import { useState} from "react";

import CluesRoutes from "./CluesRoutes";
import "./assets/css/media.css";
function App() {
  const [currentLanguage, setCurrentLanguage] = useState(
    process.env.REACT_APP_DEFAULT_LANGUAGE
  );
  // Context is best way to solve multi-level child-parent props passing
  const onLanguageChange = (language) => {
    setCurrentLanguage(language);
  };

  return (
    <CluesRoutes
      currentLanguage={currentLanguage}
      onLanguageChange={onLanguageChange}
    />
  );
}

export default App;
