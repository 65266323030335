import React from "react";
import { Link } from "react-router-dom";
import { footer_explore_links } from "../../../configuration/lang.config";
import { footer_navigation } from "../../../configuration/common.config";
import global from "../../../configuration/global.config";
import FooterNavigationItems from "./FooterNavigationItems";

const FooterLink = ({ selectedLanguage }) => {
  return (
    <div className="footerLinks">
      <FooterNavigationItems
        navObj={global.NAVIGATION_LIST}
        selectedLanguage={selectedLanguage}
      />
      <ul>
        <h4>{footer_explore_links["heading_text"][selectedLanguage]}</h4>
        <FooterNavigationItems
          navObj={footer_navigation}
          selectedLanguage={selectedLanguage}
        />
        {footer_navigation.map((linkInfo) => {
          return (
            <div className="lnk">
              <Link to={linkInfo.route}>{linkInfo.name[selectedLanguage]}</Link>
            </div>
          );
        })}
      </ul>
    </div>
  );
};
export default FooterLink;
