import { postRequest } from "./requestServices";
import { ticketApiEndPoint } from "../configuration/common.config";

//const URL = process.env.REACT_APP_HOST_NAME

export const postContactUsInfo = async (params) => {
    //const PATH = URL ? `/${URL}` : "http://support.cluesnetwork.com/" + ticketApiEndPoint
    const PATH = ticketApiEndPoint    
     const header = {
        "x-api-key" : "E6ADC09D8E880FE7E131F5B05E4381FB"
    };
    try {
         const response = await postRequest(PATH,params,header);
        if(response){
            return response
        } else {
            console.log('could not call api')
        }
    } 
    catch(err) {
        console.log(err)
    }

};

