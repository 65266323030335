import React from 'react';
import PropTypes from "prop-types";

const Block = (props) => {
    return (
        <div className={props.blockCustomClass}>
            {props.children}
        </div>
    )
}

Block.propTypes = {
    blockCustomClass: PropTypes.string,
}

export default Block;
