import React from "react";

import PropTypes from "prop-types";

function Text({ text, textCustomClass }) {
  return <span className={textCustomClass}>{text}</span>;
}

Text.propTypes = {
  text: PropTypes.string,
  textCustomClass: PropTypes.string,
};

export default Text;
