import React from "react";
import { brandTechnologies } from "../../../configuration/lang.config";
import SectionContainer from "../SectionContainer";
import Block from "../../UI/Block";
import UnOrderedList from "../../UI/UnOrderedList";
import List from "../../UI/List";
import Image from "../../UI/Image";
const BrandTechnologies = ({selectedLanguage}) => {
    return (
        <SectionContainer sectionCustomClass = "tech_used alt">
            <Block blockCustomClass = "wrapper">
                <UnOrderedList>
                    {brandTechnologies.technologies.map((technologies) => {
                        return (
                            <List key = {technologies.id}>
                                <h2>
                                    {technologies.header[selectedLanguage]}
                                </h2>
                                <p>
                                    {technologies.info[selectedLanguage]}
                                </p>
                                <Block blockCustomClass="tech_icon">
                                    {technologies["tech_icons"].map((icons) => {
                                        return (
                                            <span key = {icons.id}>
                                                <Image 
                                                    imgLocation = {icons.path}
                                                />
                                            </span>
                                        )
                                    })}
                                </Block>
                            </List>
                        )
                    })}
                </UnOrderedList>
            </Block>
        </SectionContainer>
    );
}

export default BrandTechnologies;