import React from "react";
import Block from "../../UI/Block";
const InnerPageBanner = (props) => {
    return(
        <Block blockCustomClass = "page_title">
            <Block blockCustomClass = "wrapper">
                <h2>{props.header_text}</h2>
            </Block>
      </Block>
    )
}

export default InnerPageBanner;