import React from "react";

import PropTypes from "prop-types";

const Image = ({ imgLocation, imgCustomClass, imgAltText }) => {
  return <img src={imgLocation} className={imgCustomClass} alt={imgAltText} />;
};

Image.propTypes = {
  imgLocation: PropTypes.string.isRequired,
};

Image.defaultProps = {
  imgAltText: "We the people :) :)",
};

export default Image;
