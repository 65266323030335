import {React,useState} from "react";

import CluesNetworkLogo from "./CluesNetworkLogo";
import NavigationList from "../SiteNav/NavigationList";
import NavigationListMobile from "../SiteNav/NavigationListMobile";
import LanguageTranslator from "./LanguageTranslator";

const Header = ({
  selectedLanguage,
  onLanguageChangeSelector,
  headerCustomClass,
}) => {

  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);

  const toggleMobileMenu = () => {
      setMobileMenuVisible(!mobileMenuVisible);
  };
  return (
    // <div className={headerCustomClass}>
    <>
    <NavigationListMobile
      currentLanguage={selectedLanguage}
      showMobileMenu = {mobileMenuVisible}
      toggleMobileMenu={toggleMobileMenu}
      />
    <header className={headerCustomClass}>      
        <div className="wrapper">
            <i className="menu_ico" onClick={toggleMobileMenu}></i>
            <CluesNetworkLogo logoAlt="Clues Network" />
            <NavigationList
            currentLanguage={selectedLanguage}
            />
            <div className="languageChange">
                <LanguageTranslator
                  currentLanguage={selectedLanguage}
                  onLanguageChangeSelector={onLanguageChangeSelector}
                />
            </div>
        </div>     
    </header>
    </>
    // </div>
  );
};

export default Header;
