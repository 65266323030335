import React from 'react';
import Block from '../../UI/Block';
import Text from '../../UI/Text';
import UnOrderedList from '../../UI/UnOrderedList';
import List from '../../UI/List';
import { Link } from "react-router-dom";
import { digitalProductSuccess } from "../../../configuration/lang.config";
import { brandDigitalProducts } from "../../../configuration/lang.config";

const DigitalProductSuccess = ({selectedLanguage,type}) => {
    return (
       <section className="alt"> 
             <Block blockCustomClass="wrapper">
                <Block blockCustomClass="row">
                    <Block blockCustomClass="heading_info">
                        <h2>
                            <Text text={digitalProductSuccess[type].title[selectedLanguage]} />
                        </h2>
                        <p>
                            <Text text={brandDigitalProducts[type].description[selectedLanguage]} />
                        </p>

                        <Block blockCustomClass="bttn_group">
                            <Link to={brandDigitalProducts[type].link_to} className="btn">
                                {brandDigitalProducts[type].link_title[selectedLanguage]}
                            </Link>
                        </Block>
                    </Block>
                </Block>

                <Block blockCustomClass="row">
                    <Block blockCustomClass="content full_width">
                        <UnOrderedList listCustomClass="border_blk">
                            {digitalProductSuccess[type].content.map((content) => {
                                return (
                                    <List key={content.id}>
                                        <p>
                                            {content.paragraph_content[selectedLanguage]}
                                        </p>
                                        <Text text={content.span_content[selectedLanguage]} />
                                    </List>
                                )
                            })}
                        </UnOrderedList>
                    </Block>
                </Block>
             </Block>
       </section>
    );
}

export default DigitalProductSuccess;
