import React from "react";
import { leaderboard } from "../../../configuration/common.config";
import Block from "../../UI/Block";
import Table from "../../UI/Table";
import TableCell from "../../UI/TableCell";
import Image from "../../UI/Image";
import Text from "../../UI/Text";
import { Link } from "react-router-dom";
const tableStyle = {
  borderCollapse : "inherit",
  borderSpacing : "24px"
}
const tableAttr = {
  width : "100%",
  border : 0,
  cellSpacing : 0,
  cellPadding : 0
}
const WeTheLeader = ({selectedLanguage}) => {
  return (
      <Block blockCustomClass = "whyclnet_sec">
        <Block blockCustomClass = "wrapper">
          <h3>
            {leaderboard.heading_text[selectedLanguage]}
          </h3>
          <Block blockCustomClass = "info_sec">
            <Block blockCustomClass = "info">
              <Table style = {tableStyle} {...tableAttr}>
                <tbody>
                <tr>
                {leaderboard.items.map((leaderInfo) => {
                  return (
                    <TableCell width = "25%" key = {leaderInfo.id}>
                      <Block>
                        <Image imgLocation = {leaderInfo.img_src}
                          imgCustomClass = "animated bounceIn"
                        />
                        <Text text = {leaderInfo.intro[selectedLanguage]}/>
                        <p>
                          {leaderInfo.description[selectedLanguage]}
                        </p>
                      </Block>
                    </TableCell>
                  )
                })}
                </tr></tbody>
              </Table>
            </Block>
            <Block blockCustomClass = "btn_container">
              <Link to = "/contact">
                {leaderboard.button_text[selectedLanguage]}
              </Link>
            </Block>
          </Block>
        </Block>
      </Block>
  );
};

export default WeTheLeader;
