import React from "react";
import PropTypes from "prop-types";
import styles from "./SectionContainer.module.css";

const SectionContainer = (props) => {
  return <section className={props.sectionCustomClass}>{props.children}</section>;
};

SectionContainer.propTypes = {
  sectionCustomClass: PropTypes.string,
};

SectionContainer.defaultProps = {
  sectionCustomClass: styles["container"],
};

export default SectionContainer;
