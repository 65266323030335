import React from "react";
import Header from "../Layout/Header/Header";
import Footer from "../Layout/Footer/Footer";
import SectionContainer from "../Section/SectionContainer";
import Banner from "../Layout/Banner/Banner";
import clientImage from "../../assets/images/client-pgtitle.jpg";
import styles from "../Section/Clients/Clients.module.css";
import {clients} from "../../configuration/lang.config";
import {ClientsDescription,CluesNetworkClients} from "../Section/Clients/index";
const Clients = ({selectedLanguage,onLanguageChangeSelector}) => {
  return (
    <>
       <Header
          selectedLanguage={selectedLanguage}
          onLanguageChangeSelector={onLanguageChangeSelector}
          headerCustomClass="inner"
        />
      <Banner bannerCustomClass="banner_inner" bannerImage={clientImage}>
      <SectionContainer>
          <h2 className={styles["banner-info-text"]}>
            {clients["our_clients"][selectedLanguage]}
          </h2>
        </SectionContainer>
      </Banner>
      <ClientsDescription 
          selectedLanguage = {selectedLanguage}
          onLanguageChangeSelector = {onLanguageChangeSelector}
        />
        <CluesNetworkClients 
          selectedLanguage = {selectedLanguage}
          onLanguageChangeSelector = {onLanguageChangeSelector}
        />
        <Footer 
          selectedLanguage={selectedLanguage}
        />
    </>
  )
};

export default Clients;
