import React from "react";
import SectionContainer from "../SectionContainer";
import Block from "../../UI/Block";
import Image from "../../UI/Image";
import { aboutHealthCareApp } from "../../../configuration/lang.config";
const AboutHealthCareApp = ({selectedLanguage, type}) => {
    return (
        <SectionContainer sectionCustomClass = "about_fitness">
            <Block blockCustomClass = "wrapper">
                <Block blockCustomClass = "about_img">
                    <Image 
                        imgLocation = {aboutHealthCareApp[type].image}
                    />
                </Block>
                <Block blockCustomClass = "about_info">
                    <h2>
                        {aboutHealthCareApp[type].header[selectedLanguage]}
                    </h2>
                    <p>
                        {aboutHealthCareApp[type].info[selectedLanguage]}
                    </p>
                </Block>
            </Block>
        </SectionContainer>
    );
}

export default AboutHealthCareApp;