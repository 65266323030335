import React from 'react';
import PropTypes from "prop-types";

const UnOrderedList = (props) => {
    return (
        <ul className={props.listCustomClass}>
            {props.children}
        </ul>
    )
}

UnOrderedList.propTypes = {
    listCustomClass: PropTypes.string,
};
  
UnOrderedList.defaultProps = {
    listCustomClass: "",
};

export default UnOrderedList;