import React from "react";

const TableCell = (props) => {
    return(
        <td width = {props.width} colSpan = {props.colSpan} className = {props.className}>
            {props.children}
        </td>
    )
}

export default TableCell;