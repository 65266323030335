import React from "react";
import {
  footer_info,
  footer_explore_links,
} from "../../../configuration/lang.config";
import FooterExploreNavigation from "./FooterExploreNavigation";
import Text from "../../UI/Text";
import { Link } from "react-router-dom";
import global from "../../../configuration/global.config";

const Footer = ({ selectedLanguage }) => {
  return (
    <footer>
      <div className="wrapper footer_mn">
        <section>
          <Text
            text={footer_info["clues_text"][selectedLanguage]}
            textCustomClass="clues_text"
          />
          <p>{footer_info["clues_description"][selectedLanguage]}</p>
        </section>

        {global.NAVIGATION_LIST.map((nav) => {
          if (nav.isFolder) {
            return (
              <section>
                <h3>{nav.name[selectedLanguage]}</h3>
                <ul>
                  {nav.items.map((items) => {
                    return (
                      <li>
                        <Link to={items.itemRoute}>
                          {items.itemName[selectedLanguage]}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </section>
            );
          } else {
            return "";
          }
        })}

        <section>
          <h3>{footer_explore_links["heading_text"][selectedLanguage]}</h3>
          <FooterExploreNavigation selectedLanguage={selectedLanguage} />
        </section>
      </div>
      <div className="copyright">
        <Text text="&copy;" />
        <Text text=" 2022 " />
        <Text text="CNTech" />
        <Text text=", All Right Reserved" />
      </div>
    </footer>
  );
};

export default Footer;
