import React from "react";
import { footer_navigation } from "../../../configuration/common.config";
import FooterExploreNavigationItem from "./FooterExploreNavigationItem";

const FooterExploreNavigation = ({selectedLanguage}) => {
  return (
    <ul>
        {footer_navigation.map((linkInfo) => {
          return (
           <FooterExploreNavigationItem key={linkInfo.id} name={linkInfo.name[selectedLanguage]} linkTo={linkInfo.route} />           
          );
        })}
    </ul>
  );
};
export default FooterExploreNavigation;
