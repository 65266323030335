import React from 'react';
import ClientsListing from './ClientsListing';
import {cluesNetworkClients as clients} from '../../../configuration/common.config';
import {clientSlider} from '../../../configuration/common.config';

import "../../../assets/css/slick.css";
import Slider from "react-slick";

const CluesNetworkClients = ({selectedLanguage}) => {  
  return (
    <div className = "client_sec">
      <div className = "wrapper">
        <h3>
        {selectedLanguage === 'EN' ? "Our Clients" : "우리의 클라이언트"}
        </h3>
        <ul className = "client_tabs">
        <Slider {...clientSlider}>
          {clients.map((client) => {
            return <ClientsListing key={client.id} client={client} />;
          })}
        </Slider>
        </ul>
      </div>
    </div>
  );
}

export default CluesNetworkClients;
