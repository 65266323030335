import React from "react";
import Block from "../../UI/Block";
import { scheduleExpertSession } from "../../../configuration/lang.config";
import UnOrderedList from "../../UI/UnOrderedList";
import List from "../../UI/List";
import Text from "../../UI/Text";
import Slider from "react-slick";
import ContactUsInner from "../ContactUS/contactUsInner";

const ScheduleExpertSession = ({selectedLanguage}) => {
    return(
        <section className = "contact_form alt">
            <Block blockCustomClass = "wrapper">
                <Block blockCustomClass = "testimonials_infoBox">
                    <h2>{scheduleExpertSession.header_text[selectedLanguage]}</h2>
                    <Block blockCustomClass = "testimonial_user">
                        <UnOrderedList listCustomClass = "single_item">
                            <Slider>
                            {scheduleExpertSession.testimonial_users.map((testimonial) => {
                                return(
                                    <List key = {testimonial.id}>
                                        <Block blockCustomClass = "messages">
                                            <i>
                                                <img src={testimonial.image} alt=""></img>
                                            </i>
                                            <Block blockCustomClass = "user_info">
                                                <p>{testimonial.user[selectedLanguage]}</p>
                                                <Text text = {testimonial.designation[selectedLanguage]}/>
                                            </Block>
                                        </Block>
                                        <p className = "testimonial_text">
                                            {testimonial.description[selectedLanguage]}
                                        </p>
                                    </List>
                                )
                            })}
                        </Slider>
                        </UnOrderedList>
                    </Block>
                </Block>
                <Block blockCustomClass="contact_box">
                    <ContactUsInner selectedLanguage={selectedLanguage}></ContactUsInner>
                </Block>
            </Block>
        </section>
    )
}

export default ScheduleExpertSession;