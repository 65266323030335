import React from "react";

const FooterCopyRight = () => {
  return (
    <div className="copyright">
      <div className="wrapper">
        <p>© 2022 CNTech, All rights reserved</p>
      </div>
    </div>
  );
};

export default FooterCopyRight;
