import React, { useState } from "react";
import ContactUsForm from "./ContactUsForm";
import { contact_us } from "../../configuration/lang.config";
import { postContactUsInfo } from "../../services/contactUsService";
import  "../../assets/css/inner_pages.css";
const ContactUs = (props) => {
  const {selectedLanguage} = props;
  const [thanksMsg, setThanksMsg] = useState(false);
  const [apiData, setApiData] = useState({});
  const [disable, setDisable] = useState(false)
  const [userInput, setUserInput] = useState({
    name: "",
    email: "",
    phone: "",
    company: "",
    extraInfo: "",
  });

  const onUserInfoSubmit = (event) => {
    event.preventDefault();
    setDisable(true)
    var topicID = {
      "subject": "test ticket",
      "topicId": "678"
    }
    const reqObj = {
      "name": userInput.name,
      "email": userInput.email,
      "message": userInput.extraInfo,
    }

    Object.assign(reqObj, topicID);

    postContactUsInfo(reqObj)
      .then(
        (res) => {
          setDisable(false)
          const ticketID = (res.data);
          setApiData({ "ticketID": ticketID })

          if (!isNaN(ticketID)) {
            setThanksMsg(true)
            setUserInput((prevState) => {
              return {
                ...prevState,
                ...{
                  name: "",
                  email: "",
                  phone: "",
                  company: "",
                  extraInfo: "",
                }
              }
            });
            setTimeout(() => {
              setThanksMsg(false)
            }, 10000);
          } else {
            console.log({ "err": res.data ? res.data : res.message })
          }
        }
      ).catch((err) => {
        setDisable(false)
        console.log(err)
      })
  };

  const onUserInputChange = (event) => {
    setUserInput((prevState) => {
      return {
        ...prevState, // spread operator (...)
        [event.target.name]: event.target.value,
      };
    });
  };

  return (
    <>
      <h3>{contact_us.contact_us_head[selectedLanguage]} 
        <span>
          {contact_us.contact_us_text[selectedLanguage]}
        </span>
      </h3>
      {thanksMsg && <div className = "thanks_msg" id="thanks_msg" >Thank you for sharing your details, Your request Id is: {apiData?.ticketID} Our team will get back to you in 2-3 business days.</div>}
      <ContactUsForm selectedLanguage={selectedLanguage} onUserInputChange={onUserInputChange} onUserInfoSubmit={onUserInfoSubmit} userInput={userInput} langConfig={contact_us} disableBtn={disable} />
    </>
  );
};

export default ContactUs;
