import React from 'react';
import Block from '../../UI/Block';
import {brandSolutions} from "./../../../configuration/common.config";
import UnOrderedList from '../../UI/UnOrderedList';
import List from '../../UI/List';
import { Link } from 'react-router-dom';
const OurSolutions = ({selectedLanguage}) => {
    return (
        <Block blockCustomClass = "our_sol_cnt">
            <Block blockCustomClass = "wrapper">
                <h3>{brandSolutions.header_section[selectedLanguage]}</h3>
                <UnOrderedList>
                    {brandSolutions["body_section"].map((list) => {
                        return(
                            <List key = {list.id}>
                                <Link to={list.link}>
                                <p>{list.title[selectedLanguage]}</p>
                                <span>{list.description[selectedLanguage]}</span>
                                </Link>
                            </List>
                        )
                    })}
                </UnOrderedList>
            </Block>
        </Block>
    )
}

export default OurSolutions;