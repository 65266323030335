import React from 'react';
import { Link } from "react-router-dom";

const HeaderLinks = (props) => {
    return (
        <Link to={props.linkTo} className={props.class} title={props.title}>
            {props.text}
        </Link>
    )
}

export default HeaderLinks;
