import classes from "./LanguageTranslator.module.css";

const LanguageTranslator = ({ currentLanguage, onLanguageChangeSelector }) => {
  // Passing props from Child To Parent
  // console.log(`Current Selected Language: ${currentLanguage}`);
  const onLanguageChangeHandler = (event) => {
    onLanguageChangeSelector(event.target.value);
  };

  return (
    <select
      defaultValue={currentLanguage}
      onChange={onLanguageChangeHandler}
    >
      <option value="EN">English - EN</option>
      <option value="KR">한국어 - KR</option>
    </select>
  );
};

export default LanguageTranslator;
