import React from "react";
import Block from "../../UI/Block";
import { healthCareApplicationHeader } from "../../../configuration/lang.config";
//import { servicesHeader } from "../../../configuration/lang.config";
import HeaderLinks from "../Services/HeaderLinks";
import SectionContainer from "../SectionContainer";
const HealthCareApplicationHeader = ({selectedLanguage, type}) => {
    return (
        <SectionContainer sectionCustomClass="mn_heading alt">
            <Block blockCustomClass="wrapper">
                <h1>
                    {healthCareApplicationHeader[type].header[selectedLanguage]}
                </h1>
                <p>
                    {healthCareApplicationHeader[type].header_info[selectedLanguage]}
                </p>
                <Block blockCustomClass="bttn_group">
                    {healthCareApplicationHeader[type].links.map((item) => {
                        return <HeaderLinks 
                                    key={item.id}
                                    linkTo={item.link_to} 
                                    class={item.class}
                                    title={item.title[selectedLanguage]}
                                    text={item.text[selectedLanguage]} 
                                />
                        })}
                </Block>
            </Block>
        </SectionContainer>
    );
}

export default HealthCareApplicationHeader;