import React from 'react';
import {omnichannelListing} from './../../../configuration/common.config';
import { omnichannel } from '../../../configuration/lang.config';
import Block from '../../UI/Block';
import UnOrderedList from '../../UI/UnOrderedList';
import List from '../../UI/List';
import Image from '../../UI/Image';
import omnichannelImagePath from './../../../assets/images/omnichannel.png';

const BrandOmnichannel = ({selectedLanguage}) => {
    return(
        <Block blockCustomClass = "bg_container">
            <Block blockCustomClass = "wrapper">
                <Block blockCustomClass = "scale_sec">
                    <h3>{omnichannel.head_text[selectedLanguage]}</h3>
                    <Block blockCustomClass = "info_sec">
                        <Block blockCustomClass = "info">
                            <UnOrderedList>
                                {omnichannelListing.map((list) => {
                                    return (
                                        <List key = {list.key}>{list.text[selectedLanguage]}</List>
                                    )
                                }
                                )}
                            </UnOrderedList>
                        </Block>
                        <Image 
                            imgLocation = {omnichannelImagePath}
                            imgCustomClass = "animated bounceIn"
                        />
                    </Block>
                </Block>
            </Block>
        </Block>
    )
}

export default BrandOmnichannel;