import axios from "axios";

const axiosClient = axios.create();

//axiosClient.defaults.baseURL = "https://jsonplaceholder.typicode.com/todos/1";


// axiosClient.defaults.headers = {
//   "Content-Type": "application/json",
//   Accept: "application/json",
//   "x-api-key" : "E291B0C63C72FD59FC08C034D5DD5D4B"
// };

//All request will wait 2 seconds before timeout
// axiosClient.defaults.timeout = 2000;

axiosClient.defaults.withCredentials = true;

export function getRequest(URL) {
  return axiosClient.get(URL ? `/${URL}` : "").then((response) => response);
}

export function postRequest(URL, payload, headers) {
  return axiosClient.post(URL, payload, {
    "headers": headers
  })
  .then((response) => response);
}

export function patchRequest(URL, payload) {
  return axiosClient.patch(`/${URL}`, payload).then((response) => response);
}

export function deleteRequest(URL) {
  return axiosClient.delete(`/${URL}`).then((response) => response);
}