import React from "react";
import SectionContainer from "../SectionContainer";
import Block from "../../UI/Block";
import Text from "../../UI/Text";
import UnOrderedList from "../../UI/UnOrderedList";
import List from "../../UI/List";
import { brandHealthCareServices } from "../../../configuration/lang.config";

const BrandHealthCareServices = ({selectedLanguage ,type}) => {
    if(brandHealthCareServices[type]){
    return (
       <SectionContainer>
            <Block blockCustomClass="wrapper">
                <Block blockCustomClass="row">
                    <Block blockCustomClass="heading_info full_width">
                        <h2>
                            {brandHealthCareServices[type].header[selectedLanguage]}
                        </h2>
                    </Block>
                </Block>
                <Block blockCustomClass="row">
                    <Block blockCustomClass="content full_width">
                        <UnOrderedList listCustomClass="border_blk gBlocks">
                            {brandHealthCareServices[type].services.map((services) => {
                                return (
                                    <List key = {services.id}>
                                        <p>
                                            {services.title[selectedLanguage]}
                                        </p>
                                        <Text
                                            text = {services.info[selectedLanguage]}
                                        />
                                    </List>
                                )
                            })}
                        </UnOrderedList>
                    </Block>
                </Block>
            </Block>
       </SectionContainer>
    );
    }
}

export default BrandHealthCareServices;