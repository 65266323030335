import React from 'react';
import Block from '../../UI/Block';
import Text from '../../UI/Text';
import SectionContainer from '../SectionContainer';
import HeaderLinks from './HeaderLinks';
import { servicesHeader } from "../../../configuration/lang.config";

const ServicesHeader = ({selectedLanguage,type}) => {
    return (
        <SectionContainer sectionCustomClass="mn_heading alt">
            <Block blockCustomClass="wrapper">
                <h1>
                <Text text={servicesHeader[type].title[selectedLanguage]} /> 
                </h1>
                <p>
                    <Text text={servicesHeader[type].description[selectedLanguage]} />
                </p>
                <Block blockCustomClass="bttn_group">
                    {servicesHeader[type].links.map((item) => {
                        return <HeaderLinks 
                                    key={item.id}
                                    linkTo={item.link_to} 
                                    class={item.class}
                                    title={item.title[selectedLanguage]}
                                    text={item.text[selectedLanguage]} 
                               />
                    })}
                </Block>
            </Block>
        </SectionContainer>
    )
}

export default ServicesHeader;
