const global = {
  DEFAULT_LANGUAGE: "EN",
  NAVIGATION_LIST: [
    {
      id: 1,
      name: { EN: "HOME", KR: "홈" },
      isFolder: false,
      route: "/",
    },
    {
      id: 2,
      name: { EN: "SERVICES", KR: "서비스" },
      isFolder: true,
      route: "/services",
      items: [
          {
            itemName: {EN: "Product Engineering", KR: "제품 엔지니어링" },
            isFolder: false,
            itemRoute:"/services/ProductEngineering"
          },
          {
            itemName: {EN: "Web Application Development", KR: "웹 애플리케이션 개발" },
            isFolder: false,
            itemRoute:"/services/WebApplicationDevelopment"
          },
          {
            itemName: {EN: "Internet Of Things", KR: "사물 인터넷" },
            isFolder: false,
            itemRoute:"/services/InternetOfThings"
          },
          {
            itemName: {EN: "MobileAppDevelopment", KR: "모바일 앱 개발" },
            isFolder: false,
            itemRoute:"/services/MobileAppDevelopment"
          },
          {
            itemName: {EN: "Cloud Engineering", KR: "클라우드 엔지니어링" },
            isFolder: false,
            itemRoute:"/services/CloudEngineering"
          },
          {
            itemName: {EN: "eCommerce Development", KR: "전자상거래 개발" },
            isFolder: false,
            itemRoute:"/services/eCommerceDevelopment"
          },

          {
            itemName: {EN: "UI/UX Design", KR: "UI/UX 디자인" },
            isFolder: false,
            itemRoute:"/services/UI_UX_Design"
          },
          {
            itemName: {EN: "React Native App Development", KR: "React 네이티브 앱 개발" },
            isFolder: false,
            itemRoute:"/services/ReactNativeAppDevelopment"
          },
          {
            itemName: {EN: "Enterprise App Development", KR: "엔터프라이즈 앱 개발" },
            isFolder: false,
            itemRoute:"/services/EnterpriseAppDevelopment"
          },
        ],
    },
    {
      id: 3,
      name: { EN: "INDUSTRIES", KR: "산업" },
      isFolder: true,
      route: "/industries",
      items: [
        {
          itemName: {EN: "Retail & Ecommerce", KR: "소매 및 전자 상거래" },
          isFolder: false,
          itemRoute:"/industries/retailAndecomm"
        },
        {
          itemName: {EN: "Sports & Entertainment", KR: "스포츠 및 엔터테인먼트" },
          isFolder: false,
          itemRoute:"/industries/sportsAndentertainment"
        },
        {
          itemName: {EN: "Media & Publishing", KR: "미디어 및 출판" },
          isFolder: false,
          itemRoute:"/industries/mediaAndpublish"
        },
        {
          itemName: {EN: "Food & Beverage", KR: "음식 및 음료" },
          isFolder: false,
          itemRoute:"/industries/foodAndbeverages"
        },
        {
          itemName: {EN: "Health Care & Fitness", KR: "건강 관리 및 피트니스" },
          isFolder: false,
          itemRoute:"/industries/health"
        },
        {
          itemName: {EN: "Education & Training", KR: "교육과 훈련" },
          isFolder: false,
          itemRoute:"/industries/educationAndtraining"
        },
        {
          itemName: {EN: "Logistics", KR: "물류 센터" },
          isFolder: false,
          itemRoute:"/industries/logistics"
        },
      ],
    },
    {
      id: 4,
      isFolder: false,
      name: { EN: "TECHNOLOGIES", KR: "기술" },
      route: "/technologies",
    },
    {
      id: 5,
      isFolder: false,
      name: { EN: "CONTACT", KR: "문의하기" },
      route: "/contact",
    },
  ],
};

export default global;
