import PropTypes from "prop-types";

// Handle Custom Class For Banner - Done

const Banner = (props) => {
  return (
    <section className={props.bannerCustomClass}>
        {props.children}
    </section>      
  );
};

// This will not work - Object / String ?
Banner.defaultProps = {
  bannerCustomClass:"banner_section"
};

export default Banner;
