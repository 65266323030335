import React from "react";

import { Link } from "react-router-dom";
import styles from "./Button.module.css";
import PropTypes from "prop-types";

const Button = (props) => {
  const { text, buttonCustomClass, redirectTo, type, onClick, disabled } = props;

  const renderButton = () => {
    const button = (
      <button
        className={`${buttonCustomClass}`}
        type={type || "button"}
        onClick={onClick && onClick}
        disabled={disabled}
      >
        {text}
      </button>
    );
    if (type === "button" || type === undefined) {
      return (
        <Link to={redirectTo} className={styles["btn-text"]}>
          {button}
        </Link>
      );
    } else {
      return button;
    }
  };

  return renderButton();
};

Button.propTypes = {
  onClick: PropTypes.func,
  redirectTo: PropTypes.string,
  text: PropTypes.string,
  type: PropTypes.string,
  buttonCustomClass: PropTypes.string,
};

export default Button;
