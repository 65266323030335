import React from "react";
import PropTypes from "prop-types";

const Form = (props) => {
    const { children, onSubmit, className, style, id } = props;

    return( 
        <>
        <form
            onSubmit={onSubmit}
            className={className}
            style={style}
            id={id}>
            {children}
        </form>
        </>
    );
}

Form.propTypes = {
    onSubmit: PropTypes.func,
    className: PropTypes.string,
    style: PropTypes.string,
    children: PropTypes.array,
    id: PropTypes.string
};

export default Form;

