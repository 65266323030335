import React from "react";
import SectionContainer from "../SectionContainer";
import Block from "../../UI/Block";
import { brandTechnologies } from "../../../configuration/lang.config";
import { Link } from "react-router-dom";
const BrandTechnologiesHeader = ({selectedLanguage}) => {
    return (
        <SectionContainer sectionCustomClass="mn_heading alt">
            <Block blockCustomClass="wrapper">
                <h1>
                    {brandTechnologies.header[selectedLanguage]}
                </h1>
                <p>
                    {brandTechnologies.info[selectedLanguage]}
                </p>
                <Block blockCustomClass="bttn_group">
                    <Link to = "/contact" className="btn">
                        {brandTechnologies.lets_talk[selectedLanguage]}
                    </Link>
                </Block>
            </Block>
        </SectionContainer>
    );
}

export default BrandTechnologiesHeader;