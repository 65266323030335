import {Form,Input,Button,TextArea} from "../../UI/index"


const ContactUsFormInner = ({onUserInfoSubmit,onUserInputChange,userInput,selectedLanguage,langConfig, disableBtn}) => {
  return (
    <Form onSubmit={onUserInfoSubmit}>
         <Input
                name="name"
                type="text"
                value={userInput.expenseType}
                placeholder={userInput.name !== "" ? userInput.name : langConfig.form_inputs[0]['name'][selectedLanguage]}
                onChange={onUserInputChange}
              />
              <Input
                name="email"
                type="email"
                placeholder={
                  userInput.email !== "" ? userInput.email : langConfig.form_inputs[0]['email'][selectedLanguage]
                }
                value={userInput.email}
                onChange={onUserInputChange}
              />
                <Input
                name="phone"
                type="number"
                placeholder={
                  userInput.phone !== "" ? userInput.phone : langConfig.form_inputs[0]['phone'][selectedLanguage]
                }
                value={userInput.phone}
                onChange={onUserInputChange}
              />
            <Input
                name="company"
                type="text"
                placeholder={
                  userInput.company !== "" ? userInput.company : langConfig.form_inputs[0]['company'][selectedLanguage]
                }
                value={userInput.company}
                onChange={onUserInputChange}
              />
            <TextArea
                name="extraInfo"
                id="tellus"
                placeholder={
                  userInput.extraInfo !== ""
                  ? userInput.extraInfo
                  : langConfig.form_inputs[0]['message'][selectedLanguage]
                }
                value={userInput.extraInfo}
                onChange={onUserInputChange}
              />
              <div className="bttn_group">
              <Button
                text={langConfig.submit[selectedLanguage]}
                type="submit"
                //onClick={onUserInfoSubmit}
                buttonCustomClass = "btn"
                disabled={disableBtn}
              />
              </div>
    </Form>
  );
};

export default ContactUsFormInner;
