import React from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const NavigationItem = ({ name, linkTo ,className, items, lang}) => {
  
  if(className){
    return(
    <li className={className}>
      {linkTo === window.location.pathname ? <a href={void(0)} className = "active">{name}</a>: <a href={void(0)} >{name}</a>}
    
    <div className="dropDown">
    {items.map((dropDownItems) => {
    return(
      (linkTo === window.location.pathname ? <Link to={dropDownItems.itemRoute} className = "active">{dropDownItems.itemName[lang]}</Link>: <Link to={dropDownItems.itemRoute}>{dropDownItems.itemName[lang]}</Link>)
    )}
  )
  }
    </div>
      
    </li>
    );
    }else{
    return(
      <li>
      {linkTo === window.location.pathname ? <Link to={linkTo} className = "active">{name}</Link>: <Link to={linkTo}>{name}</Link>
    }
    </li>
    );
    }
}

NavigationItem.propTypes = {
  name: PropTypes.string,
  linkTo: PropTypes.string,
  className:PropTypes.string,
  items:PropTypes.any,
  lang:PropTypes.string,
};

export default NavigationItem;
