import React from "react";
import Header from "../Layout/Header/Header";
import MainContainer from "../UI/MainContainer";
import BrandTechnologiesHeader from "../Section/Technologies/BrandTechnologiesHeader";
import BrandTechnologies from "../Section/Technologies/BrandTechnologies";
import Footer from "../Layout/FooterNew/Footer";

const Technologies = ({selectedLanguage , onLanguageChangeSelector}) => {
    return (
        <>
            <Header
                selectedLanguage={selectedLanguage}
                onLanguageChangeSelector={onLanguageChangeSelector}
                headerCustomClass="inner"
            />
            <MainContainer MainContainerCustomClass = "container technologies">
                <BrandTechnologiesHeader
                    selectedLanguage={selectedLanguage} 
                />
                <BrandTechnologies
                    selectedLanguage={selectedLanguage}
                />
            </MainContainer>
            <Footer selectedLanguage={selectedLanguage}/>
        </>
    );
}

export default Technologies;