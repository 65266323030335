import Header from "../../components/Layout/Header/Header";
import Footer from "../../components/Layout/FooterNew/Footer";
import Banner from "../Layout/Banner/Banner";
import BannerInfo from "../Layout/Banner/BannerInfo";
import {BrandOmnichannel,CluesNetworkClients,BrandPower,WeTheLeader,BrandSolutions,
  BrandIndustries} from 
'../Section/Home/index';
import MainContainer from "../UI/MainContainer";
import "../../assets/css/cluesnet.css";
const Home = ({ selectedLanguage, onLanguageChangeSelector }) => {
  return (
    <>
      <Banner>
        <BannerInfo
            selectedLanguage={selectedLanguage}
        />
      </Banner>
               <Header
          selectedLanguage={selectedLanguage}
          onLanguageChangeSelector={onLanguageChangeSelector}
          headerCustomClass="home_page"
        />

      <MainContainer>
        <BrandOmnichannel selectedLanguage = {selectedLanguage}/>
        <BrandSolutions selectedLanguage = {selectedLanguage} />
        <BrandIndustries selectedLanguage = {selectedLanguage} />
        <BrandPower selectedLanguage = {selectedLanguage}/>
        <WeTheLeader selectedLanguage = {selectedLanguage}/>
        <CluesNetworkClients selectedLanguage = {selectedLanguage} />
        <Footer
            selectedLanguage={selectedLanguage}
        />
      </MainContainer>
    </>
  );
};

export default Home;
