import React from "react";
import Block from "../../UI/Block";
import {brandIndustries} from "./../../../configuration/common.config";
import UnOrderedList from "../../UI/UnOrderedList";
import List from "../../UI/List";
import Image from "../../UI/Image";
import { Link } from 'react-router-dom';

const BrandIndustries = ({selectedLanguage}) => {
    return (
        <Block blockCustomClass = "industries_cnt">
            <Block blockCustomClass = "wrapper">
                <h3>{brandIndustries.header_section[selectedLanguage]}</h3>
                <UnOrderedList>
                    {brandIndustries.body_section.map((list) => {
                        return(
                            <List key = {list.id}>
                                <Link to={list.link}>
                                    <Block blockCustomClass = "blk_img">
                                        <Image imgLocation = {list.image} />
                                    </Block>
                                    <Block blockCustomClass = "info">
                                        <h4>{list.title[selectedLanguage]}</h4>
                                        <p>{list.description[selectedLanguage]}</p>
                                
                                    </Block>
                                </Link>
                            </List>
                        )
                    })}
                </UnOrderedList>
            </Block>
        </Block>
    )
}

export default BrandIndustries;