import React from "react";

import NavigationItem from "./NavigationItem";



// GET navigation from Props and use PropTypes
import global from "../../../configuration/global.config";


const NavigationList = ({currentLanguage}) => {
    return (
        <>
    <div className="main_nav">
        <ul>
            {global.NAVIGATION_LIST.map((nav) => {
                if(nav.isFolder){
                    return (  
                        <NavigationItem key={nav.id} name={nav.name[currentLanguage]} linkTo={nav.route} className="hasDropdown" items={nav.items} lang={currentLanguage}/>
                    );}
                    else{
                        return (
                        <NavigationItem key={nav.id} name={nav.name[currentLanguage]} linkTo={nav.route} />
                        );
                    }
            }
        )}
        </ul>
    </div>
</>
    );  

};

export default NavigationList;
