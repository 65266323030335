import React from "react";
import FooterCopyRight from "./FooterCopyRight";
import FooterHeader from "./FooterHeader";
import FooterLink from "./FooterLink";

const Footer = ({ selectedLanguage }) => {
  return (
    <footer>
      <div className="wrapper">
        <FooterHeader selectedLanguage={selectedLanguage} />
        <FooterLink selectedLanguage={selectedLanguage} />
      </div>
      <FooterCopyRight />
    </footer>
  );
};

export default Footer;
