import React from "react";
import { Link } from "react-router-dom";
import cluesNetworkLogo from "../../../assets/images/cntech_logo.svg";
import { banner_info } from "../../../configuration/lang.config";

const FooterHeader = ({ selectedLanguage }) => {
  return (
    <div className="footerInfo">
      <div className="footerInfoHead">
        <Link to="/">
          <img src={cluesNetworkLogo} alt="" />
        </Link>
        <h3>{banner_info["head_text"][selectedLanguage]}</h3>
      </div>
      <div className="footerInfoText">
        <p>{banner_info["head_sub_text"][selectedLanguage]}</p>
        <Link to="/contact">
          {banner_info["button_text"][selectedLanguage]}
        </Link>
      </div>
    </div>
  );
};
export default FooterHeader;
