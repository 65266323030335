import React from "react";
import PropTypes from "prop-types";

const TextArea = (props) => {
    const { onChange, value, placeholder, name, id} = props;

return (
    <div>
      <textarea
        name = {name}
        id = {id}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
          </div>
  );
}

TextArea.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string,
    placeholder:PropTypes.string,
    name:PropTypes.string,
    id:PropTypes.string
};

export default TextArea;