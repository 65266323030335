import React from 'react';
import Block from '../../UI/Block';
import Text from '../../UI/Text';
import UnOrderedList from '../../UI/UnOrderedList';
import List from '../../UI/List';
import { productOfferings } from "../../../configuration/lang.config";

const ProductOfferings = ({selectedLanguage,type}) => {
    if(productOfferings[type]){
    return (
       <section>
           <Block blockCustomClass="wrapper">
               <Block blockCustomClass="row">
                    <Block blockCustomClass="heading_info full_width">
                        <h2>
                            <Text text={productOfferings[type].title[selectedLanguage]} />
                        </h2>
                        <p>
                            <Text text={productOfferings[type].description[selectedLanguage]} />
                        </p>
                    </Block>
               </Block>
               <Block blockCustomClass="row">
                   <Block blockCustomClass="content full_width">
                        <UnOrderedList listCustomClass="border_blk gBlocks"> 
                            {productOfferings[type].content.map((content) => {
                                return (
                                    <List key={content.id}>
                                        <p>
                                            {content.paragraph_content[selectedLanguage]}
                                        </p>
                                        <Text text={content.span_content[selectedLanguage]} />
                                    </List>
                                )
                            })}
                        </UnOrderedList>
                   </Block>
               </Block>
           </Block>
       </section>
    )
    }
}

export default ProductOfferings;
