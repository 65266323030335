import React from "react";

import NavigationItemMobile from "./NavigationItemMobile";


// GET navigation from Props and use PropTypes
import global from "../../../configuration/global.config";

const NavigationListMobile = ({currentLanguage,showMobileMenu,toggleMobileMenu}) => {
    const toggleMobileMenuHandler = (event) => {
        toggleMobileMenu(event.target.value);
      };
    let sideMenuClasses = "sideMenu";
    if(showMobileMenu) {
    sideMenuClasses = "sideMenu active";    
        }
        return (
            <>
        <div className={sideMenuClasses}>
    <div className="overlay" onClick={toggleMobileMenuHandler}></div>
        <div className="mobileMenu">
            <ul>
                {global.NAVIGATION_LIST.map((nav) => {
                    if(nav.isFolder){
                        return (  
                            <NavigationItemMobile key={nav.id} name={nav.name[currentLanguage]} linkTo={nav.route} className="hasDropdown" items={nav.items} lang={currentLanguage}/>
                        );}
                        else{
                            return (
                            <NavigationItemMobile key={nav.id} name={nav.name[currentLanguage]} linkTo={nav.route} toggleMobileMenu={toggleMobileMenuHandler}/>
                            );
                        }
                }
            )}
            </ul>
        </div>
        </div>
    </>
        );  
    

  // <div className={styles.navigation}>Navigation List Here</div>;
};

export default NavigationListMobile;
