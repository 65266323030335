import React from "react";

const List = (props) => { 
    return (
        <li>
            {props.children}
        </li>
    )
}

export default List;