/**
 *
 * Make this Component generic - Task for me (Done one for Banner)
 *
 */
import cluesNetworkLogo from "../../../assets/images/cntech_logo.svg";
import { Link } from "react-router-dom";

const CluesNetworkLogo = ({ logoAlt }) => {
  return (
    <div className="logo_sec">
      <Link to="/">
        <img src={cluesNetworkLogo} alt={logoAlt} />
      </Link>
    </div>
  );
};

export default CluesNetworkLogo;
