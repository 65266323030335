import React from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const FooterExploreNavigationItem = ({ name, linkTo }) => {
  return (
    <li>
      <Link to={linkTo}>{name}</Link>
    </li>
  );
};

FooterExploreNavigationItem.propTypes = {
  name: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
};

export default FooterExploreNavigationItem;
