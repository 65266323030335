import React from "react";
import { contact_us } from "../../configuration/lang.config";
import { addressMetaInfo } from "../../configuration/common.config";
import Block from "../UI/Block";
import Text from "../UI/Text";
const Address = ({ selectedLanguage }) => {
  return (
    <Block blockCustomClass = "contact_address">
      <Block blockCustomClass = "title_info">
        <h3>{contact_us["head_text"][selectedLanguage]}</h3>
        {addressMetaInfo.map((metaInfo) => {
          let addtional_text = "";
          if (metaInfo.additional_info) {
            addtional_text = (
            <Text text={contact_us[metaInfo.additional_info][selectedLanguage]}
            />
            );
          }
          return (
            <p key={metaInfo.key} className = {metaInfo.class}>
              {addtional_text}
              {contact_us[metaInfo.key][selectedLanguage]}
            </p>
          );
        })}
      </Block>
    </Block>
  );
};

export default Address;
