import React from "react";
import { Link } from "react-router-dom";
import Image from "../../UI/Image";
import { banner_info } from "../../../configuration/lang.config";
import bannerImage from "../../../assets/images/Frame.png";

const BannerInfo = ({ selectedLanguage }) => {
  return (
    <div className="banner_info">
        <div className="wrapper">
            <div className="info">
                <h3>
                    {banner_info["head_text"][selectedLanguage]}
                </h3>
                <p>
                    {banner_info["head_sub_text"][selectedLanguage]}
                </p>
                <Link to="/contact">
                    {banner_info["button_text"][selectedLanguage]}
                </Link>
            </div>
            <Image
                imgLocation={bannerImage}
            />
        </div>
    </div>
  );
};

export default BannerInfo;
