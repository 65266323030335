import React from "react";

const Table = (props) => {
    return (
        <table width = {props.width}
            border = {props.border}
            cellPadding = {props.cellPadding}
            cellSpacing = {props.cellSpacing}
            style = {props.style}
        >
            {props.children}
        </table>
    )
}

export default Table;