import React from "react";
import List from "../../UI/List";
import Text from "../../UI/Text";
import Image from "../../UI/Image";

// We can even make this generic - For now, lets keep it as it is

const BrandPowerStatistics = ({ stats, selectedLanguage}) => {
  return (
    <List key={stats.id}>
      <div> 
        <Image
          imgLocation={stats.img_src}
          imgCustomClass = "animated bounceIn"
          imgAltText={stats.title[selectedLanguage]}
        />
        {/* Paragraph being Block-Level element */}
        <p>{stats.title[selectedLanguage]}</p>
        <Text
          text={stats.description}
        />
      </div> 
    </List>
  );
};

export default BrandPowerStatistics;
