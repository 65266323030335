import {Form,Input,Button,TextArea,Table,TableCell} from "../UI/index"

const tableAttr = {
  width : "100%",
  border : 0,
  cellSpacing : 0,
  cellPadding : 0
}

const ContactUsForm = ({onUserInfoSubmit,onUserInputChange,userInput,selectedLanguage,langConfig, disableBtn}) => {
  return (
    <Form onSubmit={onUserInfoSubmit}>
    <Table {...tableAttr}>
      <tbody>
          <tr>
            <TableCell width = "50%">
              {/* <label>Enter Name</label> */}
              <Input
                name="name"
                type="text"
                value={userInput.expenseType}
                placeholder={userInput.name !== "" ? userInput.name : langConfig.form_inputs[0]['name'][selectedLanguage]}
                onChange={onUserInputChange}
              />
            </TableCell>
            <TableCell width = "50%">
              {/* <label>Enter Email</label> */}
              <Input
                name="email"
                type="email"
                placeholder={
                  userInput.email !== "" ? userInput.email : langConfig.form_inputs[0]['email'][selectedLanguage]
                }
                value={userInput.email}
                onChange={onUserInputChange}
              />
            </TableCell>
          </tr>
          <tr>
            <TableCell width = "50%">
              {/* <label>Phone Number</label> */}
              <Input
                name="phone"
                type="number"
                placeholder={
                  userInput.phone !== "" ? userInput.phone : langConfig.form_inputs[0]['phone'][selectedLanguage]
                }
                value={userInput.phone}
                onChange={onUserInputChange}
              />
            </TableCell>
            <TableCell width = "50%">
              {/* <label>Company Name</label> */}
              <Input
                name="company"
                type="text"
                placeholder={
                  userInput.company !== "" ? userInput.company : langConfig.form_inputs[0]['company'][selectedLanguage]
                }
                value={userInput.company}
                onChange={onUserInputChange}
              />
            </TableCell>
          </tr>
          <tr>
            <TableCell width = "100%" colSpan = "2">
              {/* <label>Tell us something about you</label> */}
              <TextArea
                name="extraInfo"
                id="tellus"
                placeholder={
                  userInput.extraInfo !== ""
                  ? userInput.extraInfo
                  : langConfig.form_inputs[0]['message'][selectedLanguage]
                }
                value={userInput.extraInfo}
                onChange={onUserInputChange}
              />
            </TableCell>
          </tr>
          <tr>
            <TableCell width = "50%">
              <Button
                text={langConfig.submit[selectedLanguage]}
                type="submit"
                //onClick={onUserInfoSubmit}
                buttonCustomClass = "q-btn-red"
                disabled={disableBtn}
              />
            </TableCell>
            <TableCell width = "50%">
              &nbsp;
            </TableCell>
          </tr>
      </tbody>
    </Table>    
    </Form>
  );
};

export default ContactUsForm;
