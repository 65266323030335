import React from 'react';
import Image from "../../UI/Image";

const ClientsListing = ({client}) => {
    return (
        <li key={client.id} className = "list_item">
            <Image 
                imgLocation = {client.img_src}
                imgCustomClass = "animated bounceIn"
                imgAltText="Clues Network Client" 
            />
        </li>
    );
}

export default ClientsListing;
