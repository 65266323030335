import React from "react";
import styles from "./Clients.module.css";
import ClientsDescriptionListing from "./ClientsDescriptionListing";
const ClientsDescription  = ({selectedLanguage,onLanguageChangeSelector}) => {
    return (
        <div className={`${styles.wrapper} ${styles.content}`}>
            <ClientsDescriptionListing 
                selectedLanguage = {selectedLanguage}
                onLanguageChangeSelector = {onLanguageChangeSelector}
            />
        </div>
    )    
}

export default ClientsDescription;