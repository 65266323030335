import React , {useEffect} from 'react';
import MainContainer from '../UI/MainContainer';
import ServicesHeader from '../Section/Services/ServicesHeader';
import ProductOfferings from '../Section/Services/ProductOfferings';
import BrandDigitalProducts from '../Section/Services/BrandDigitalProducts';
import DigitalProductSuccess from '../Section/Services/DigitalProductSuccess';
import ScheduleExpertSession from '../Section/Services/ScheduleExpertSession';
import Header from "../Layout/Header/Header";
import Footer from "../Layout/FooterNew/Footer";
import { useParams } from 'react-router-dom';
import "../../assets/css/inner_pages.css";


const Services = ({selectedLanguage, onLanguageChangeSelector}) => {
    const { serviceName } = useParams();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      });
    return (
        <>
        <Header
          selectedLanguage={selectedLanguage}
          onLanguageChangeSelector={onLanguageChangeSelector}
          headerCustomClass="inner"
        />
        <MainContainer>
            <ServicesHeader 
                selectedLanguage={selectedLanguage}
                type={serviceName} 
            />
            <BrandDigitalProducts
                selectedLanguage={selectedLanguage}
                type={serviceName}  
            />
             <DigitalProductSuccess
                selectedLanguage={selectedLanguage}
                type={serviceName}
            /> 
            <ProductOfferings
                selectedLanguage={selectedLanguage}
                type={serviceName}
            />
            <ScheduleExpertSession
                selectedLanguage = {selectedLanguage}
            />
            <Footer
                selectedLanguage={selectedLanguage}
            />
        </MainContainer>
        

        </>
        
    )
}

export default Services;
