const leaderboard = {
  heading_text: {
    EN: "Why choose CNTech?",
    KR: "시엔태크 가 최선의 선택인 이유",
  },
  items: [
    {
      id: 1,
      img_src: require("../assets/images/whyclnet_1.png"),
      intro: { EN: "Short Go Live Time", KR: "신속한 개발 및 반영" },
      description: {
        EN: "Plug and play ecommerce platform & ecosystem",
        KR: "Plug & Play e커머스 시스템으로 신속하고 효율적인 개발지원",
      },
    },
    {
      id: 2,
      img_src: require("../assets/images/whyclnet_2.png"),
      intro: {
        EN: "Empowered Data Driven Decision Making",
        KR: "데이터 기반의 의사결정",
      },
      description: {
        EN: "AI driven technology architecture",
        KR: "AI 기반의 기술 아키텍쳐를 통한 분석툴 및 데이터 마이닝 지원",
      },
    },
    {
      id: 3,
      img_src: require("../assets/images/whyclnet_3.png"),
      intro: {
        EN: "Increased Sales Across Channels",
        KR: "채널을 통한 매출증대",
      },
      description: {
        EN: "By providing a seamless brand experience",
        KR: "다양한 채널의 통합을 통해서, 고객의 방문/구매를 촉진",
      },
    },
    {
      id: 4,
      img_src: require("../assets/images/whyclnet_4.png"),
      intro: {
        EN: "Enhanced operational efficiency",
        KR: "운영의 효율성 증대",
      },
      description: {
        EN: "Through catalog, merchandising, end to end marketing, fulfilment operations & customer support",
        KR: "아이템 등록, 상품판매, 마케팅, 풀필먼트, 고객응대까지 원스톱 서비스를 제공​.",
      },
    },
  ],
  button_text: { EN: "Know More", KR: "자세히 보기" },
};

const cluesNetworkStatisticsInfo = [
  {
    id: 1,
    img_src: require("../assets/images/powering_1.png"),
    title: { EN: "Daily Orders", KR: "일일 주문" },
    description: "150K+",
  },
  {
    id: 2,
    img_src: require("../assets/images/powering_2.png"),
    title: { EN: "Monthly Visitors", KR: "월간 방문자" },
    description: "100 Milltion+",
  },
  {
    id: 3,
    img_src: require("../assets/images/powering_3.png"),
    title: { EN: "Catalog", KR: "목록" },
    description: "2 Million+",
  },
  {
    id: 4,
    img_src: require("../assets/images/powering_4.png"),
    title: { EN: "Online Brand Store", KR: "온라인 브랜드 스토어" },
    description: "7000+",
  },
  {
    id: 5,
    img_src: require("../assets/images/powering_5.png"),
    title: { EN: "Large Malls", KR: "대형 쇼핑몰" },
    description: "200+",
  },
  {
    id: 6,
    img_src: require("../assets/images/powering_6.png"),
    title: { EN: "Annual GMV", KR: "연간 GMV" },
    description: "$3 Billion+",
  },
  {
    id: 7,
    img_src: require("../assets/images/powering_7.png"),
    title: { EN: "Annual Offline Customers", KR: "연간 오프라인 고객" },
    description: "30M+",
  },
  {
    id: 8,
    img_src: require("../assets/images/powering_8.png"),
    title: { EN: "Global Brands", KR: "글로벌 브랜드" },
    description: "100+",
  },
  {
    id: 9,
    img_src: require("../assets/images/powering_9.png"),
    title: { EN: "Merchant", KR: "상인" },
    description: "600K+",
  },
];

const cluesNetworkClients = [
  {
    id: 1,
    img_src: require("../assets/images/client_1.png"),
  },
  {
    id: 2,
    img_src: require("../assets/images/client_2.png"),
  },
  {
    id: 3,
    img_src: require("../assets/images/client_3.png"),
  },
  {
    id: 4,
    img_src: require("../assets/images/client_4.png"),
  },
  {
    id: 5,
    img_src: require("../assets/images/client_5.png"),
  },
  {
    id: 6,
    img_src: require("../assets/images/code_yellow.png"),
  },
  {
    id: 7,
    img_src: require("../assets/images/digi_m.png"),
  },
  {
    id: 8,
    img_src: require("../assets/images/gem.png"),
  },
  {
    id: 9,
    img_src: require("../assets/images/j_maze.png"),
  },
  {
    id: 10,
    img_src: require("../assets/images/liberty.png"),
  },
  {
    id: 11,
    img_src: require("../assets/images/man_c.png"),
  },
  {
    id: 12,
    img_src: require("../assets/images/moms_co.png"),
  },
  {
    id: 13,
    img_src: require("../assets/images/repid.png"),
  },
  {
    id: 14,
    img_src: require("../assets/images/shukhi.png"),
  },
];

const clientSlider = {
  infinite: false,
  arrows: true,
  dots: true,
  slidesToShow: 5,
  slidesToScroll: 1,
  autoplay: false,
  autoplaySpeed: 3000,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 4,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 740,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
  ],
};

const footer_navigation = [
  {
    id: 1,
    name: { EN: "Contact Us", KR: "문의하기" },
    route: "/contact",
  },
];

const addressMetaInfo = [
  {
    key: "title",
    class: "title",
  },
  {
    key: "email_id",
    class: "address",
    additional_info: "email_id_title",
  },
  {
    key: "address_korea",
    class: "address",
    additional_info: "address_title_korea",
  },
  {
    key: "address_india",
    class: "address",
    additional_info: "address_title_india",
  },
];

const omnichannelListing = [
  {
    key: 1,
    text: {
      EN: "Ecommerce Solutions",
      KR: "전자상거래 솔루션",
    },
  },
  {
    key: 2,
    text: {
      EN: "Omnichannel Enablement",
      KR: "옴니채널 지원",
    },
  },
  {
    key: 3,
    text: {
      EN: "Smart Operations",
      KR: "스마트 운영",
    },
  },
];

const brandSolutions = {
  header_section: {
    EN: "Our Services",
    KR: "우리의 서비스",
  },
  body_section: [
    {
      id: 1,
      title: { EN: "Product Engineering", KR: "제품 엔지니어링" },
      description: {
        EN: "We deliver end-to-end corporate product development, personalization, and integration to build excellent software solutions for your company.",
        KR: "당사는 귀사를 위한 우수한 소프트웨어 솔루션을 구축하기 위해 End-to-End 기업 제품 개발, 개인화 및 통합을 제공합니다.",
      },
      footer_flag: 1,
      link: "/services/ProductEngineering",
    },
    {
      id: 2,
      title: { EN: "Web Application Development", KR: "웹 애플리케이션 개발" },
      description: {
        EN: "We create platform-agnostic, feature-rich web apps that allow businesses to engage with their customers across all touch points.",
        KR: "우리는 기업이 모든 접점에서 고객과 소통할 수 있도록 플랫폼에 구애 받지 않고 기능이 풍부한 웹 앱을 만듭니다.",
      },
      footer_flag: 1,
      link: "/services/WebApplicationDevelopment",
    },
    {
      id: 3,
      title: { EN: "Internet Of Things", KR: "사물 인터넷" },
      description: {
        EN: "We will create futuristic yet practical applications and forge a new path toward innovation by combining the power of hardware, software, and the Internet of Things.",
        KR: "하드웨어, 소프트웨어, 사물인터넷의 힘을 결합하여 미래지향적이면서도 실용적인 애플리케이션을 만들고 혁신을 향한 새로운 길을 만들어 가겠습니다.",
      },
      footer_flag: 1,
      link: "/services/InternetOfThings",
    },
    {
      id: 4,
      title: { EN: "Mobile App Development", KR: "모바일 앱 개발" },
      description: {
        EN: "In a mobile world, customers and employees look for more ways to interact with your brand.",
        KR: "모바일 세계에서 고객과 직원은 브랜드와 상호 작용할 수 있는 더 많은 방법을 찾습니다.",
      },
      footer_flag: 1,
      link: "/services/MobileAppDevelopment",
    },
    {
      id: 5,
      title: { EN: "Cloud Engineering", KR: "클라우드 엔지니어링" },
      description: {
        EN: "We offer complete product development, customization, and integration to help you create great software solutions for your business.",
        KR: "우리는 완벽한 제품 개발, 사용자 정의 및 통합을 제공하여 귀하의 비즈니스를 위한 훌륭한 소프트웨어 솔루션을 생성할 수 있도록 지원합니다.",
      },
      footer_flag: 1,
      link: "/services/CloudEngineering",
    },
    {
      id: 6,
      title: { EN: "ECommerce Development", KR: "전자상거래 개발" },
      description: {
        EN: "If you develop or revamp your eCommerce website or keep it updated, Gio-Commerce is the one-stop solution for you. We deliver outstanding.",
        KR: "시엔태크는 전자 상거래 웹 사이트를 개발 또는 개선하거나 지속적인 업데이트을 위한 훌륭한 원스톱 솔루션을 제공합니다.",
      },
      footer_flag: 1,
      link: "/services/eCommerceDevelopment",
    },
    {
      id: 7,
      title: { EN: "UI/UX Design", KR: "UI/UX 디자인" },
      description: {
        EN: "Our product design services range from creating intuitive user journeys to creating exceptional user interactions.",
        KR: "당사의 제품 디자인 서비스는 직관적인 사용자 경험 생성에서 탁월한 사용자 상호 작용 생성에 이르기까지 다양합니다.",
      },
      footer_flag: 0,
      link: "/services/UI_UX_Design",
    },
    {
      id: 8,
      title: {
        EN: "React Native App Development",
        KR: "React 네이티브 앱 개발",
      },
      description: {
        EN: "You are partnering with a powerful team of creative and talented professionals when you choose our React Native App Development company.",
        KR: "React Native App Development는 창의적이고 재능 있는 전문가들로 구성된 강력한 팀과 협력하게 됩니다.",
      },
      footer_flag: 0,
      link: "/services/ReactNativeAppDevelopment",
    },
    {
      id: 9,
      title: { EN: "Enterprise App Development", KR: "엔터프라이즈 앱 개발" },
      description: {
        EN: "Replace sluggish and outdated technology in your business with superfast, scalable, and robust technological platforms.",
        KR: "비즈니스의 느리고 오래된 기술을 확장 가능하고 강력한 초고속 기술 플랫폼으로 교체하십시오.",
      },
      footer_flag: 0,
      link: "/services/EnterpriseAppDevelopment",
    },
  ],
};

const cluesNetworkSolutions = [
  {
    id: 1,
    title: { EN: "Ecommerce Solutions", KR: "이커머스 솔루션" },
    class: "ecommerce",
    body_section: [
      {
        id: 1,
        header_text: { EN: "Web & Mobile Stores", KR: "웹 및 모바일 스토어" },
        description: [
          {
            EN: "Fully functional, responsive eCommerce Website",
            KR: "쇼핑몰 구축​",
          },
          { EN: "Android & iOS Apps", KR: "안드로이 / 아이폰 APP 개발​" },
          { EN: "Payment gateway integrations", KR: "온라인 결제 개발​" },
          { EN: "Shipping platform Integrations", KR: "배송 플랫폼 통합" },
          {
            EN: "Personalization and CRM integrations to maximise",
            KR: "매출 극대화를 개인화 서비스 및 CRM 통합",
          },
          {
            EN: "Customer Experience",
            KR: "고객만족 극대화를 위한 통합서비스",
          },
          {
            EN: "Clickstream tracking and analysis",
            KR: "클릭스트림 추적 및 분석",
          },
        ],
      },
      {
        id: 2,
        header_text: { EN: "Digitising Catalog", KR: "카탈로그 디지털화" },
        description: [
          {
            EN: "Photoshoot & Creation of Product Catalog",
            KR: "사진 촬영 및 제품 카탈로그 제작",
          },
          { EN: "Catalog Enrichment", KR: "카탈로그 보강" },
          {
            EN: "Content Creation, Keyword Optimisation",
            KR: "콘텐츠 제작, 키워드 최적화",
          },
        ],
      },
      {
        id: 3,
        header_text: { EN: "Integrated Store Manager", KR: "통합 매장 관리자" },
        description: [
          { EN: "Catalog & Inventory Management", KR: "카탈로그 및 재고 관리" },
          {
            EN: "Promotions & Pricing Management",
            KR: "프로모션 및 가격 관리",
          },
          { EN: "Order Processing & Fulfilment", KR: "주문 처리 및 이행" },
          { EN: "Returns Management", KR: "반품 관리" },
          { EN: "Payments Reconciliation", KR: "결제 조정" },
          { EN: "Reporting & Analytics", KR: "보고 및 분석" },
          { EN: "Campaign Management", KR: "캠페인 관리" },
        ],
      },
    ],
  },
  {
    id: 2,
    title: { EN: "Omni-channel", KR: "옴니채널 구축" },
    class: "omni",
    body_section: [
      {
        id: 1,
        header_text: { EN: "ERP And POS Integrations", KR: "ERP 및 POS 통합" },
        description: [
          {
            EN: "Quick Integration with existing ERP and POS solution already deployed at retailers",
            KR: "오프라인 매장의 Pos 시스템과 ERP 시스템과 통합",
          },
          {
            EN: "State-of-the-Art Smart-POS hardware available for retailers to upgrade with seamless ERP integrations",
            KR: "ERP 시스템과 통합이 가능한 Smart Pos의 제공​",
          },
          { EN: "WMS integrations", KR: "WMS(창고관리 시스템)의 통합" },
        ],
      },
      {
        id: 2,
        header_text: {
          EN: "Single Inventory View",
          KR: "단일 인벤토리 시스템",
        },
        display_type: "paragraph",
        description: {
          EN: "Integrated real-time multi-channel inventory view enabling smooth omni-channel and O2O experience",
          KR: "단일 인벤토리 시스템을 통한 다양한 채널에서의 통합된 재고관리 기능제공",
        },
      },
      {
        id: 3,
        header_text: { EN: "Safety Stock", KR: "안전 재고 관리" },
        display_type: "paragraph",
        description: {
          EN: "Retailers maintain safety stock to maximise business and order fulfilment across channels",
          KR: "주문처리 효율을 제고하기 위한 안전재고 시스템 제공​",
        },
      },
      {
        id: 4,
        header_text: { EN: "Save the Sale", KR: "주문저장 관리" },
        display_type: "paragraph",
        description: {
          EN: "In-store staff assists customers to place order for unavailable item and getting it fulfilled from another store or warehouse",
          KR: "현재 매장의 재고가 없는 주문을 연동해서 다른 매장에서 처리가 가능하게 하는 주문저장 시스템 제공​",
        },
      },
      {
        id: 5,
        header_text: { EN: "Endless Aisle", KR: "끝없는 통로" },
        display_type: "paragraph",
        description: {
          EN: "Customers can browse through the entire digital catalog of Retailers at in-store kiosks",
          KR: "현재 매장의 재고가 없는 주문을 연동해서 다른 매장에서 처리가 가능하게 하는 주문저장 시스템 제공​",
        },
      },
      {
        id: 6,
        header_text: { EN: "Click And Collect", KR: "온라인 주문/매장 픽업​" },
        display_type: "paragraph",
        description: {
          EN: "Customers can order the item online and pick from desired store",
          KR: "온라인 주문 후, 매장에서 픽업할 수 있는 기능의 제공​",
        },
      },
      {
        id: 7,
        header_text: { EN: "Fulfil from Store", KR: "매장 주문처리" },
        display_type: "paragraph",
        description: {
          EN: "Each store becomes a fulfilment point for orders received online or via any Omni-channel used case",
          KR: "온라인 또는 다른 채널에서 들어온 주문을 연계해서 각 매장에서 처리할 수 있는 기능 제공​.",
        },
      },
    ],
  },
  {
    id: 3,
    title: { EN: "Smart Operations", KR: "스마트 운영" },
    class: "operations",
    body_section: [
      {
        id: 1,
        header_text: { EN: "Smart Operations", KR: "스마트 운영" },
        display_type: "paragraph",
        description: {
          EN: "Clues Network Technology will manage all digital operations  Enable you to harness the power of Artificial Intelligence & Machine Learning Reporting dashboards to monitor key metrics",
          KR: "AI 학습형 대시보드 통해서, 핵심역량의 모니터링 및 관리 기능의 제공",
        },
      },
      {
        id: 2,
        header_text: { EN: "Marketing Operations", KR: "마케팅 운영" },
        description: [
          { EN: "Digital Marketing", KR: "디지털 마케팅" },
          {
            EN: "SEO / Social Media Marketing",
            KR: "SEO / 소셜 미디어 마케팅",
          },
          { EN: "Hyperlocal Marketing", KR: "하이퍼로컬 마케팅" },
          {
            EN: "Highly curated campaigns across customer cohorts",
            KR: "고객에 타켓팅된 캠페인 제작​",
          },
          {
            EN: "Monthly calendar driven marketing and advanced festive planning",
            KR: "월간 마케팅 및 프로모션 스케줄링",
          },
          { EN: "Campaign & Deal management", KR: "캠페인 및 딜 관리" },
          { EN: "Banner Designing", KR: "배너 디자인" },
        ],
      },
      {
        id: 3,
        header_text: {
          EN: "Customer Experience & Support Operations Technology",
          KR: "고객 경험 및 지원 운영 기술",
        },
        description: [
          {
            EN: "Both Inbound & Outbound for customer (Voice) – multi lingual",
            KR: "인/아웃바운드 콜 지원 (다국어 지원)​",
          },
          { EN: "Email support for customer", KR: "이메일 응대 지원" },
          { EN: "Escalation handling team", KR: "고객문의 인계 처리팀" },
          {
            EN: "Social media grievance support team",
            KR: "소셜미디어 클레임 지원팀",
          },
          {
            EN: "Training team & Transaction Quality audit team",
            KR: "교육팀 및 서비스 퀄리티 감사팀",
          },
          { EN: "Chatbot handling team", KR: "챗봇 처리팀" },
        ],
      },
      {
        id: 4,
        header_text: {
          EN: "Logistics & Fulfilment Operations",
          KR: "물류 및 주문 처리 작업",
        },
        description: [
          {
            EN: "Largest Network of leading Courier Partners across globe",
            KR: "전세계 배송파트너 네트워크",
          },
          { EN: "AI based automated shipping", KR: "AI 기반 자동 배송" },
          {
            EN: "API integration with all channels",
            KR: "모든 채널과 API 통합",
          },
          { EN: "Live Tracking", KR: "라이브 트래킹" },
          { EN: "Advanced Analytics and Reporting", KR: "향상된 분석 및 보고" },
          { EN: "Dispute Resolution", KR: "분쟁 해결" },
          {
            EN: "Full service return management",
            KR: "통합 서비스 제공 및 반품 관리",
          },
          {
            EN: "Warehousing and Packing Service",
            KR: "창고 서비스 및 포장 서비스",
          },
        ],
      },
      {
        id: 5,
        header_text: { EN: "Cataloging Operations", KR: "카탈로그 작업" },
        description: [
          {
            EN: "Catalog creation and Photoshoot",
            KR: "카탈로그 생성 및 사진 촬영",
          },
          { EN: "Catalog upload operations", KR: "카탈로그 업로드 작업" },
        ],
      },
    ],
  },
];
const ticketApiEndPoint =
  "https://support.cluesnetwork.com/api/http.php/tickets.json";
const brandIndustries = {
  header_section: {
    EN: "Industries",
    KR: "산업",
  },
  body_section: [
    {
      id: 1,
      title: {
        EN: "Retail and E-Commerce",
        KR: "소매 및 전자 상거래",
      },
      description: {
        EN: "An enterprise e-commerce platform must manage a wide range of operations, including rendering product catalogs, managing orders, and connecting with third-party systems. When you consider large order volumes, multiple currencies, and different languages, the complexity of these tasks increases.",
        KR: "엔터프라이즈 전자 상거래 플랫폼은 제품 카탈로그 렌더링, 주문 관리 및 타사 시스템과의 연결을 포함하여 광범위한 작업을 관리해야 합니다. 대량 주문, 여러 통화 및 다른 언어를 고려할 때 이러한 작업의 복잡성이 증가합니다.",
      },
      image: require("../assets/images/industry_home_1.png"),
      footer_flag: 1,
      link: "/industries/retailAndecomm",
    },
    {
      id: 2,
      title: {
        EN: "Sports and Entertainment",
        KR: "스포츠 및 엔터테인먼트",
      },
      description: {
        EN: "Sports and entertainment is a type of spectacle that uses a high level of theatrical flourish and extravagant presentation to present a seemingly competitive event with the intent of entertaining an audience rather than sporting competition.",
        KR: "스포츠 및 엔터테인먼트는 스포츠 경기보다는 청중을 즐겁게 할 목적으로 겉보기에 경쟁적인 이벤트를 제시하기 위해 높은 수준의 극장의 환경과 화려한 프레젠테이션을 사용하는 일종의 스펙터클입니다.",
      },
      image: require("../assets/images/industry_home_2.png"),
      footer_flag: 1,
      link: "/industries/sportsAndentertainment",
    },
    {
      id: 3,
      title: {
        EN: "Media and Publishing",
        KR: "미디어 및 출판",
      },
      description: {
        EN: "Media and Publishing is the sole organization that caters to the requirements of print and digital publishers in nonprofit organizations. AM&P also serves association business operations executives,communication professionals, designers, editors, and content creators, as well as the media they create.",
        KR: "비영리 조직에서 인쇄 및 디지털 출판사의 요구 사항을 충족하는 유일한 조직입니다. 미디어 및 출반은 또한 협회 비즈니스 운영 임원, 커뮤니케이션 전문가, 디자이너, 편집자, 콘텐츠 제작자 및 이들이 만드는 미디어에 서비스를 제공합니다.",
      },
      image: require("../assets/images/industry_home_3.png"),
      footer_flag: 1,
      link: "/industries/mediaAndpublish",
    },
    {
      id: 4,
      title: {
        EN: "Food and Beverage",
        KR: "음식 및 음료",
      },
      description: {
        EN: "F&B Solutions is a progressive B2B service provider in the UK casual dining market. We create, build, and multiple food and beverage solutions under our own distinctive brands, packaging them into extensive and ready-to-use solutions for hospitality establishments.",
        KR: "F&B Solutions는 영국 캐주얼 다이닝 시장의 진보적인 B2B 서비스 제공업체입니다. 우리는 우리 고유의 브랜드로 다양한 식음료 솔루션을 만들고 구축하며 이를 접객 시설을 위한 광범위하고 바로 사용할 수 있는 솔루션으로 패키징합니다.",
      },
      image: require("../assets/images/industry_home_4.png"),
      footer_flag: 1,
      link: "/industries/foodAndbeverages",
    },
    {
      id: 5,
      title: {
        EN: "Health Care and Fitness",
        KR: "건강 관리 및 피트니스",
      },
      description: {
        EN: "Healthcare, like most other industries, has been rapidly transformed through technological advances.The use of medical smartphone applications has increased across the globe, with an increasing number of people who rely on them for everything from medical treatments to general health and wellness.",
        KR: "대부분의 다른 산업과 마찬가지로 의료 분야도 기술 발전을 통해 빠르게 변모했습니다. 의료용 스마트폰 애플리케이션의 사용이 전 세계적으로 증가했으며 의료에서 일반 건강 및 웰빙에 이르기까지 모든 분야에서 스마트폰에 의존하는 사람들이 증가하고 있습니다.",
      },
      image: require("../assets/images/industry_home_5.png"),
      footer_flag: 1,
      link: "/industries/health",
    },
    {
      id: 6,
      title: {
        EN: "Education and Training",
        KR: "교육과 훈련",
      },
      description: {
        EN: "E-learning is a quick way to deliver lessons. In comparison to the traditional classroom method, this mode has relatively quick delivery cycles. This means that the time required to learn has been reduced to 25% -60% of what is required in traditional learning.",
        KR: "E-Learning은 수업을 빠르게 전달할 수 있는 방법입니다. 기존의 강의실 방법과 비교하여 이 모드는 전달 주기가 비교적 빠릅니다. 이는 학습에 필요한 시간이 기존 학습에 필요한 시간의 25% -60%로 단축되었음을 의미합니다.",
      },
      image: require("../assets/images/industry_home_6.png"),
      footer_flag: 1,
      link: "/industries/educationAndtraining",
    },
    {
      id: 7,
      title: {
        EN: "Logistics",
        KR: "물류 센터",
      },
      description: {
        EN: "Experts in e-learning software development create unmatched solutions that promote positive user experiences for evolved educational systems to meet the goals of higher education institutions,universities, high schools, primary schools, trade schools, continuing education, as well as business and corporations.",
        KR: "e-러닝 소프트웨어 개발 전문가는 고등 교육 기관, 대학, 고등학교, 초등학교, 무역 학교, 평생 교육, 기업 및 기업의 목표를 충족하기 위해 진화된 교육 시스템에 대한 긍정적인 사용자 경험을 촉진하는 탁월한 솔루션을 만듭니다.",
      },
      image: require("../assets/images/industry_home_7.png"),
      footer_flag: 0,
      link: "/industries/logistics",
    },
  ],
};
module.exports = {
  leaderboard,
  cluesNetworkStatisticsInfo,
  cluesNetworkClients,
  footer_navigation,
  clientSlider,
  addressMetaInfo,
  omnichannelListing,
  cluesNetworkSolutions,
  brandSolutions,
  ticketApiEndPoint,
  brandIndustries,
};
