import {React,useState} from "react";

import PropTypes from "prop-types";

import { Link } from "react-router-dom";

const NavigationItem = ({ name, linkTo ,className, items, lang,toggleMobileMenu}) => {
  
    const [expandMenu, setExpandMenu] = useState(false);

    const toggleMenuExpand = () => {
        setExpandMenu(!expandMenu);
    };
    const toggleMobileMenuHandler = (event) => {
        toggleMobileMenu(event.target.value);
      };
  if(className){
    if(expandMenu) {
        className = className +" active";
    }
    return(
    <li className={className} onClick={toggleMenuExpand}>
      {linkTo === window.location.pathname ? <a href={void(0)} >{name}</a>: <a href={void(0)} >{name}</a>
    }
    
    <ul>
    {items.map((dropDownItems) => {
    return(
      (linkTo === window.location.pathname ? <Link to={dropDownItems.itemRoute}>{dropDownItems.itemName[lang]}</Link>: <Link to={dropDownItems.itemRoute}>{dropDownItems.itemName[lang]}</Link>)
    )}
  )
  }
    </ul>
      
    </li>
    );
    }else{
    return(
      <li>
      {linkTo === window.location.pathname ? <Link to={linkTo} className = "active">{name}</Link>: <Link to={linkTo}>{name}</Link>
    }
    </li>
    );
    }
}

NavigationItem.propTypes = {
  name: PropTypes.string,
  linkTo: PropTypes.string,
  className:PropTypes.string,
  items:PropTypes.any,
  lang:PropTypes.string,
};

export default NavigationItem;
