let banner_info = {
  head_text: {
    EN: "Unifying your customer's offline and online brand experience",
    KR: "고객만족 극대화를 위한 e-커머스 통합 솔루션 제공",
  },
  head_sub_text: {
    EN: "We will deploy powerful, robust, scalable & result-oriented next-gen technology for your business",
    KR: "우리는 귀하의 비즈니스를 위해 강력하고 강력하며 확장 가능하며 결과 지향적인 차세대 기술을 배포할 것입니다.",
  },
  button_text: {
    EN: "Let’s talk",
    KR: "이야기하자",
  },
};
let footer_info = {
  clues_text: {
    EN: "CNTech",
    KR: "시엔태크",
  },
  clues_description: {
    EN: "An end to end managed E-commerce and Omnichannel Solutions and Service provider to Retailers and Brands",
    KR: "고객에게 최상의 이커머스 원스톱 통합 솔루션을 제공합니다.​",
  },
};
let footer_explore_links = {
  heading_text: {
    EN: "Explore",
    KR: "탐구하다",
  },
};

const contact_us = {
  head_text: {
    EN: "Contact Us",
    KR: "문의하기",
  },
  title: {
    EN: "CNTech",
    KR: "시엔태크",
  },
  email_id_title: {
    EN: "Email Id:",
    KR: "이메일:",
  },
  email_id: {
    EN: "connect@cluesnetwork.com",
    KR: "connect@cluesnetwork.com",
  },
  address_title_korea: {
    EN: "Korean Address:",
    KR: "한국 주소",
  },
  address_title_india: {
    EN: "India Address:",
    KR: "인도 주소",
  },
  address_korea: {
    EN: "N Tower 13F, 129 Teheran-ro, Gangnam-gu, Seoul, korea",
    KR: "N Tower 13F, 129 Teheran-ro, Gangnam-gu, Seoul, korea",
  },
  address_india: {
    EN: "Unitech Cyber Park, Tower-B, 14th Floor, Sector - 39, Gurgaon, 122001, \nHaryana, India",
    KR: "Unitech Cyber Park, Tower-B, 14th Floor, Sector - 39, Gurgaon, 122001, \nHaryana, India",
  },
  contact_us_head: {
    EN: "Get in touch with us ",
    KR: "문의하기",
  },
  contact_us_text: {
    EN: "If you have any questions, please write to us here",
    KR: "문의하실 사항이 있으시면, 아래에 내용을 남겨주세요",
  },
  form_inputs: [
    {
      name: {
        EN: "Enter Name",
        KR: "이름을 입력하시오",
      },
      email: {
        EN: "Enter Email",
        KR: "이메일 입력",
      },
      phone: {
        EN: "Phone Number",
        KR: "전화 번호",
      },
      company: {
        EN: "Company Name",
        KR: "회사 이름",
      },
      message: {
        EN: "Tell us something about you",
        KR: "당신에 대해 알려주세요",
      },
    },
  ],
  submit: {
    EN: "Submit",
    KR: "제출하다",
  },
};
const omnichannel = {
  head_text: {
    EN: "Build your Brand, Scale your Brand",
    KR: "브랜드 구축 및 확장을 위한 최상의 솔루션을 제공합니다​",
  },
  know_more: {
    EN: "Know More",
    KR: "자세히 보기",
  },
};

let our_solutions = {
  head_text: {
    EN: "OUR SOLUTIONS",
    KR: "우리의 솔루션",
  },
};

const clients = {
  our_clients: {
    EN: "Our Clients",
    KR: "CNT 고객​",
  },
  client_desc: [
    {
      client_name: {
        EN: "Al Hokair",
        KR: "Al Hokair",
      },
      client_logo: require("../assets/images/client_1.png"),
      client_title: {
        EN: "Alhokair Fashion Retail",
        KR: "Alhokair Fashion Retail",
      },
      client_descr: {
        EN: "Alhokair Fashion Retail was formed in 1990 and currently is the largest franchise retailer in the KSA, MENA, Central Asia and Caucasus regions, and is the only public listed business of its type in the Middle East. Alhokair Fashion Retail now trades in more than 2,000 stores across 100 shopping malls in 14 countries, with a retail platform operating on a total GLA of over 600,000 sqm. . Alhokair Fashion Retail currently represents over 88 fashion brands like Aldo, Celio , GAP, MANGO, Marks and Spencers, Zara etc. across a diverse platform covering Womenswear, Menswear, Kids & Baby, Department Stores, Shoes & Accessories, Cosmetics, F&B, and Entertainment.",
        KR: "Alhokair Fashion Retail은 1990년에 설립되었으며 현재 KSA, MENA, 중앙 아시아 및 코카서스 지역에서 가장 큰 프랜차이즈 소매업체이며 중동에서 해당 카테고리의 유일한 상장 기업입니다. Alhokair Fashion Retail은 현재 14개국의 100개 쇼핑몰에 걸쳐 2,000개 이상의 매장을 운영하고 있으며,  이들 매장의 총 규모는  600,000 제곱피트 이상으로 산정되고 있습니다 . Alhokair Fashion Retail은 현재 88개의 유명 브랜드 (GAP, MANGO, MARKS&Spencers, ZARA 등) 판매하고 있으며, 여성의류, 패션, 남성의류, 아동의류 등 다양한 상품을 취급하고 있습니다.",
      },
      section_class: "padding-l",
      logo_class: "client_logo hokair",
    },
    {
      client_name: {
        EN: "V-MART",
        KR: "V-MART",
      },
      client_logo: require("../assets/images/client_2.png"),
      client_title: {
        EN: "India’s Leadng aRetail Company",
        KR: "India’s Leadng aRetail Company",
      },
      client_descr: {
        EN: "The world’s best-performing department store, 2017-18’ according to Bloomberg  V-Mart is one of India's leading retail companies. With annual sales of $188 mn(FY 2017-18), its 171 stores covering 1.4 mn sq ft attract an annual footfall of close to 30 million in metros, Tier 1,2,3 and 4 cities across North, Central and Eastern India.",
        KR: "블룸버그 기사에 따르면, V-Mart는 2017년 인도 최고의 백화점으로 선정된 인도의 주요 유통회사 중 하나입니다. 연간 매출이 1억 8800만 달러(2017-18 회계연도)로 140만 평방피트에 달하는 171개 매장을 운영하고 있으며,  인도 전역에서 연간 약 3천만 명의 고객이 방문합니다",
      },
      section_class: "padding-r",
      logo_class: "client_logo vmart",
    },
  ],
};

const servicesHeader = {
  ProductEngineering: {
    title: {
      EN: "Witness A Revolution In Digital Product Engineering",
      KR: "디지털 제품 엔지니어링의 혁명을 지켜보세요",
    },
    description: {
      EN: "We offer enterprise end-to-end product development, customization, and integration to help you create great software solutions for your company.",
      KR: "우리는 기업을 위한 훌륭한 소프트웨어 솔루션을 만드는 데 도움이 되는 엔터프라이즈 End-to-End 제품 개발, 사용자 지정 및 통합을 제공합니다.",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },

  WebApplicationDevelopment: {
    title: {
      EN: "Enjoy Our Web Development Services to Disrupt Your Market",
      KR: "귀하의 시장을 혁신하기 위해 당사의 웹 개발 서비스를 즐기십시오",
    },
    description: {
      EN: "We create platform-independent, feature-rich web apps that allow businesses to engage with their customers across all touch points.",
      KR: "우리는 기업이 모든 접점에서 고객과 소통할 수 있도록 하는 플랫폼 독립적이고 기능이 풍부한 웹 앱을 만듭니다..",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },

  InternetOfThings: {
    title: {
      EN: "Conquer The Future With Our IoT App Development Company",
      KR: "IoT 앱 개발 회사와 함께 미래를 정복하십시오",
    },
    description: {
      EN: "Collaborate with CNTech to unleash a revolution in the IoT space and accelerate your growth and progress.",
      KR: "시엔태크와 협력하여 IoT 공간에서 혁명을 일으키고 성장과 발전을 가속화하십시오.비즈니스 목표 달성을 지원하는 미래형 IoT 솔루션",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },

  MobileAppDevelopment: {
    title: {
      EN: "Mobile App Development Company that Triggers a Revolution",
      KR: "혁신을 촉발하는 모바일 앱 개발 회사",
    },
    description: {
      EN: "Hire us to build robust, scalable, and user-friendly mobile apps for your business on both the Android and iOS platforms, and leverage our decades of experience in mobile app development.",
      KR: "Android 및 iOS 플랫폼 모두에서 비즈니스를 위해 강력하고 확장 가능하며 사용자 친화적인 모바일 앱을 구축하고 모바일 앱 개발 분야에서 수십 년 간의 경험을 활용하려면 당사를 고용하십시오.",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
  CloudEngineering: {
    title: {
      EN: "Remove Chaos & Embrace Growth with Cloud Engineering Services",
      KR: "클라우드 엔지니어링 서비스로 혼돈 제거 및 성장 수용",
    },
    description: {
      EN: "We understand that providing a consistent user experience is key to the success of any mobile app. This is why our mobile app development company has taken a human-centered approach.",
      KR: "당사의 강력한 클라우드 엔지니어링 서비스는 귀하의 비즈니스를 혁신하고 장기적인 성장과 지속적인 성공을 촉발하는 촉매제가 될 것입니다. 우리는 귀하의 틈새 비즈니스 요구 사항을 이해할 것입니다.",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },

  eCommerceDevelopment: {
    title: {
      EN: "eCommerce Development Service",
      KR: "전자상거래 개발 서비스",
    },
    description: {
      EN: "If you want to create, redesign, or update your e-commerce website, CNTech is your one-stop solution for you.",
      KR: "전자 상거래 웹 사이트를 생성, 재설계 또는 업데이트하려는 경우 시엔태크가 원스톱 솔루션입니다.",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },

  UI_UX_Design: {
    title: {
      EN: "Deliver Higher Engagement With Human-Centric Experiences",
      KR: "인간 중심적 경험으로 더 높은 참여도 제공",
    },
    description: {
      EN: "Our product design services range from developing intuitive user journeys to creating exceptional user interactions.",
      KR: "당사의 제품 디자인 서비스는 직관적인 사용자 여정 개발에서 탁월한 사용자 상호 작용 생성에 이르기까지 다양합니다.",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },

  ReactNativeAppDevelopment: {
    title: {
      EN: "Our React Native App Development Company Will Accelerate Business Growth",
      KR: "React 네이티브 앱 개발 회사는 비즈니스 성장을 가속화할 것입니다",
    },
    description: {
      EN: "When you choose our react native app development company, you are partnering with a strong team of talented and innovative professionals who will help you amplify your vision and speed up business growth.",
      KR: "리액트 네이티브 앱 개발 회사를 선택하면 비전을 확대하고 비즈니스 성장을 가속화하는 데 도움이 될 재능 있고 혁신적인 전문가로 구성된 강력한 팀과 협력하게 됩니다.",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },

  EnterpriseAppDevelopment: {
    title: {
      EN: "Leverage The Power of Technology with Our Enterprise Application Development",
      KR: "당사의 엔터프라이즈 애플리케이션 개발을 통해 기술의 힘을 활용하십시오",
    },
    description: {
      EN: "With our enterprise application development services, you can replace sluggish and outdated technology with superfast, scalable, and robust technological platforms.",
      KR: "당사의 엔터프라이즈 애플리케이션 개발 서비스를 통해 느리고 오래된 기술을 초고속, 확장 가능하고 강력한 기술 플랫폼으로 대체할 수 있습니다.",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
};

const brandDigitalProducts = {
  ProductEngineering: {
    title: {
      EN: "Design & Launch Unmatchable Digital Products",
      KR: "타의 추종을 불허하는 디지털 제품 디자인 및 출시",
    },
    description: {
      EN: "Let's hinder the $1 trillion digital products market and provide powerful, reliable, and scalable products to millions of users.",
      KR: "1조 달러 규모의 디지털 제품 시장을 파괴하고 강력하고 강력하며 확장 가능한 제품으로 수백만 명의 사용자에게 권한을 부여합시다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Powerful Team",
          KR: "강력한 팀",
        },
        span_content: {
          EN: "Our in-house digital product development team of 60+ will ensure that your digital product is the best in class.",
          KR: "30명 이상의 사내 디지털 제품 개발자가 귀하의 디지털 제품이 동급 최고임을 보장합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Impeccable Experience",
          KR: "완벽한 경험",
        },
        span_content: {
          EN: "Our expertise in designing and launching digital products spans over 11 years.",
          KR: "우리는 디지털 제품을 설계하고 출시하는 데 11년 이상의 완벽한 경험을 가지고 있습니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Digital in our DNA",
          KR: "우리 DNA의 디지털",
        },
        span_content: {
          EN: "We have delivered more than 10 digital products for global enterprises and startups.",
          KR: "우리는 글로벌 기업 및 스타트업을 위해 50개 이상의 디지털 제품을 제공한 경험이 있습니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Fusion of Ideas & Skills",
          KR: "아이디어와 기술의 융합",
        },
        span_content: {
          EN: "Make use of our incredibly talented designers, UX/UI experts, and skilled programmers.",
          KR: "재능있는 프로그래머와 함께 우리의 재능있는 디자이너 및 UX/UI 전문가의 기술을 활용하십시오.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Innovation Partner",
          KR: "혁신 파트너",
        },
        span_content: {
          EN: "Utilize our experience serving 10+ global brands to push your company to unstoppable success.",
          KR: "50개 이상의 글로벌 브랜드에 서비스를 제공한 경험을 활용하고 비즈니스를 위한 멈출 수 없는 성공을 촉발하십시오.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Global Brands",
          KR: "글로벌 브랜드",
        },
        span_content: {
          EN: "Some of the world's largest brands, including Alhokiar, ShopClues, V-Mart, and others, trust and believe in us.",
          KR: "Alhokiar, Shopclues, V-Mart 및 기타와 같은 가장 큰 글로벌 브랜드 중 일부는 우리를 신뢰하고 우리를 믿습니다.",
        },
      },
    ],
  },
  WebApplicationDevelopment: {
    title: {
      EN: "Leverage Our Experience & Expertise to Disrupt the Status-quo",
      KR: "우리의 경험과 전문성을 활용하여 현상 유지",
    },
    description: {
      EN: "We at CNTech are always at the forefront of innovation, triggering a digital revolution by empowering startups, enterprises, and corporations to leverage the power of web platforms and web apps.",
      KR: "시엔태크는 항상 혁신의 최전선에 있으며 신생 기업, 기업 및 기업이 웹 플랫폼 및 웹 앱의 힘을 활용할 수 있도록 지원함으로써 디지털 혁명을 촉발합니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Our Team: Our Strength",
          KR: "우리 팀: 우리의 강점",
        },
        span_content: {
          EN: "Encounter the power of 60+ digitally native, passionate, and creative team members who will ensure our success is constant.",
          KR: "우리의 지속적인 성공을 보장할 60명 이상의 디지털 네이티브, 열정적이고 창의적인 팀원의 힘을 만나십시오.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Impeccable Experience",
          KR: "완벽한 경험",
        },
        span_content: {
          EN: "With over ten years of experience in web development, web application development, cloud computing,and mobile app development.",
          KR: "웹 개발, 웹 애플리케이션 개발, 클라우드 컴퓨팅 및 모바일 앱 개발 분야에서 10년 이상의 경험을 쌓았습니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Seamless Delivery",
          KR: "원활한 배송",
        },
        span_content: {
          EN: "We have delivered over ten mobile apps, web platforms, IoT solutions, and Cloud Computing solutions to 600+ global brands.",
          KR: "우리는 600개 이상의 글로벌 브랜드에 10개 이상의 모바일 앱, 웹 플랫폼, IoT 솔루션 및 클라우드 컴퓨팅 솔루션을 제공했습니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Trusted By Global Brands",
          KR: "글로벌 브랜드의 신뢰",
        },
        span_content: {
          EN: "Some of the world's largest brands, including Alhokiar, ShopClues, V Mart, and others, trust and believe in us.",
          KR: "Alhokiar , ShopClues , V Mart 등을 포함한 세계 최대 브랜드 중 일부는 우리를 신뢰하고 믿습니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Business Expertise",
          KR: "비즈니스 전문성",
        },
        span_content: {
          EN: "Leverage our decades of experience serving some of the world's largest brands to unveil a business revolution for your company.",
          KR: "수십 년 동안 세계 최대 브랜드에 서비스를 제공한 경험을 활용하여 귀사를 위한 비즈니스 혁신을 공개하십시오.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Amalgamation Of Ideas",
          KR: "아이디어의 융합",
        },
        span_content: {
          EN: "Our creative UX/UI experts are based in South Korea and India, and our sharp programmers are based in Gurugram (India), providing our clients.",
          KR: "우리의 창의적인 UX/UI 전문가는 한국과 인도를 기반으로 하고 우리의 날카로운 프로그래머는 Gurugram(인도)에 기반을 두고 고객에게 서비스를 제공합니다.",
        },
      },
    ],
  },
  InternetOfThings: {
    title: {
      EN: "Futuristic IoT Solutions to Help You Reach Your Business Objectives",
      KR: "비즈니스 목표 달성을 지원하는 미래형 IoT 솔루션",
    },
    description: {
      EN: "With CNTech, your company selects the most disruptive and unique thinkers, who take pride in innovation and provide higher quality digital products, bringing all-around performance and diverse capabilities to the table.",
      KR: "시엔태크를 통해 귀사는 혁신에 자부심을 갖고 더 높은 품질의 디지털 제품을 제공하여 만능 성능과 다양한 기능을 테이블에 제공하는 가장 파괴적이고 독창적인 사상가를 선택합니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Specialized IoT Team",
          KR: "IoT 전문팀",
        },
        span_content: {
          EN: "Our 60+ developer team serves as our arsenal in delivering skillfully crafted IoT solutions even under impossible deadlines.",
          KR: "60명 이상의 개발자 팀은 단 기간의 마감 시간에도 능숙하게 제작된 IoT 솔루션을 제공하는 데 있어 무기고 역할을 합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Site-Of-The-Art Infrastructure",
          KR: "최첨단 인프라",
        },
        span_content: {
          EN: "Our comprehensive understanding of IoT, the latest tools, and systematic processes lay the groundwork for delivering robust IoT solutions.",
          KR: "IoT, 최신 도구 및 체계적인 프로세스에 대한 포괄적인 이해는 강력한 IoT 솔루션을 제공하기 위한 토대를 마련합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Flexible Engagement Models",
          KR: "유연한 참여 모델",
        },
        span_content: {
          EN: "We offer an array of engagement models and implement them based on our decades of experience working with some of the world's most recognizable brands.",
          KR: "우리는 세계에서 가장 잘 알려진 브랜드와 함께 일한 수십 년의 경험을 바탕으로 다양한 참여 모델을 제공하고 구현합니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Scalable End Products",
          KR: "확장 가능한 최종 제품",
        },
        span_content: {
          EN: "Our goal is to ensure that your IoT application can handle increased data load as more devices are connected. In this ever-changing world.",
          KR: "우리의 목표는 이 끊임없이 변화하는 세상에서 IoT 애플리케이션이 더 많은 장치가 연결됨에 따라 증가하는 데이터 로드를 처리할 수 있도록 하는 것입니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Quick Turnaround",
          KR: "빠른 처리",
        },
        span_content: {
          EN: "One of our primary focus areas is the rapid delivery of robust and high-quality software. CNTech is known for exceeding client expectations.",
          KR: "우리의 주요 초점 영역 중 하나는 강력하고 고품질의 소프트웨어를 신속하게 제공하는 것입니다. 시엔태크는 고객의 기대치를 뛰어넘는 것으로 유명합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Data Security",
          KR: "데이터 보안",
        },
        span_content: {
          EN: "To keep your confidential information safe, our vigilant team employs the most recent data security practices, compliance, and regulations.",
          KR: "우리의 주요 초점 영역 중 하나는 강력하고 고품질의 소프트웨어를 신속하게 제공하는 것입니다. 시엔태크는 고객의 기대치를 뛰어넘는 것으로 유명합니다.",
        },
      },
    ],
  },

  MobileAppDevelopment: {
    title: {
      EN: "Design & Launch World’s Most Popular Mobile Apps",
      KR: "세계에서 가장 인기 있는 모바일 앱 디자인 및 출시",
    },
    description: {
      EN: "With CNTech, you are selecting a dependable partner for conceptualizing, designing, and launching mobile apps for millions of users worldwide.",
      KR: "시엔태크와 함께 전 세계 수백만 사용자를 위한 모바일 앱의 개념화, 디자인 및 출시를 위한 신뢰할 수 있는 파트너를 선택하고 있습니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Passionate Team",
          KR: "열정적인 팀",
        },
        span_content: {
          EN: "Our passionate team of 60+ designers, developers, solution architects, and business analysts is ready to serve you.",
          KR: "60명 이상의 디자이너, 개발자, 솔루션 설계자 및 비즈니스 분석가로 구성된 열정적인 팀이 귀하에게 서비스를 제공할 준비가 되어 있습니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Decades Of Experience",
          KR: "수십 년의 경험",
        },
        span_content: {
          EN: "With over ten years of experience, we are disrupting the status quo and unleashing never-before-seen innovation.",
          KR: "10년 이상의 경험을 통해 우리는 현상 유지를 파괴하고 이전에 볼 수 없었던 혁신을 촉발합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Successful Deliveries",
          KR: "성공적인 배송",
        },
        span_content: {
          EN: "More than ten mobile apps, digital platforms, and IoT solutions were successfully delivered, empowering millions of users.",
          KR: "10개 이상의 모바일 앱, 디지털 플랫폼 및 IoT 솔루션이 성공적으로 제공되어 수백만 명의 사용자에게 권한을 부여했습니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Business Consultancy",
          KR: "비즈니스 컨설팅",
        },
        span_content: {
          EN: "Our keen business sense and expertise translate into consistent success for your mobile and digital venture.",
          KR: "우리의 예리한 비즈니스 감각과 전문성은 모바일 및 디지털 벤처의 지속적인 성공으로 이어집니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Global Brands",
          KR: "글로벌 브랜드",
        },
        span_content: {
          EN: "Our keen business sense and expertise translate into consistent success for your mobile and digital venture.",
          KR: "Alhokiar , ShopClues , V Mart 등을 포함한 세계 최대 브랜드 중 일부는 우리를 신뢰하고 믿습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Best Of Both Worlds",
          KR: "세계의 최고",
        },
        span_content: {
          EN: "Our talented programmers are based in Gurugram, India, and our creative UX/UI experts are based in South Korea and India.",
          KR: "우리의 재능 있는 프로그래머는 인도 Gurgram에 있으며 창의적인 UX/UI 전문가는 한국과 인도에 있습니다.",
        },
      },
    ],
  },
  CloudEngineering: {
    title: {
      EN: "Why CNTech For Cloud Engineering Services?",
      KR: "클라우드 엔지니어링 서비스를 위하여 시엔태크가 필요한 이유",
    },
    description: {
      EN: "Use our decades of experience in unleashing Digital Transformation through Cloud technologies topush your business forward. We are AWS Certified Cloud Architects and Microsoft Certified Cloud Gold Partners.",
      KR: "클라우드 기술을 통해 디지털 혁신을 실현한 수십 년의 경험을 활용하여 비즈니스를 발전시키십시오. 우리는 AWS 공인 클라우드 설계자이자 Microsoft 공인 클라우드 골드 파트너입니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Power Of Team",
          KR: "팀의 강점",
        },
        span_content: {
          EN: "Work with a full-stack, digitally native team of 60+ trained professionals who will leave no stone unturned to ensure your success.",
          KR: "60명 이상의 숙련된 전문가로 구성된 완전한 디지털 네이티브 팀과 협력하여 성공을 보장합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "10+ Years Of Experience",
          KR: "10년 이상의 경험",
        },
        span_content: {
          EN: "We have over ten years of experience in cloud technologies, as well as digital and mobile engineering,ensuring stellar success.",
          KR: "우리는 클라우드 기술과 디지털 및 모바일 엔지니어링 분야에서 10년 이상의 경험을 갖고 있어 뛰어난 성공을 보장합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Best Of Both Worlds",
          KR: "세계의 최고",
        },
        span_content: {
          EN: "While our skilled designers and UX/UI specialists are centered in South Korea and India, our sharp Cloud engineers and programmers.",
          KR: "우리의 숙련된 디자이너와 UX/UI 전문가는 한국과 인도를 중심으로, 우리의 날카로운 클라우드 엔지니어와 프로그래머입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Business Consultancy",
          KR: "비즈니스 컨설팅",
        },
        span_content: {
          EN: "Our keen business sense and expertise translate into consistent success for your mobile and digital venture.",
          KR: "우리의 예리한 비즈니스 감각과 전문성은 모바일 및 디지털 벤처의 지속적인 성공으로 이어집니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Global Brands",
          KR: "글로벌 브랜드",
        },
        span_content: {
          EN: "Some of the world's largest brands, including Alhokiar, ShopClues, V Mart, and others, trust and believe in us.",
          KR: "Alhokiar , ShopClues , V Mart 등을 포함한 세계 최대 브랜드 중 일부는 우리를 신뢰하고 믿습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Best Of Both Worlds",
          KR: "세계의 최고",
        },
        span_content: {
          EN: "Our talented programmers are based in Gurugram, India, and our creative UX/UI experts are based in South Korea and India.",
          KR: "우리의 재능 있는 프로그래머는 인도 Gurugram에 있으며 창의적인 UX/UI 전문가는 한국과 인도에 있습니다.",
        },
      },
    ],
  },
  eCommerceDevelopment: {
    title: {
      EN: "We Provide eCommerce Software Development Services for All Kinds of Businesses.",
      KR: "우리는 모든 종류의 비즈니스를 위한 전자 상거래 소프트웨어 개발 서비스를 제공합니다.",
    },
    description: {
      EN: "Your concept is unique, and we can make it a beautiful reality. With our custom eCommerce development services, we can assist you in making your dream a reality.",
      KR: "당신의 컨셉은 독특하며 우리는 그것을 아름다운 현실로 만들 수 있습니다. 맞춤형 전자 상거래 개발 서비스를 통해 꿈을 현실로 만드는 데 도움을 드릴 수 있습니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "eCommerce Strategy & Consultation",
          KR: "전자상거래 전략 및 컨설팅",
        },
        span_content: {
          EN: "We provide e-commerce development services to assist you in determining the best solution for your e-commerce business.",
          KR: "우리는 전자 상거래 비즈니스에 가장 적합한 솔루션을 결정하는 데 도움이 되는 전자 상거래 개발 서비스를 제공합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Auction & Bidding Platform",
          KR: "경매 및 입찰 플랫폼",
        },
        span_content: {
          EN: "Take advantage of our services whenever you want to grow your eCommerce business. ",
          KR: "전자 상거래 비즈니스를 성장시키고 싶을 때마다 당사 서비스를 활용하십시오. ",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "eCommerce Website Development",
          KR: "전자상거래 웹사이트 개발.",
        },
        span_content: {
          EN: "eCommerce websites are a must-have for businesses looking to expand into the online market.",
          KR: "전자 상거래 웹 사이트는 온라인 시장으로 확장하려는 기업에게 필수품입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "eCommerce Website Optimization",
          KR: "전자상거래 웹사이트 최적화",
        },
        span_content: {
          EN: "A website is never complete unless it is optimized. When we say top-notch, we really mean it.",
          KR: "웹사이트는 최적화되지 않는 한 완성되지 않습니다. 우리가 최고 수준이라고 말할 때, 우리는 정말로 그것을 의미합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Payment Gateway Integration",
          KR: "결제 게이트웨이 통합",
        },
        span_content: {
          EN: "One of our top priorities is ensuring that your customers can shop safely on both the web and mobile.We integrate your eCommerce. ",
          KR: "우리의 최우선 과제 중 하나는 고객이 웹과 모바일 모두에서 안전하게 쇼핑할 수 있도록 하는 것입니다.우리는 귀하의 전자 상거래를 통합합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Online Store Development ",
          KR: "온라인 스토어 개발",
        },
        span_content: {
          EN: "We are a premier e-commerce development firm that specializes in managing various online store projects of varying complexity.",
          KR: "우리는 다양한 복잡성의 다양한 온라인 상점 프로젝트 관리를 전문으로 하는 최고의 전자 상거래 개발 회사입니다.",
        },
      },
    ],
  },

  UI_UX_Design: {
    title: {
      EN: "User Experience Design Services",
      KR: "사용자 경험 디자인 서비스",
    },
    description: {
      EN: "For each product, our designers use a systematic and interactive design approach to ensure that it meets user expectations.",
      KR: "각 제품에 대해 당사 디자이너는 체계적이고 대화형 디자인 접근 방식을 사용하여 사용자의 기대를 충족하는지 확인합니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Discovery",
          KR: "발견",
        },
        span_content: {
          EN: "As a leading UI/UX design firm, our goal is to create a strategy that supports both your business goals and the needs of your users.",
          KR: "선도적인 UI/UX 디자인 회사로서 우리의 목표는 귀하의 비즈니스 목표와 사용자의 요구를 모두 지원하는 전략을 만드는 것입니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "User Experience",
          KR: "사용자 경험",
        },
        span_content: {
          EN: "User Experience (UX) refers to the user's attitudes and emotions when using mobile or web applications.",
          KR: "사용자 경험(UX)은 모바일이나 웹 애플리케이션을 사용할 때 사용자의 태도와 감정을 의미합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Visual Design",
          KR: "시각 디자인",
        },
        span_content: {
          EN: "The goal of user interface design is to maximize user experience by employing design principles that centralizes on usability and utility.",
          KR: "사용자 인터페이스 디자인의 목표는 사용성과 유용성에 중점을 둔 디자인 원칙을 적용하여 사용자 경험을 극대화하는 것입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Usability Testing",
          KR: "사용성 테스트",
        },
        span_content: {
          EN: "A beta run ensures that your application runs smoothly after it has been completed. We conduct extensive user testing and UX reviews.",
          KR: "베타 실행은 애플리케이션이 완료된 후 원활하게 실행되도록 합니다. 우리는 광범위한 사용자 테스트 및 UX 검토를 수행합니다.",
        },
      },
    ],
  },

  ReactNativeAppDevelopment: {
    title: {
      EN: "Why Are We The Top Rated React Native App Development Company?",
      KR: "왜 우리가 최고의 React 네이티브 앱 개발 회사일까요?",
    },
    description: {
      EN: "To achieve spectacular success, we combine the power of a human-centered design experience with the powerful features of the React Native platform.",
      KR: "놀라운 성공을 달성하기 위해 우리는 인간 중심 디자인 경험의 힘과 React Native 플랫폼의 강력한 기능을 결합합니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Unbeatable Experience",
          KR: "탁월한 경험",
        },
        span_content: {
          EN: "We have over ten years of hands-on experience developing next-generation mobile apps for hundreds of global clients.",
          KR: "우리는 수백 명의 글로벌 고객을 위한 차세대 모바일 앱을 개발한 10년 이상의 실무 경험을 보유하고 있습니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Consistent Delivery",
          KR: "일관된 배송",
        },
        span_content: {
          EN: "In the last 10 years, we have delivered more than 10 mobile apps, digital portals, IoT platforms, and business solutions.",
          KR: "지난 10년 동안 우리는 10개 이상의 모바일 앱, 디지털 포털, IoT 플랫폼 및 비즈니스 솔루션을 제공했습니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "The Power Of Team",
          KR: "우리만의 강점",
        },
        span_content: {
          EN: "Our true strength is our incredible team of 60+ professionals dedicated to delivering unstoppable success.",
          KR: "우리의 진정한 강점은 막을 수 없는 성공을 제공하기 위해 헌신하는 60명 이상의 전문가로 구성된 놀라운 팀입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Creative Powerhouse",
          KR: "창의적인 팀",
        },
        span_content: {
          EN: "Leverage the strength of diversity: our flamboyant designers are based in South Korea and India.",
          KR: "다양성의 강점 활용: 우리의 화려한 디자이너는 한국과 인도에 기반을 두고 있습니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Trusted By Brands",
          KR: "신뢰할 수 있는 브랜드",
        },
        span_content: {
          EN: "Some of the world's largest brands, including Alhokiar, Shopclues, V Mart, and others, trust and believe in us.",
          KR: "Alhokiar , Shopclues , V Mart 등을 포함한 세계 최대 브랜드 중 일부는 우리를 신뢰하고 믿습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Business Expertise",
          KR: "비즈니스 전문성",
        },
        span_content: {
          EN: "Leverage our expertise partnering with 10+ startups, small businesses, SMEs, and enterprises to transform your business.",
          KR: "10개 이상의 신생 기업, 중소기업, 중소기업 및 기업과 파트너 관계를 맺고 있는 당사의 전문 지식을 활용하여 비즈니스를 혁신하십시오.",
        },
      },
    ],
  },

  EnterpriseAppDevelopment: {
    title: {
      EN: "CNTech is Transforming Enterprises with Powerful Technology",
      KR: "강력한 기술로 기업을 변화시키는 시엔태크",
    },
    description: {
      EN: "You will reinvent your enterprise IT infrastructure with CNTech by leveraging emerging technologies and building with speed and agility to prepare for the future.",
      KR: "새로운 기술을 활용하고 신속하고 민첩하게 구축하여 미래를 준비함으로써 시엔태크로 엔터프라이즈 IT 인프라를 재창조할 것입니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Digitally-Native Team",
          KR: "디지털 네이티브 팀",
        },
        span_content: {
          EN: "We have a passionate team of 60+ members who are experienced in delivering high-end business solutions across multiple frameworks.",
          KR: "우리는 여러 프레임워크에서 고급 비즈니스 솔루션을 제공한 경험이 있는 60명 이상의 구성원으로 구성된 열정적인 팀을 보유하고 있습니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Flexible Engagement Models",
          KR: "유연한 참여 모델",
        },
        span_content: {
          EN: "Instead of working within unfavourable rigid and strict parameters, we provide our clients with the option of selecting from a variety of engagement and hiring options.",
          KR: "바람직하지 않은 엄격하고 엄격한 매개 변수 내에서 작업하는 대신 고객에게 다양한 참여 및 고용 옵션 중에서 선택할 수 있는 옵션을 제공합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Flexibility In Time Zones",
          KR: "시간대의 유연성",
        },
        span_content: {
          EN: "We are always ready to work in your country's time zone. Our skilled developers in South Korea, CA, and our skilled designers",
          KR: "우리는 항상 귀하의 국가 시간대에서 일할 한국의 숙련된 개발자, CA와 숙련된 디자이너 준비가 되어 있습니다. ",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "On-Time Delivery",
          KR: "정시 지원",
        },
        span_content: {
          EN: "Our designers and developers use cutting-edge technologies and an agile methodology to assist us.",
          KR: "우리의 디자이너와 개발자는 최첨단 기술과 민첩한 방법론을 사용하여 우리를 지원합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Trusted By Brands",
          KR: "신뢰할 수 있는 브랜드",
        },
        span_content: {
          EN: "Some of the world's largest brands, including Alhokiar, Shopclues, V Mart, and others, trust and believe in us.",
          KR: "Alhokiar , Shopclues , V Mart 등을 포함한 세계 최대 브랜드 중 일부는 우리를 신뢰하고 믿습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Unbeatable Experience",
          KR: "탁월한 경험",
        },
        span_content: {
          EN: "With over 10 years of hands-on experience driving digital transformation for global brands.",
          KR: "10년 이상의 실무 경험을 통해 글로벌 브랜드의 디지털 혁신을 주도했습니다.",
        },
      },
    ],
  },
};

const digitalProductSuccess = {
  ProductEngineering: {
    title: {
      EN: "Discover Our Digital Product Success Approach",
      KR: "디지털 제품 성공 접근 방식 알아보기",
    },
    description: {
      EN: "Let's hinder the $1 trillion digital products market and provide powerful, reliable, and scalable products to millions of users.",
      KR: "1조 달러 규모의 디지털 제품 시장을 막고 수백만 명의 사용자에게 강력하고 안정적이며 확장 가능한 제품을 제공합시다.",
    },

    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",

    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Decoding Audience",
          KR: "디코딩 대상",
        },
        span_content: {
          EN: "We will conduct extensive market research to figure out the target audience's problem areas and brainstorm ideas to provide them with a solution they cannot deny.",
          KR: "우리는 광범위한 시장 조사를 수행하여 대상 고객의 문제 영역을 파악하고 아이디어를 Brain Storming하여 거부할 수 없는 솔루션을 제공할 것입니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Prototype & Wireframes",
          KR: "프로토타입 및 와이어프레임",
        },
        span_content: {
          EN: "Our digital transformation firm will create prototypes and wireframes with an emphasis on design and functionality.",
          KR: "우리의 디지털 전환 회사는 디자인과 기능에 중점을 둔 프로토타입과 와이어프레임을 만들 것입니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Design & Development",
          KR: "디자인 & 개발",
        },
        span_content: {
          EN: "We will begin design and development work after several layers of testing the prototypes and examining the responses of the test audience.",
          KR: "우리는 프로토타입을 테스트하고 고객의 반응을 조사하는 여러 레이어를 거친 후 디자인 및 개발 작업을 시작합니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Rigorous Testing",
          KR: "엄격한 테스트",
        },
        span_content: {
          EN: "Our intense focus on rigorous testing of the final product guarantees that all loopholes and bugs are addressed before Go-Live.",
          KR: "최종 제품에 대한 엄격한 테스트에 집중하기 때문에 모든 허점과 버그가 배포 전에 해결됩니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Go Live & Publishing",
          KR: "라이브 및 퍼블리싱 시작",
        },
        span_content: {
          EN: "Our team will assist you in going live and publishing your applications on major app stores such as Google Play and Apple's App Store.",
          KR: "우리 팀은 Google Play 및 Apple의 App Store와 같은 주요 앱 스토어에 애플리케이션을 게시하고 게시하는 데 도움을 드릴 것입니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Maintenance & Support",
          KR: "유지보수 및 지원",
        },
        span_content: {
          EN: "We will keep monitoring the digital product's performance as well as its usage, user behavior, and user experiences.",
          KR: "우리는 디지털 제품의 성능과 사용, 사용자 행동 및 사용자 경험을 계속 모니터링할 것입니다.",
        },
      },
    ],
  },

  WebApplicationDevelopment: {
    title: {
      EN: "This Is How We Are Disrupting Web Development: Our Methodology",
      KR: "이것이 우리가 웹 개발을 개발하는 방법입니다: 우리의 방법론",
    },
    description: {
      EN: "Our human-first design principles, together with powerful web technologies and a device-agnostic approach, make sure that our customers are able to leverage the full power and might of the web.",
      KR: "우리의 human-first 설계 원칙은 강력한 웹 기술 및 장치에 구애 받지 않는 접근 방식과 함께 고객이 웹의 모든 기능과 능력을 활용할 수 있도록 합니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Need Analysis",
          KR: "분석 필요",
        },
        span_content: {
          EN: "Our business analysts will decode your audience's needs and understand your company's goals.",
          KR: "우리의 비즈니스 분석가는 청중의 요구를 분석하고 회사의 목표를 이해합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Strategic Research",
          KR: "전략적 연구",
        },
        span_content: {
          EN: "Our team will conduct extensive strategic research to understand your company's web platform needs and requirements.",
          KR: "우리 팀은 귀사의 웹 플랫폼 요구 사항과 요구 사항을 이해하기 위해 광범위한 전략적 연구를 수행합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Web Concept Development",
          KR: "웹 Concept 개발",
        },
        span_content: {
          EN: "We will brainstorm ideas and concepts for web platforms that your company requires to achieve hyper-growth.",
          KR: "귀사가 고성장을 달성하는 데 필요한 웹 플랫폼에 대한 아이디어와 개념을 브레인스토밍합니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "UX Prototyping",
          KR: "UX 프로토타이핑",
        },
        span_content: {
          EN: "We will create UX and UI prototypes of the web platform based on the approved concepts and seek your input.",
          KR: "승인된 개념을 바탕으로 웹 플랫폼의 UX 및 UI 프로토타입을 만들고 여러분의 의견을 구합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Web Development",
          KR: "웹 개발",
        },
        span_content: {
          EN: "After we have finalized the design and UX, we will begin web development using some of the most powerful web technologies.",
          KR: "디자인과 UX가 완성되면 가장 강력한 웹 기술을 사용하여 웹 개발을 시작합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Testing & QA",
          KR: "테스트 및 QA",
        },
        span_content: {
          EN: "We will rigorously test the web platforms with various scenarios and user behavior to identify and eliminate any bugs or anomalies that may cause problems for the user.",
          KR: "다양한 시나리오와 사용자 행동으로 웹 플랫폼을 엄격하게 테스트하여 사용자에게 문제를 일으킬 수 있는 버그나 이상 현상을 식별하고 제거합니다.",
        },
      },
    ],
  },
  InternetOfThings: {
    title: {
      EN: "Behind The Curtains: Our IoT App Development Methodology",
      KR: "우리의 IoT 앱 개발 방법론",
    },
    description: {
      EN: "Get the best balance of quality, cost, and time with superior IoT Services for dealing with your business challenges. With our impressive services, we serve clients from all over the world.",
      KR: "비즈니스 과제를 처리하기 위한 우수한 IoT 서비스로 품질, 비용 및 시간의 최상의 균형을 얻으십시오. 인상적인 서비스로 전 세계의 고객에게 서비스를 제공합니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Research & Analysis",
          KR: "연구 및 분석",
        },
        span_content: {
          EN: "Our IoT experts are well-versed in your business model, reach, and capabilities. We contact you to obtain critical information about user interaction.",
          KR: "당사의 IoT 전문가는 귀하의 비즈니스 모델, 범위 및 기능에 정통합니다. 사용자 상호 작용에 대한 중요한 정보를 얻기 위해 연락합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Design & Prototype",
          KR: "디자인 및 프로토타입",
        },
        span_content: {
          EN: "Following the purpose and vision of the IoT app, our product and design team creates a visual mock-up,also known as a designed prototype.",
          KR: "IoT 앱의 목적과 비전에 따라 당사 제품 및 디자인 팀은 디자인된 프로토타입이라고도 하는 시각적 모형을 만듭니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Strategy & Magnitude",
          KR: "전략 및 규모",
        },
        span_content: {
          EN: "We will create a robust product strategy for the app and then shape the development process to optimize functionality and connectivity.",
          KR: "우리는 앱에 대한 강력한 제품 전략을 만든 다음 기능과 연결성을 최적화하기 위해 개발 프로세스를 형성할 것입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "App Development",
          KR: "앱 개발",
        },
        span_content: {
          EN: "Our IoT engineers will fine-tune the primary product features, validate the functionalities, and carry out progressive development.",
          KR: "당사의 IoT 엔지니어는 주요 제품 기능을 미세 조정하고 기능을 검증하며 점진적인 개발을 수행합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Testing & Debugging",
          KR: "테스트 및 디버깅",
        },
        span_content: {
          EN: "Our IoT experts rigorously test your app for usability, reliability, data integrity, security, and performance.",
          KR: "당사의 IoT 전문가는 사용성, 안정성, 데이터 무결성, 보안 및 성능에 대해 앱을 엄격하게 테스트합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Delivery",
          KR: "배달",
        },
        span_content: {
          EN: "We present our product for your approval once the app has passed the testing phase. Any mandated ramifications, such as device control.",
          KR: "앱이 테스트 단계를 통과하면 승인을 위해 제품을 제시합니다. 장치 제어와 같은 모든 필수 결과.",
        },
      },
    ],
  },
  MobileAppDevelopment: {
    title: {
      EN: "Our Methodology For Developing Mobile Apps",
      KR: "모바일 앱 개발을 위한 방법론",
    },
    description: {
      EN: "We understand that providing a consistent user experience is key to the success of any mobile app. This is why our mobile app development company has taken a human-centered approach.",
      KR: "우리는 일관된 사용자 경험을 제공하는 것이 모든 모바일 앱의 성공의 핵심이라는 것을 이해합니다. 이것이 우리 모바일 앱 개발 회사가 인간 중심적인 접근 방식을 취한 이유입니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Validation Of Ideas",
          KR: "아이디어 검증",
        },
        span_content: {
          EN: "We conduct a thorough validation of the mobile app concept based on behavioral patterns and target audience.",
          KR: "우리는 행동 패턴과 대상 고객을 기반으로 모바일 앱 개념의 철저한 검증을 수행합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "App Wireframing",
          KR: "앱 와이어프레임",
        },
        span_content: {
          EN: "Based on the business requirements, we envision the app idea through app wireframing and present the exact user journey of the target users.",
          KR: "비즈니스 요구 사항을 기반으로 앱 와이어프레임을 통해 앱 아이디어를 구상하고 대상 사용자의 정확한 사용자 여정을 제시합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "App prototype",
          KR: "앱 프로토타입",
        },
        span_content: {
          EN: "We will create a mobile app prototype using advanced tools and software based on the wireframe and feedback.",
          KR: "와이어프레임과 피드백을 기반으로 고급 도구와 소프트웨어를 사용하여 모바일 앱 프로토타입을 만들 것입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Mobile App Development",
          KR: "모바일 앱 개발",
        },
        span_content: {
          EN: "Once the prototype is confirmed, we build the app using human-centered design and best practices from the Android and iOS platforms.",
          KR: "프로토타입이 확인되면 Android 및 iOS 플랫폼의 인간 중심 디자인과 모범 사례를 사용하여 앱을 빌드합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Testing & QA",
          KR: "테스트 및 QA",
        },
        span_content: {
          EN: "We rigorously test the mobile app for responsiveness, stability, and scalability to guarantee a smooth user experience.",
          KR: "원활한 사용자 경험을 보장하기 위해 모바일 앱의 반응성, 안정성 및 확장성을 엄격하게 테스트합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Go-Live & Maintenance",
          KR: "라이브 및 유지 관리",
        },
        span_content: {
          EN: "We will assist you in maintaining and optimizing the mobile app once it is live in the app stores.",
          KR: "앱 스토어에 출시되면 모바일 앱을 유지 관리하고 최적화하는 데 도움을 드릴 것입니다.",
        },
      },
    ],
  },
  CloudEngineering: {
    title: {
      EN: "Our Methodology For Developing Mobile Apps",
      KR: "모바일 앱 개발을 위한 방법론",
    },
    description: {
      EN: "We understand that providing a consistent user experience is key to the success of any mobile app.This is why our mobile app development company has taken a human-centered approach.",
      KR: "우리는 일관된 사용자 경험을 제공하는 것이 모든 모바일 앱의 성공의 핵심이라는 것을 이해합니다. 이것이 우리 모바일 앱 개발 회사가 인간 중심적인 접근 방식을 취한 이유입니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Validation Of Ideas",
          KR: "아이디어 검증",
        },
        span_content: {
          EN: "We conduct a thorough validation of the mobile app concept based on behavioral patterns and target audience.",
          KR: "우리는 행동 패턴과 대상 고객을 기반으로 모바일 앱 개념의 철저한 검증을 수행합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "App Wireframing",
          KR: "앱 와이어프레임",
        },
        span_content: {
          EN: "Based on the business requirements, we envision the app idea through app wireframing and present the exact user journey of the target users.",
          KR: "비즈니스 요구 사항을 기반으로 앱 와이어프레임을 통해 앱 아이디어를 구상하고 대상 사용자의 정확한 사용자 여정을 제시합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "App prototype",
          KR: "앱 프로토타입",
        },
        span_content: {
          EN: "We will create a mobile app prototype using advanced tools and software based on the wireframe and feedback.",
          KR: "와이어프레임과 피드백을 기반으로 고급 도구와 소프트웨어를 사용하여 모바일 앱 프로토타입을 만들 것입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Mobile App Development",
          KR: "모바일 앱 개발",
        },
        span_content: {
          EN: "Once the prototype is confirmed, we build the app using human-centered design and best practices from the Android and iOS platforms.",
          KR: "프로토타입이 확인되면 Android 및 iOS 플랫폼의 인간 중심 디자인과 모범 사례를 사용하여 앱을 빌드합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Testing & QA",
          KR: "테스트 및 QA",
        },
        span_content: {
          EN: "We rigorously test the mobile app for responsiveness, stability, and scalability to guarantee a smooth user experience.",
          KR: "원활한 사용자 경험을 보장하기 위해 모바일 앱의 반응성, 안정성 및 확장성을 엄격하게 테스트합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Go-Live & Maintenance",
          KR: "라이브 및 유지 관리",
        },
        span_content: {
          EN: "We will assist you in maintaining and optimizing the mobile app once it is live in the app stores.",
          KR: "앱 스토어에 출시되면 모바일 앱을 유지 관리하고 최적화하는 데 도움을 드릴 것입니다.",
        },
      },
    ],
  },

  eCommerceDevelopment: {
    title: {
      EN: "Last But Not Least! We, Like Every Other Vendor, Take Great Care to Maintain These Characteristics.",
      KR: "마지막으로 우리는 다른 모든 공급업체와 마찬가지로 이러한 특성을 유지하기 위해 세심한 주의를 기울입니다.",
    },
    description: {
      EN: "We make every effort to keep all of these characteristics in mind as we design our project.",
      KR: "우리는 프로젝트를 설계할 때 이러한 모든 특성을 염두에 두고 모든 노력을 기울입니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Automatic Location Picker",
          KR: "자동 위치 선택기",
        },
        span_content: {
          EN: "Improve your user's experience and expand your user base by automatically detecting their location when they visit your website.",
          KR: "사용자가 웹사이트를 방문할 때 위치를 자동으로 감지하여 사용자 경험을 개선하고 사용자 기반을 확장합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "SEO Optimized",
          KR: "SEO 최적화",
        },
        span_content: {
          EN: "To be successful, your eCommerce store must be SEO optimized. Why? So, it will rank higher in the search engine results.",
          KR: "성공하려면 전자 상거래 상점이 SEO에 최적화되어야 합니다. 왜냐하면 검색 엔진 결과에서 더 높은 순위를 차지하기 때문입니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Smart Search",
          KR: "스마트 검색",
        },
        span_content: {
          EN: "Smart search, we believe, is the key to creating a better shopping experience. An experience is provided by smart search.",
          KR: "우리는 스마트 검색이 더 나은 쇼핑 경험을 만드는 열쇠라고 믿습니다. 스마트 검색을 통해 경험을 제공합니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Effective Calls to Action",
          KR: "효과적인 클릭 유도",
        },
        span_content: {
          EN: "The call to action essentially contains the instructions that visitors require in order to succeed on your site.",
          KR: "클릭 유도문안에는 방문자가 사이트에서 성공하기 위해 필요한 지침이 기본적으로 포함되어 있습니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Secure Payment Network",
          KR: "안전한 결제 네트워크",
        },
        span_content: {
          EN: "Your eCommerce site and your merchant bank are linked by a payment network. It authorizes credit and debit card payments from your customers.",
          KR: "전자상거래 사이트와 판매자 은행은 결제 네트워크로 연결되어 있습니다. 고객의 신용 카드 및 직불 카드 결제를 승인합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Enhance Your Experience With 3D",
          KR: "3D로 경험 향상",
        },
        span_content: {
          EN: "3D is more than a gimmick. In the case of e-commerce, 3D can actually improve your shoppingexperience by displaying virtual items.",
          KR: "3D는 특수 효과 그 이상입니다. 전자 상거래의 경우 3D는 가상 항목을 표시하여 실제로 쇼핑 경험을 향상시킬 수 있습니다.",
        },
      },
    ],
  },

  UI_UX_Design: {
    title: {
      EN: "Our Design Strategy",
      KR: "우리의 디자인 전략",
    },
    description: {
      EN: "We've worked on custom user interfaces for a variety of platforms, including desktops, phones,tablets, and smart displays.",
      KR: "우리는 데스크톱, 휴대폰, 태블릿, 스마트 디스플레이를 포함한 다양한 플랫폼을 위한 맞춤형 사용자 인터페이스를 작업했습니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "User Focused Design",
          KR: "사용자 중심의 디자인",
        },
        span_content: {
          EN: "We define user goals to help us understand who our audience is and why they would engage with the experience we are providing.",
          KR: "우리는 청중이 누구이며 그들이 우리가 제공하는 경험에 참여하는 이유를 이해하는 데 도움이 되도록 사용자 목표를 정의합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Clarity & Simplicity",
          KR: "명확성 및 단순성",
        },
        span_content: {
          EN: "The most effective user interfaces are almost unnoticeable to the user. We avoid unnecessary elements and stick to that language at all times.",
          KR: "가장 효과적인 사용자 인터페이스는 사용자에게 거의 눈에 띄지 않습니다. 우리는 불필요한 요소를 피하고 항상 그 언어를 고수합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Consistent Design",
          KR: "일관된 디자인",
        },
        span_content: {
          EN: "Design consistency makes users feel at ease and happy, and it encourages them to stay engaged,increasing usability.",
          KR: "디자인 일관성은 사용자를 편안하고 행복하게 만들고 참여를 유도하여 사용성을 높입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Visual Hierarchy",
          KR: "시각적 계층",
        },
        span_content: {
          EN: "Design consistency makes users feel at ease and happy, and it encourages them to stay engaged,increasing usability.",
          KR: "우리는 UI 구성 요소를 구성하고 두뇌가 개체를 구별할 수 있도록 콘텐츠를 제공하는 것을 목표로 합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Feedback",
          KR: "피드백",
        },
        span_content: {
          EN: "We ensure that your UI can inform users on actions or changes by strategically placing them based on constant client and user feedback.",
          KR: "지속적인 클라이언트 및 사용자 피드백을 기반으로 전략적으로 배치하여 UI가 사용자에게 작업이나 변경 사항을 알릴 수 있도록 합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Usability",
          KR: "사용성",
        },
        span_content: {
          EN: "Our UI/UX designers completely immerse themselves in the minds of users and follow their thought processes when using various.",
          KR: "우리의 UI/UX 디자이너는 사용자의 마음에 완전히 몰입하여 다양한 것을 사용할 때 그들의 사고 과정을 따릅니다.",
        },
      },
    ],
  },
  ReactNativeAppDevelopment: {
    title: {
      EN: "Advantages Of Using React Native for Mobile App Development",
      KR: "모바일 앱 개발에 React Native 사용의 장점",
    },
    description: {
      EN: "React Native framework has quickly become the most popular development platform for mobile app developers all over the globe.",
      KR: "React Native 프레임워크는 전 세계 모바일 앱 개발자에게 가장 인기 있는 개발 플랫폼이 되었습니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Cross Platform",
          KR: "크로스 플랫폼",
        },
        span_content: {
          EN: "One single code base created in React Native can be used for both the Android and iOS platforms, saving time and resources.",
          KR: "React Native에서 생성된 하나의 단일 코드 베이스는 Android 및 iOS 플랫폼 모두에서 사용할 수 있어 시간과 리소스를 절약할 수 있습니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Trusted By Billions of Users",
          KR: "수십억 명의 사용자가 신뢰합니다.",
        },
        span_content: {
          EN: "React Native was used for the mobile apps for Facebook and Instagram, which are used by billions of people every day.",
          KR: "React Native는 매일 수십억 명이 사용하는 Facebook 및 Instagram용 모바일 앱에 사용되었습니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Swift Deployment",
          KR: "신속한 배포",
        },
        span_content: {
          EN: "Because most of the code can be reused and there is a seamless modular structure, React Native mobile apps can be quickly deployed and made go-live.",
          KR: "대부분의 코드를 재사용할 수 있고 원활한 모듈식 구조가 있기 때문에 React Native 모바일 앱을 빠르게 배포하고 가동할 수 있습니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "3rd Party Integrations",
          KR: "3rd Party 통합",
        },
        span_content: {
          EN: "We have a large pool of 3rd party APIs and tools that can be seamlessly integrated because React Native uses JavaScript.",
          KR: "React Native는 JavaScript를 사용하기 때문에 원활하게 통합할 수 있는 대규모 타사 API 및 도구 풀이 있습니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Modular Structure",
          KR: "모듈식 구조",
        },
        span_content: {
          EN: "React Native's modular structure is a real game-changer: the same modules can be used seamlessly across differen APIs for quick results.",
          KR: "React Native의 모듈식 구조는 진정한 게임 체인저입니다. 동일한 모듈을 다른 API에서 원활하게 사용하여 빠른 결과를 얻을 수 있습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Powerful Performance",
          KR: "강력한 성능",
        },
        span_content: {
          EN: "Unlike other platforms that rely heavily on CPU (Central Processing Unit), React Native makes use of GPU (Graphics Processing Unit).",
          KR: "CPU(중앙 처리 장치)에 크게 의존하는 다른 플랫폼과 달리 React Native는 GPU(그래픽 처리 장치)를 사용합니다.",
        },
      },
    ],
  },
  EnterpriseAppDevelopment: {
    title: {
      EN: "Enterprise App Development Methodology: Our secret Of Long-Term Success",
      KR: "엔터프라이즈 앱 개발 방법론: 장기적인 성공의 비결",
    },
    description: {
      EN: "To ensure top-tier results for our enterprise clients, we unleash a perfect blend of well-crafted system architecture and code quality.",
      KR: "기업 고객을 위한 최고 수준의 결과를 보장하기 위해 잘 만들어진 시스템 아키텍처와 코드 품질의 완벽한 조화를 구현합니다.",
    },
    link_title: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    link_to: "/contact",
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Research & Analysis",
          KR: "연구 및 분석",
        },
        span_content: {
          EN: "We begin by analyzing and researching vital business statistics such as employee interaction and the number of onshore/offshore workforce.",
          KR: "우리는 직원 상호 작용 및 국내/해외 인력 수와 같은 중요한 비즈니스 통계를 분석하고 조사하는 것으로 시작합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Documentation & Prototype",
          KR: "문서 및 프로토타입",
        },
        span_content: {
          EN: "Documents are prepared in accordance with the specifications. This helps to define the overall system architecture and technology stack.",
          KR: "문서는 사양에 따라 준비됩니다. 이는 전체 시스템 아키텍처와 기술 스택을 정의하는 데 도움이 됩니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "App Development",
          KR: "앱 개발",
        },
        span_content: {
          EN: "Using our entire arsenal, our expert enterprise app developers consult you on the best way to begin building the entire system by writing code.",
          KR: "전체 무기고를 사용하여 전문 엔터프라이즈 앱 개발자가 코드를 작성하여 전체 시스템 구축을 시작하는 가장 좋은 방법에 대해 상담합니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Testing & Fixes",
          KR: "테스트 및 수정",
        },
        span_content: {
          EN: "Every app is a success once it has dealt with all loopholes. We share the app with you to evaluate the quality in order to ensure fruitful workability.",
          KR: "모든 앱이 모든 허점을 처리하면 성공합니다. 유익한 작업성을 보장하기 위해 품질을 평가하기 위해 앱을 공유합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Launch & Go-Live",
          KR: "출시 및 라이브",
        },
        span_content: {
          EN: "We integrate the application into your work environment once the final software has been shared and tested for deployment issues.",
          KR: "최종 소프트웨어가 공유되고 배포 문제에 대해 테스트되면 애플리케이션을 작업 환경에 통합합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Maintenance & Support",
          KR: "유지보수 및 지원",
        },
        span_content: {
          EN: "We continue to provide app maintenance and platform support under the service level agreement to ensure that the system continues to function properly.",
          KR: "시스템이 계속해서 제대로 작동할 수 있도록 서비스 수준 계약에 따라 앱 유지 관리 및 플랫폼 지원을 계속 제공합니다.",
        },
      },
    ],
  },
};

const productOfferings = {
  ProductEngineering: {
    title: {
      EN: "Digital Product Engineering Offerings: Disruptive, Robust & Scalable",
      KR: "디지털 제품 엔지니어링 제공: 파괴적이고 강력하며 확장 가능",
    },
    description: {
      EN: "Our Digital Product Engineering solutions are customized to solve your business issues and generate more revenue and profits.",
      KR: "당사의 디지털 제품 엔지니어링 솔루션은 비즈니스 문제를 해결하고 더 많은 수익과 수익을 창출하도록 맞춤화 되었습니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Product Engineering",
          KR: "제품 엔지니어링",
        },
        span_content: {
          EN: "Using our highly advanced and rigorous process, we will nurture and polish your idea before transforming it into a full-fledged digital product.",
          KR: "고도로 발전되고 엄격한 프로세스를 사용하여 귀하의 아이디어를 본격적인 디지털 제품으로 변환하기 전에 육성하고 연마합니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Product Development",
          KR: "제품 개발",
        },
        span_content: {
          EN: "We will lead you through the entire product journey, ensuring that you introduce the best version of your digital product.",
          KR: "최고의 디지털 제품 버전을 소개할 수 있도록 전체 제품 시연을 안내해 드립니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Innovation Labs",
          KR: "이노베이션 랩",
        },
        span_content: {
          EN: "Our digital engineering firm has established cutting-edge innovation labs in which we design, develop, and test digital products.",
          KR: "당사의 디지털 엔지니어링 회사는 디지털 제품을 설계, 개발 및 테스트하는 최첨단 혁신 연구소를 설립했습니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Rapid Prototyping",
          KR: "신속한 프로토 타입",
        },
        span_content: {
          EN: "Our digital engineering firm has established cutting-edge innovation labs in which we design, develop,and test digital products.",
          KR: "당사의 디지털 엔지니어링 서비스는 신속한 프로토타이핑을 사용하여 디지털 제품의 가장 정밀한 버전만 작업하고 최적화하도록 합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "UX Design Consulting",
          KR: "UX 디자인 컨설팅",
        },
        span_content: {
          EN: "We provide practical and goal-driven UX design and usability consulting for a few digital products for the next disruptive digital innovation.",
          KR: "차세대 디지털 혁신을 위한 몇 가지 디지털 제품에 대한 실용적이고 목표 지향적인 UX 디자인 및 사용성 컨설팅을 제공합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Independent Validation",
          KR: "독립적인 검증",
        },
        span_content: {
          EN: "We ensure that your idea is polished, refined, and ready to go into the market by conducting an independent and neutral observation.",
          KR: "우리는 독립적이고 중립적인 관찰을 수행하여 귀하의 아이디어가 세련되고 세련되며 시장에 출시될 준비가 되었는지 확인합니다.",
        },
      },
    ],
  },

  WebApplicationDevelopment: {
    title: {
      EN: "Web Development Solutions for Triggering Hyper Growth",
      KR: "초고속 성장을 위한 웹 개발 솔루션",
    },
    description: {
      EN: "We create and launch custom web platforms that reach millions of users and enable our clients to achieve hyper-growth.",
      KR: "우리는 수백만 명의 사용자에게 도달하고 고객이 초고속 성장을 달성할 수 있도록 하는 맞춤형 웹 플랫폼을 생성 및 출시합니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "iOS App Strategy",
          KR: "iOS 앱 전략",
        },
        span_content: {
          EN: "We will brainstorm result-driven iOS app strategies that are aligned with your business goals and will enable you to dominate your market.",
          KR: "우리는 귀하의 비즈니스 목표에 부합하고 귀하가 시장을 지배할 수 있도록 하는 결과 중심의 iOS 앱 전략을 브레인스토밍할 것입니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Digital Business Consultancy",
          KR: "디지털 비즈니스 컨설팅",
        },
        span_content: {
          EN: "We will consult with you on your digital business, assessing the exact solutions you require for leveraging the power of web platforms and calculating costs.",
          KR: "웹 플랫폼의 힘을 활용하고 비용을 계산하는 데 필요한 정확한 솔루션을 평가하여 디지털 비즈니스에 대해 컨설팅합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Web App Development",
          KR: "웹 앱 개발",
        },
        span_content: {
          EN: "Full-fledged web app development for launching next-generation web apps that can perform and deliver on any platform for global users.",
          KR: "전 세계 사용자를 위해 모든 플랫폼에서 수행 및 제공할 수 있는 차세대 웹 앱 출시를 위한 본격적인 웹 앱 개발.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Web Platform Development",
          KR: "웹 플랫폼 개발",
        },
        span_content: {
          EN: "Full-fledged web development services for creating powerful, scalable, and creative web platforms that can reach millions of new users worldwide.",
          KR: "전 세계 수백만 명의 신규 사용자에게 다가갈 수 있는 강력하고 확장 가능하며 창의적인 웹 플랫폼을 만들기 위한 본격적인 웹 개발 서비스입니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "UX/UI Consultancy",
          KR: "UX/UI 컨설팅",
        },
        span_content: {
          EN: "The real game changer is our human-centric web design principles and UX/UI approach: Learn how web design can help you break through new barriers.",
          KR: "우리의 인간 중심 웹 디자인 원칙과 UX/UI 접근 방식이 진정한 판도를 바꿉니다. 웹 디자인이 새로운 장벽을 극복하는 데 어떻게 도움이 되는지 알아보십시오.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Web Engineering Solutions",
          KR: "웹 엔지니어링 솔루션",
        },
        span_content: {
          EN: "We can quickly optimize and improve your existing web applications and web platforms with web engineering solutions, increasing your ROI.",
          KR: "웹 엔지니어링 솔루션으로 기존 웹 애플리케이션 및 웹 플랫폼을 신속하게 최적화하고 개선하여 ROI를 높일 수 있습니다.",
        },
      },
    ],
  },
  InternetOfThings: {
    title: {
      EN: "Smart & Customized IoT Solutions For Maximum ROI",
      KR: "ROI 극대화를 위한 스마트하고 맞춤형 IoT 솔루션",
    },
    description: {
      EN: "We have the expertise and understanding required to create smart and robust IoT solutions for a variety of niches as a leading IoT development company. With each product.",
      KR: "우리는 선도적인 IoT 개발 회사로서 다양한 틈새 시장을 위한 스마트하고 강력한 IoT 솔루션을 만드는 데 각 제품과 함께 필요한 전문 지식과 이해를 갖추고 있습니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "IoT App Development",
          KR: "IoT 앱 개발",
        },
        span_content: {
          EN: "Create smart, innovative, feature-rich, and scalable IoT mobile apps that work seamlessly with remote devices.",
          KR: "원격 장치와 원활하게 작동하는 스마트하고 혁신적이며 기능이 풍부하고 확장 가능한 IoT 모바일 앱을 만드십시오.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Equipment Tracking Apps",
          KR: "장비 추적 앱",
        },
        span_content: {
          EN: "We provide some of the best development services for equipment tracking apps, which help monitor various parameters in real-time.",
          KR: "다양한 매개변수를 실시간으로 모니터링할 수 있는 장비 추적 앱을 위한 최고의 개발 서비스를 제공합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "IoT Integration",
          KR: "사물인터넷 통합",
        },
        span_content: {
          EN: "Partner with us to hire IoT developers who are skilled at creating IoT solutions that integrate seamlessly with Cloud, Big Data, and others.",
          KR: "클라우드, 빅 데이터 및 기타와 원활하게 통합되는 IoT 솔루션을 만드는 데 능숙한 IoT 개발자를 고용하려면 당사와 협력하십시오.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Smart Home Solutions",
          KR: "스마트 홈 솔루션",
        },
        span_content: {
          EN: "We specialize in creating innovative, sensor-based smart home solutions that provide a great user experience.",
          KR: "우리는 뛰어난 사용자 경험을 제공하는 혁신적인 센서 기반 스마트 홈 솔루션을 만드는 것을 전문으로 합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "IoT Wearables Connectivity",
          KR: "IoT 웨어러블 연결",
        },
        span_content: {
          EN: "Using cross-platform app development, our dedicated IoT developers can create feature-rich IoT wearable apps for Android, IoT, or both.",
          KR: "플랫폼 간 앱 개발을 사용하여 당사의 전담 IoT 개발자는 Android, IoT 또는 둘 모두를 위한 기능이 풍부한 IoT 웨어러블 앱을 만들 수 있습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "IoT Cloud Platform",
          KR: "IoT 클라우드 플랫폼",
        },
        span_content: {
          EN: "Easily scale your business and manage, store, and retrieve IoT data by leveraging end-to-end IoT cloud platforms such as Azure, AWS, and others.",
          KR: "Azure, AWS 등과 같은 종단 간 IoT 클라우드 플랫폼을 활용하여 비즈니스를 쉽게 확장하고 IoT 데이터를 관리, 저장 및 검색합니다.",
        },
      },
    ],
  },
  MobileAppDevelopment: {
    title: {
      EN: "Our Cutting Edge Solutions For Mobile App Development",
      KR: "모바일 앱 개발을 위한 최첨단 솔루션",
    },
    description: {
      EN: "Our mobile app development solutions are tailored to your specific business needs and requirements.",
      KR: "당사의 모바일 앱 개발 솔루션은 귀하의 특정 비즈니스 요구 사항과 요구 사항에 맞게 조정됩니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "iOS App Development",
          KR: "iOS 앱 개발",
        },
        span_content: {
          EN: "For developing and launching iOS mobile apps for Apple's App Store, we are the most preferred mobile app development company.",
          KR: "Apple의 App Store용 iOS 모바일 앱 개발 및 출시를 위해 가장 선호하는 모바일 앱 개발 회사입니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Android App Development",
          KR: "안드로이드 앱 개발",
        },
        span_content: {
          EN: "For designing and developing Android apps for Google's Playstore, we are the world's leading mobile app development company.",
          KR: "Playstore 용 Android 앱 설계 및 개발을 위해 우리는 세계 최고의 모바일 앱 개발 회사입니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "React Native App Development",
          KR: "React 네이티브 앱 개발",
        },
        span_content: {
          EN: "Using the stunning features of the React Native platform, we will conceptualize and launch platform-independent mobile apps.",
          KR: "React Native 플랫폼의 놀라운 기능을 사용하여 플랫폼 독립적인 모바일 앱을 개념화하고 출시합니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Flutter App Development",
          KR: "Flutter 앱 개발",
        },
        span_content: {
          EN: "We can create fast, scalable, and cross-platform mobile apps by leveraging the fascinating features of the flutter platform.",
          KR: "Flutter 플랫폼의 매력적인 기능을 활용하여 빠르고 확장 가능한 크로스 플랫폼 모바일 앱을 만들 수 있습니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Xamarin App Development",
          KR: "Xamarin 앱 개발",
        },
        span_content: {
          EN: "We were among the first to use the Xamarin platform for developing platform-independent mobile apps.",
          KR: "우리는 플랫폼 독립적인 모바일 앱을 개발하기 위해 Xamarin 플랫폼을 사용한 최초의 기업 중 하나였습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Mobile App Consulting",
          KR: "모바일 앱 컨설팅",
        },
        span_content: {
          EN: "Our decades of experience delivering 10+ mobile apps will empower you create a scalable and dependable business model.",
          KR: "10개 이상의 모바일 앱을 제공한 수십 년의 경험을 통해 확장 가능하고 신뢰할 수 있는 비즈니스 모델을 만들 수 있습니다.",
        },
      },
    ],
  },
  CloudEngineering: {
    title: {
      EN: "Our Cutting Edge Solutions For Mobile App Development",
      KR: "모바일 앱 개발을 위한 최첨단 솔루션",
    },
    description: {
      EN: "Our mobile app development solutions are tailored to your specific business needs and requirements.",
      KR: "당사의 모바일 앱 개발 솔루션은 귀하의 특정 비즈니스 요구 사항과 요구 사항에 맞게 조정됩니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "iOS App Development",
          KR: "iOS 앱 개발",
        },
        span_content: {
          EN: "For developing and launching iOS mobile apps for Apple's App Store, we are the most preferred mobile app development company.",
          KR: "Apple의 App Store용 iOS 모바일 앱 개발 및 출시를 위해 가장 선호하는 모바일 앱 개발 회사입니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Android App Development",
          KR: "안드로이드 앱 개발",
        },
        span_content: {
          EN: "For designing and developing Android apps for Google's Playstore, we are the world's leading mobile app development company.",
          KR: "Playstore 용 Android 앱 설계 및 개발을 위해 우리는 세계 최고의 모바일 앱 개발 회사입니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "React Native App Development",
          KR: "React 네이티브 앱 개발",
        },
        span_content: {
          EN: "Using the stunning features of the React Native platform, we will conceptualize and launch platform-independent mobile apps.",
          KR: "React Native 플랫폼의 놀라운 기능을 사용하여 플랫폼 독립적인 모바일 앱을 개념화하고 출시합니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Flutter App Development",
          KR: "Flutter 앱 개발",
        },
        span_content: {
          EN: "We can create fast, scalable, and cross-platform mobile apps by leveraging the fascinating features of the flutter platform.",
          KR: "Flutter 플랫폼의 매력적인 기능을 활용하여 빠르고 확장 가능한 크로스 플랫폼 모바일 앱을 만들 수 있습니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Xamarin App Development",
          KR: "Xamarin 앱 개발",
        },
        span_content: {
          EN: "We were among the first to use the Xamarin platform for developing platform-independent mobile apps.",
          KR: "우리는 플랫폼 독립적인 모바일 앱을 개발하기 위해 Xamarin 플랫폼을 사용한 최초의 기업 중 하나였습니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Mobile App Consulting",
          KR: "모바일 앱 컨설팅",
        },
        span_content: {
          EN: "Our decades of experience delivering 10+ mobile apps will empower you create a scalable and dependable business model.",
          KR: "10개 이상의 모바일 앱을 제공한 수십 년의 경험을 통해 확장 가능하고 신뢰할 수 있는 비즈니스 모델을 만들 수 있습니다.",
        },
      },
    ],
  },
  eCommerceDevelopment: {
    title: {
      EN: "We Offer Custom Ecommerce Website Development for All major Platforms",
      KR: "우리는 모든 주요 플랫폼을 위한 맞춤형 전자상거래 웹사이트 개발을 제공합니다.",
    },
    description: {
      EN: "CNTech offers a wide range of eCommerce website development services for a variety of prominent platforms that help businesses transform.",
      KR: "지오텍 는 비즈니스 혁신을 지원하는 다양한 주요 플랫폼을 위한 광범위한 전자 상거래 웹사이트 개발 서비스를 제공합니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Laravel",
          KR: "라라벨",
        },
        span_content: {
          EN: "CNTech is an eCommerce development company that is passionate about creating an online platform that will assist our clients in growing their businesses.",
          KR: "CNTech는 고객의 비즈니스 성장을 지원하는 온라인 플랫폼을 만드는 데 열정적인 전자 상거래 개발 회사입니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "WooCommerce",
          KR: "우커머스",
        },
        span_content: {
          EN: "Using the power of WooCommerce, the CNTech team will bring your eCommerce website to life. With skilled developers,",
          KR: "WooCommerce의 힘을 사용하여 CNTech 팀은 전자 상거래 웹사이트에 생명을 불어넣을 것입니다. 숙련된 개발자와 함께,",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Magento 2.0",
          KR: "마젠토 2.0",
        },
        span_content: {
          EN: "Migrate your existing store to Magento 2.0 to improve your eCommerce business's conversions, sales,and returns. With an experienced team.",
          KR: "기존 상점을 Magento 2.0으로 마이그레이션하여 전자 상거래 비즈니스의 전환, 판매, 그리고 반환합니다. 경험 많은 팀과 함께.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "OpenCart",
          KR: "오픈카트",
        },
        span_content: {
          EN: "OpenCart is a powerful eCommerce solution that allows you to quickly build a robust online store. It aids in the smooth management of the entire process.",
          KR: "OpenCart는 강력한 온라인 상점을 빠르게 구축할 수 있는 강력한 전자 상거래 솔루션입니다. 도움이 된다 전체 프로세스의 원활한 관리.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "NopCommerce",
          KR: "노프커머스",
        },
        span_content: {
          EN: "Do you require NopCommerce development services? CNTech India is your one-stop destination for multidimensional business growth and full-cycle support.",
          KR: "NopCommerce 개발 서비스가 필요하십니까? CNTech India는 다음을 위한 원스톱 목적지입니다. 다차원적 비즈니스 성장 및 전체 주기 지원.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Brightpearl Integration",
          KR: "브라이트펄 통합",
        },
        span_content: {
          EN: "Our Magento eCommerce Integration Services are intended to assist you in transforming your eCommerce store into a thriving multichannel business.",
          KR: "Magento 전자 상거래 통합 서비스는 귀하의 전자 상거래를 번창하는 다채널 비즈니스로 전환합니다.",
        },
      },
    ],
  },

  ReactNativeAppDevelopment: {
    title: {
      EN: "React Native App Development Services for Accelerated Growth",
      KR: "성장 가속화를 위한 React 네이티브 앱 개발 서비스",
    },
    description: {
      EN: "Our React Native app development company provides customized, tailor-made services to meet your specific business needs.",
      KR: "React Native 앱 개발 회사는 특정 비즈니스 요구 사항을 충족하기 위해 맞춤형 맞춤형 서비스를 제공합니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "React Native Consultation",
          KR: "리액트 네이티브 상담",
        },
        span_content: {
          EN: "We will advise you on the React Native platform and assist you in selecting the best platform, functionalities, and structure for your next mobile app project.",
          KR: "React Native 플랫폼에 대해 조언하고 다음 모바일 앱 프로젝트에 가장 적합한 플랫폼, 기능 및 구조를 선택하는 데 도움을 드립니다.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "React Native App Development",
          KR: "React 네이티브 앱 개발",
        },
        span_content: {
          EN: "We provide full-service React Native app development, including wireframes, prototypes, design development, testing, and go-live.",
          KR: "우리는 와이어프레임, 프로토타입, 디자인, 개발, 테스트 및 라이브를 포함한 전체 서비스 React Native 앱 개발을 제공합니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "React Native UI/UX & Design",
          KR: "React 네이티브 UI/UX 및 디자인",
        },
        span_content: {
          EN: "Our expert UX/UI designers, visualizers, and design strategists will ensure that your users have an incredible design experience.",
          KR: "당사의 전문 UX/UI 디자이너, 시각화 및 디자인 전략가가 사용자에게 놀라운 디자인 경험을 제공할 것입니다.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "App Migration & Upgradation",
          KR: "앱 마이그레이션 및 업그레이드",
        },
        span_content: {
          EN: "Our team will assist you in migrating from a legacy platform to the React Native platform, as well as optimizing features for smooth performance.",
          KR: "우리 팀은 레거시 플랫폼에서 React Native 플랫폼으로 마이그레이션하고 원활한 성능을 위해 기능을 최적화하는 데 도움을 줄 것입니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Product Ideation & Consultation",
          KR: "제품 구상 및 상담",
        },
        span_content: {
          EN: "We also offer state-of-the-art product ideation and consultation services to ensure rapid business expansion and increased ROI.",
          KR: "또한 신속한 비즈니스 확장과 ROI 증대를 위해 최첨단 제품 아이디어 및 컨설팅 서비스를 제공합니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "React Native App Maintenance",
          KR: "React 네이티브 앱 유지 관리",
        },
        span_content: {
          EN: "We provide React native app maintenance services around the clock, as well as performance monitoring, to ensure powerful results and consistent ROI.",
          KR: "강력한 결과와 일관된 ROI를 보장하기 위해 24시간 React 기본 앱 유지 관리 서비스와 성능 모니터링을 제공합니다.",
        },
      },
    ],
  },
  EnterpriseAppDevelopment: {
    title: {
      EN: "Triggering Transformation: Our Enterprise Software Development Solutions",
      KR: "혁신의 계기: 당사의 엔터프라이즈 소프트웨어 개발 솔루션",
    },
    description: {
      EN: "Our enterprise app and software development solutions are tailored to your specific business requirements.",
      KR: "당사의 엔터프라이즈 앱 및 소프트웨어 개발 솔루션은 특정 비즈니스 요구 사항에 맞게 조정됩니다.",
    },
    content: [
      {
        id: 1,
        paragraph_content: {
          EN: "Custom Enterprise & Software Development",
          KR: "맞춤형 엔터프라이즈 및 소프트웨어 개발",
        },
        span_content: {
          EN: "We will improve your business infrastructure with scalable enterprise-grade software that will help you grow faster. Get access to our disruptive design.",
          KR: "더 빠르게 성장하는 데 도움이 되는 확장 가능한 엔터프라이즈급 소프트웨어로 비즈니스 인프라를 개선합니다. 우리의 파괴적인 디자인에 액세스하십시오.",
        },
      },
      {
        id: 2,
        paragraph_content: {
          EN: "Mobility Strategy & Consulting",
          KR: "모빌리티 전략 및 컨설팅",
        },
        span_content: {
          EN: "We will make your company future-ready by providing stunningly powerful and scalable mobility solutions that will increase productivity.",
          KR: "생산성을 높이는 놀랍도록 강력하고 확장 가능한 모빌리티 솔루션을 제공하여 귀사를 미래에 대비할 수 있도록 만들 것입니다.",
        },
      },
      {
        id: 3,
        paragraph_content: {
          EN: "Digital Transformation Services",
          KR: "디지털 혁신 서비스",
        },
        span_content: {
          EN: "CNTech is a global leader in enabling enterprise digital transformation. Make use of our experience in deploying digital technologies.",
          KR: "시엔태크는 엔터프라이즈 디지털 변환을 지원하는 글로벌 리더입니다. 디지털 기술 배포 경험을 활용하십시오.",
        },
      },
      {
        id: 4,
        paragraph_content: {
          EN: "Data Management",
          KR: "데이터 관리",
        },
        span_content: {
          EN: "Our powerful and ROI-focused Data Management services make use of cutting-edge technologies like predictive analytics and automation.",
          KR: "당사의 강력한 ROI 중심 데이터 관리 서비스는 예측 분석 및 자동화와 같은 첨단 기술을 사용합니다.",
        },
      },
      {
        id: 5,
        paragraph_content: {
          EN: "Software Based Automation",
          KR: "소프트웨어 기반 자동화",
        },
        span_content: {
          EN: "We will completely replace manual processes with lightning-fast software-based automation.",
          KR: "우리는 수동 프로세스를 번개처럼 빠른 소프트웨어 기반 자동화로 완전히 대체할 것입니다.",
        },
      },
      {
        id: 6,
        paragraph_content: {
          EN: "Legacy Application Modernization",
          KR: "레거시 애플리케이션 현대화",
        },
        span_content: {
          EN: "We are knight & in shining armor for businesses that are still using outdated, legacy systems. The old systems will be completely rebuilt.",
          KR: "우리는 여전히 구식 레거시 시스템을 사용하는 기업을 위해 기사와 갑옷을 입고 있습니다. 오래된 시스템은 완전히 재건될 것입니다.",
        },
      },
    ],
  },
};
const scheduleExpertSession = {
  header_text: {
    EN: "Schedule a free expert session with our experts",
    KR: "전문가와 무료 전문가 세션 예약",
  },
  testimonial_users: [
    {
      id: 1,
      user: {
        EN: "Jooyoung Mok",
        KR: "목주영",
      },
      designation: {
        EN: "CTO of Qoo10 group",
        KR: "Qoo10 그룹 CTO",
      },
      description: {
        EN: "Jooyoung Mok is the CTO of Qoo10 group. System architecture experienced in several domains - e-commerce, logistics,  logistics automation, general IT services on public cloud and on-premise environment.",
        KR: "목주영은 Qoo10 그룹의 CTO입니다. 전자 상거래, 물류, 물류 자동화, 퍼블릭 클라우드 및 온프레미스 환경의 일반 IT 서비스 등 여러 영역에서 시스템 아키텍처 경험.",
      },
      image: require("../assets/images/profile_jymok.jpg"),
    },
    {
      id: 2,
      user: {
        EN: "John Yun",
        KR: "윤수언",
      },
      designation: {
        EN: "Division Leader of Tech Planning Division",
        KR: "기술기획본부장",
      },
      description: {
        EN: "Experienced in System structure and process planning for more than 10 years for the sites such as e-commerce and logistics.",
        KR: "전자상거래, 물류 등 현장에서 10년 이상 시스템 구조 및 프로세스 기획 경험.",
      },
      image: require("../assets/images/yunsueon.png"),
    },
  ],
};
const brandPower = {
  header: {
    EN: "CNTech ",
    KR: "시엔태크 ",
  },
  header_info: {
    EN: "Powering",
    KR: "전원 공급",
  },
  text: {
    EN: "Brands, Retailers & Marketplaces.",
    KR: "브랜드, 소매업체 & 시장.",
  },
};
const healthCareApplicationHeader = {
  retailAndecomm: {
    header: {
      EN: "Enterprise eCommerce & Retail",
      KR: "기업 전자상거래 및 소매",
    },
    header_info: {
      EN: "Customers' experiences will be reframed by omnichannel retail and eCommerce services.",
      KR: "고객 경험은 옴니채널 소매 및 전자 상거래 서비스로 재구성됩니다.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    technologies: {
      EN: "Technologies",
      KR: "기술",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
  sportsAndentertainment: {
    header: {
      EN: "Sports & Entertainment",
      KR: "스포츠 및 엔터테인먼트",
    },
    header_info: {
      EN: "Enhance sports and entertainment experiences by gathering real-time data and providing personalized customer interactions.",
      KR: "실시간 데이터를 수집하고 개인화된 고객 상호 작용을 제공하여 스포츠 및 엔터테인먼트 경험을 향상합니다.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    technologies: {
      EN: "Technologies",
      KR: "기술",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
  mediaAndpublish: {
    header: {
      EN: "Digitizing The Media & Publishing Industry",
      KR: "미디어 및 출판 산업의 디지털화",
    },
    header_info: {
      EN: "Specific solutions to revolutionize the media and publishing industry.",
      KR: "미디어 및 출판 산업에 혁명을 일으킬 특정 솔루션.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    technologies: {
      EN: "Technologies",
      KR: "기술",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
  foodAndbeverages: {
    header: {
      EN: "Food & Beverages Software Development",
      KR: "식음료 소프트웨어 개발",
    },
    header_info: {
      EN: "Our expert developers personalize food and beverage industry software; we specifically design flexible and configurable food and beverage solutions to increase delivery efficiency, sales, and profitability.",
      KR: "당사의 전문 개발자는 식품 및 음료 산업 소프트웨어를 개인화합니다. 특히 유연하고 구성 가능한 식음료 솔루션을 설계하여 배달 효율성, 판매 및 수익성을 높입니다.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    technologies: {
      EN: "Technologies",
      KR: "기술",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
  health: {
    header: {
      EN: "Healthcare and Fitness Software Development Company",
      KR: "건강 관리 및 피트니스 소프트웨어 개발 회사",
    },
    header_info: {
      EN: "We create custom healthcare applications to help patients and doctors save time by streamlining appointment scheduling and patient check-in.",
      KR: "우리는 환자와 의사가 약속 일정과 환자 체크인을 간소화하여 시간을 절약할 수 있도록 맞춤형 의료 애플리케이션을 만듭니다.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    technologies: {
      EN: "Technologies",
      KR: "기술",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
  educationAndtraining: {
    header: {
      EN: "Custom E-Learning Solutions",
      KR: "맞춤형 E-Learning 솔루션",
    },
    header_info: {
      EN: "Experts in e-learning software development create unmatched solutions that promote positive user experiences for evolved educational systems in order to meet the objectives of higher education establishments.",
      KR: "e-러닝 소프트웨어 개발 전문가들은 고등 교육 기관의 목표를 달성하기 위해 진화된 교육 시스템에 대한 긍정적인 사용자 경험을 촉진하는 탁월한 솔루션을 만듭니다.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    technologies: {
      EN: "Technologies",
      KR: "기술",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
  logistics: {
    header: {
      EN: "Simplifying Digital Supply Chains Through Strategy & Innovations",
      KR: "전략 및 혁신을 통한 디지털 공급망 단순화",
    },
    header_info: {
      EN: "We at CNTech strive to change the logistics and transportation industry by attracting operational potency and automation to your company through the use of innovative technologies and tools.",
      KR: "우리 CNTech는 혁신적인 기술과 도구를 사용하여 귀사의 운영 능력과 자동화를 유치함으로써 물류 및 운송 산업을 변화시키기 위해 노력합니다.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    technologies: {
      EN: "Technologies",
      KR: "기술",
    },
    links: [
      {
        id: 1,
        class: "btn",
        title: { EN: "Let's talk", KR: "얘기하자" },
        link_to: "/contact",
        text: { EN: "Let's talk", KR: "얘기하자" },
      },
      {
        id: 2,
        class: "btn bgwhite",
        title: { EN: "Technologies", KR: "기술" },
        link_to: "/technologies",
        text: { EN: "Technologies", KR: "기술" },
      },
    ],
  },
};
const aboutHealthCareApp = {
  retailAndecomm: {
    header: {
      EN: "About eCommerce & Retail",
      KR: "전자 상거래 및 소매 정보",
    },
    info: {
      EN: "An enterprise eCommerce platform must perform a variety of tasks, such as rendering product catalogs, managing orders, and connecting with third-party systems. When you consider large order volumes, multiple currencies, and different languages, the complexity of these tasks increases. If your eCommerce software is struggling to keep up today, imagine how far behind your brand will fall tomorrow. After all, consumers are increasingly expecting more complex and engaging customer experiences.",
      KR: "엔터프라이즈 전자 상거래 플랫폼은 제품 카탈로그 렌더링, 주문 관리 및 타사 시스템과의 연결. 대량 주문량을 고려할 때, 여러 통화 및 다른 언어로 인해 이러한 작업의 복잡성이 증가합니다. 전자 상거래 소프트웨어가 오늘날 따라잡기 위해 고군분투하고 있다면 브랜드가 얼마나 뒤처질지 상상해 보십시오. 내일. 결국 소비자들은 점점 더 복잡하고 매력적인 고객을 기대하고 있습니다. 경험담.",
    },
    image: require("../assets/images/industry_inner_1.png"),
  },
  sportsAndentertainment: {
    header: {
      EN: "About Sports & Entertaiment",
      KR: "스포츠 및 엔터테인먼트 정보",
    },
    info: {
      EN: "Sports entertainment is a type of spectacle that uses a high level of theatrical flourish and extravagant presentation to present an ostensibly competitive event for the purpose of entertaining an audience rather than sporting competition. Unlike traditional sports and games, which are played for competition, sportsmanship, physical exercise, or personal recreation, sports entertainment's primary product is a performance for the benefit of an audience. The eventual outcomes are frequently, but not always, predetermined and agreed upon between participants, and this is an open secret.",
      KR: "스포츠 엔터테인먼트는 높은 수준의 연극적 화려함과 화려함을 사용하는 일종의 스펙터클입니다. 청중을 즐겁게 할 목적으로 표면적으로 경쟁적인 이벤트를 제시하기 위한 프레젠테이션 스포츠 경기보다. 경쟁을 위해 하는 전통적인 스포츠 및 게임과 달리, 스포츠맨십, 신체 운동 또는 개인 레크리에이션, 스포츠 엔터테인먼트의 주요 제품은 관객을 위한 공연. 최종 결과는 자주 발생하지만 항상 그런 것은 아닙니다. 참여자 간에 사전에 결정되고 합의된 사항이며 이는 공개된 비밀입니다.",
    },
    image: require("../assets/images/industry_inner_2.png"),
  },
  mediaAndpublish: {
    header: {
      EN: "About Media & Publishing Industry",
      KR: "미디어 및 출판 산업 소개",
    },
    info: {
      EN: "Media & Publishing is the only organization committed to the needs of nonprofit print and digital publishers. AM&P also serves association executives of business operations, communication professionals, designers, editors, content creators, and the media they produce. Through insight, information, and community, we inspire excellence in association communications. Media & Publishing is the web or electronic carrier publication of magazines, e-books, white papers, reports, or any other type of longform content.",
      KR: "Media & Publishing은 비영리 인쇄 및 디지털의 요구에 전념하는 유일한 조직입니다. 출판사. AM&P는 또한 비즈니스 운영, 커뮤니케이션의 협회 임원에게 서비스를 제공합니다. 전문가, 디자이너, 편집자, 콘텐츠 제작자 및 그들이 생산하는 미디어. 통찰력을 통해, 정보 및 커뮤니티, 우리는 협회 커뮤니케이션의 우수성을 고취합니다. Media & Publishing은 잡지, 전자책, 백서, 보고서 또는 기타 모든 유형의 장편 콘텐츠.",
    },
    image: require("../assets/images/industry_inner_3.png"),
  },
  foodAndbeverages: {
    header: {
      EN: "About Food & Beverage Software",
      KR: "식음료 소프트웨어 정보",
    },
    info: {
      EN: "F&B Solutions is a forward-thinking B2B food service provider in the Korea casual dining market. We create, build, and develop diverse food and beverage solutions under our own distinct brands, packaging them into comprehensive and ready-to-use solutions for hospitality establishments. Our main goal is to de-skill, reduce waste, and maximize efficiency in any commercial kitchen without sacrificing quality or profit. Food & Beverage Solutions represents some of the nation's fastest-growing, on-trend hospitality brands, which are speedily becoming loved and trusted.",
      KR: "F&B Solutions는 한국 캐주얼 다이닝 시장의 진취적인 B2B 외식 서비스 기업입니다. 우리 고유한 브랜드로 다양한 식음료 솔루션을 생성, 구축 및 개발합니다. 환대 시설을 위한 포괄적이고 바로 사용할 수 있는 솔루션으로 패키징합니다. 우리의 주요 목표는 모든 상업용 주방에서 기술을 희생하지 않으면서 낭비를 줄이고 효율성을 극대화하는 것입니다. 품질이나 이익. Food & Beverage Solutions는 미국에서 가장 빠르게 성장하는 최신 트렌드의 호스피탈리티 브랜드를 대표합니다. 빠르게 사랑받고 신뢰받는 곳.",
    },
    image: require("../assets/images/industry_inner_4.png"),
  },
  health: {
    header: {
      EN: "About Healthcare and Fitness Software",
      KR: "건강 관리 및 피트니스 소프트웨어 정보",
    },
    info: {
      EN: "Healthcare, like most other industries, has experienced rapid transformations as a result of technological advancements. Globally, the use of smartphone medical applications has increased, with an increasing number of people relying on them for everything from medical treatments to overall health and well-being. Did you know that the global medical app market has tremendous growth potential and is expected to generate USD 111.1 billion by 2025? So, if you want to create a mobile healthcare app or improve an existing one, now is the time to invest your resources.",
      KR: "대부분의 다른 산업과 마찬가지로 의료 분야도 기술 발전의 결과로 급격한 변화를 경험했습니다. 전 세계적으로 스마트폰 의료 애플리케이션의 사용이 증가했으며 의료에서 ​​전반적인 건강 및 웰빙에 이르기까지 모든 분야에서 스마트폰에 의존하는 사람들이 증가하고 있습니다. 글로벌 의료 앱 시장은 엄청난 성장 잠재력을 가지고 있으며 2025년까지 1,111억 달러 규모로 성장할 것으로 예상된다는 사실을 알고 계셨습니까? 따라서 모바일 건강 관리 앱을 만들거나 기존 앱을 개선하려는 경우 지금이 리소스를 투자할 때입니다.",
    },
    image: require("../assets/images/industry_inner_5.png"),
  },
  educationAndtraining: {
    header: {
      EN: "About E-Learning Solutions",
      KR: "E-Learning 솔루션 정보",
    },
    info: {
      EN: "E-Learning is a method of delivering lessons quickly. When compared to traditional classroom teaching methods, this mode has relatively short delivery cycles. This means that the time required to learn is reduced by 25% to 60% compared to traditional learning. Our eLearning solution is delivered as an Internet platform SaaS (Software-as-a-Service), allowing businesses and individuals to gain access to online digital education tools without having to invest large sums of money or pay for maintenance.",
      KR: "E-Learning은 수업을 빠르게 전달하는 방법입니다. 기존의 교실 수업과 비교할 때 방법에 비해 이 모드는 전달 주기가 비교적 짧습니다. 즉, 학습에 필요한 시간은 전통적인 학습에 비해 25%에서 60%까지 감소했습니다. 당사의 eLearning 솔루션은 인터넷 플랫폼 SaaS(Software-as-a-Service)를 통해 기업과 개인이 액세스할 수 있습니다. 많은 돈을 투자하거나 유지 보수 비용을 지불하지 않고도 온라인 디지털 교육 도구.",
    },
    image: require("../assets/images/industry_inner_6.png"),
  },
  logistics: {
    header: {
      EN: "About Logistics Solutions",
      KR: "물류 솔루션 정보",
    },
    info: {
      EN: "Our logistics web development service is equipped with the essential and distinct engagement models required to take a business to the next level and build it to stand out globally. The transportation industry, on the other hand, will be regarded as an advanced set of operations. As a result, many businesses lose a significant portion of their revenue.",
      KR: "우리의 물류 웹 개발 서비스는 필수적이고 독특한 참여 모델을 갖추고 있습니다. 비즈니스를 다음 단계로 끌어올리고 전 세계적으로 두각을 나타내기 위해 구축해야 합니다. 교통 반면에 산업은 고급 작업 세트로 간주됩니다. 그 결과 많은 기업은 수익의 상당 부분을 잃습니다.",
    },
    image: require("../assets/images/industry_inner_7.png"),
  },
};
const healthCareServices = {
  retailAndecomm: {
    header: {
      EN: "Types Of eCommerce",
      KR: "전자상거래의 유형",
    },
    info: {
      EN: "Generally, there are six main models of eCommerce that businesses can be categorized into.",
      KR: "일반적으로 비즈니스를 분류할 수 있는 전자 상거래의 6가지 주요 모델이 있습니다.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Business To Consumer (B2C)",
          KR: "기업 대 소비자(B2C)",
        },
        info: {
          EN: "B2C eCommerce refers to transactions between a company and a customer. In the context of eCommerce, this is one of the most frequently used sales models.",
          KR: "B2C 전자상거래는 기업과 고객 간의 거래를 의미합니다. 문맥 상에 전자 상거래, 이것은 가장 자주 사용되는 판매 모델 중 하나입니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Business To Business (B2B)",
          KR: "기업간 거래(B2B)",
        },
        info: {
          EN: "B2B eCommerce, as compared to B2C eCommerce, refers to sales made between businesses, such as a manufacturer and a wholesaler or retailer. This type of eCommerce is not aimed at consumers.",
          KR: "B2C 전자 상거래와 비교하여 B2B 전자 상거래는 제조자 및 도매업자 또는 소매업자. 이러한 유형의 전자 상거래는 소비자를 대상으로 하지 않습니다.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Consumer To Consumer (C2C)",
          KR: "소비자 대 소비자(C2C)",
        },
        info: {
          EN: "The C2C eCommerce business model is one of the oldest forms of eCommerce. Customer-to-customer refers to the sale of products or services between customers.",
          KR: "C2C 전자 상거래 비즈니스 모델은 전자 상거래의 가장 오래된 형태 중 하나입니다. 고객 대 고객 고객 간에 제품 또는 서비스를 판매하는 것을 말합니다.",
        },
      },
      {
        id: 4,
        title: {
          EN: "Consumer To Business (C2B)",
          KR: "소비자 대 기업(C2B)",
        },
        info: {
          EN: "C2B is the inverse of the classic eCommerce model (and is common in crowdfunding projects). Individual consumers make their products or services available to business buyers through C2B.",
          KR: "C2B는 고전적인 전자 상거래 모델의 반대입니다(크라우드 펀딩 프로젝트에서 일반적임). 개인 소비자는 C2B를 통해 비즈니스 구매자에게 제품이나 서비스를 제공합니다.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Business To Administration (B2A)",
          KR: "기업 간 관리(B2A)",
        },
        info: {
          EN: "This model applies to transactions between online businesses and administrations. Products and services related to legal documents, social security, and so on are examples.",
          KR: "이 모델은 온라인 비즈니스와 행정부 간의 거래에 적용됩니다. 제품 및 법률 문서, 사회 보장 등과 관련된 서비스가 그 예입니다.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Consumer To Administration (C2A)",
          KR: "소비자 대 행정(C2A)",
        },
        info: {
          EN: "The same concept applies here, except that consumers are selling online products or services to an administration. C2A could include things like online education consulting, online tax preparation, and so on.",
          KR: "소비자가 온라인 제품이나 서비스를 판매한다는 점을 제외하고 동일한 개념이 여기에 적용됩니다. 관리. C2A에는 온라인 교육 컨설팅, 온라인 세금 준비 등이 포함될 수 있습니다. 에.",
        },
      },
    ],
  },
  sportsAndentertainment: {
    header: {
      EN: "Sports Analytics & Athlete Performance Solutions",
      KR: "스포츠 분석 및 선수 성과 솔루션",
    },
    info: {
      EN: "Our sports software developers create a suite of sports analytics tools for maximum performance intelligence, including custom data capture for training, injury monitoring, and tactical game analysis.",
      KR: "당사의 스포츠 소프트웨어 개발자는 최대 성능을 위한 스포츠 분석 도구 제품군을 만듭니다. 훈련, 부상 모니터링 및 전술적 게임 분석을 위한 맞춤형 데이터 캡처를 포함한 인텔리전스.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Sports Analytics Software",
          KR: "스포츠 분석 소프트웨어",
        },
        info: {
          EN: "Through pre/post game analysis applications and motion/video analysis software, we deploy and implement custom sports analytics and athlete performance software with real-time predictive insights.",
          KR: "게임 전/후 분석 애플리케이션과 모션/비디오 분석 소프트웨어를 통해 배포하고 실시간 예측 통찰력으로 맞춤형 스포츠 분석 및 운동 선수 성과 소프트웨어를 구현합니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Sports Performance Analysis Software",
          KR: "스포츠 경기력 분석 소프트웨어",
        },
        info: {
          EN: "We create sports video analysis software that includes editing, tagging, side-by-side viewing, synchronization, photo sequencing, and footage playback.",
          KR: "우리는 편집, 태깅, 나란히 보기, 동기화, 사진 시퀀싱 및 푸티지 재생.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Athlete Tracking Technology",
          KR: "선수 추적 기술",
        },
        info: {
          EN: "We offer a full-cycle software development service, and our powerful custom sports performance tracking software supports tactical and physical performance analysis with RFID application integrations.",
          KR: "우리는 풀 사이클 소프트웨어 개발 서비스와 강력한 맞춤형 스포츠 성능을 제공합니다. 추적 소프트웨어는 RFID 애플리케이션 통합을 통해 전술 및 물리적 성능 분석을 지원합니다.",
        },
      },
    ],
  },
  mediaAndpublish: {
    header: {
      EN: "Our Media & Publishing Services",
      KR: "미디어 및 출판 서비스",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Media IT & Consulting",
          KR: "미디어 IT 및 컨설팅",
        },
        info: {
          EN: "Our media IT services and consulting are designed to assist clients in transforming their physical and digital businesses by focusing on digital experiences and building agile networks.",
          KR: "당사의 미디어 IT 서비스 및 컨설팅은 고객이 물리적 및 디지털 경험에 중점을 두고 민첩한 네트워크를 구축함으로써 디지털 비즈니스를 실현합니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Order Management",
          KR: "주문 관리",
        },
        info: {
          EN: "We assist businesses in increasing profitability through more precise and integrated forecasts by allowing them to manage sales and optimize workflow through CRM and invoice integration.",
          KR: "보다 정확하고 통합된 예측을 통해 기업의 수익성 증대를 지원합니다. CRM 및 송장 통합을 통해 영업을 관리하고 워크플로를 최적화할 수 있습니다.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Cross-Media Publishing",
          KR: "미디어 간 출판",
        },
        info: {
          EN: "CNTech can create visually appealing content that can automatically be published to the web, digital, and print devices.",
          KR: "CNTech는 웹, 디지털, 및 인쇄 장치.",
        },
      },
      {
        id: 4,
        title: {
          EN: "Digital Publishing",
          KR: "디지털 출판",
        },
        info: {
          EN: "Through custom e-reader apps, interactive eCommerce sites, and branded newsreader apps, our publishing web and mobile app development services enable publishers to distribute their content on any media, device, or platform.",
          KR: "맞춤형 전자책 앱, 양방향 전자상거래 사이트, 브랜드 뉴스리더 앱을 통해 퍼블리싱 웹 및 모바일 앱 개발 서비스를 통해 퍼블리셔는 콘텐츠를 배포할 수 있습니다. 모든 미디어, 장치 또는 플랫폼.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Cloud Technology",
          KR: "클라우드 기술",
        },
        info: {
          EN: "We leverage cloud technology to help print and digital businesses take a holistic view of behavioral, revenue, and customer data in order to maximize revenues and improve service performance.",
          KR: "우리는 클라우드 기술을 활용하여 인쇄 및 디지털 비즈니스가 행동, 수익을 극대화하고 서비스 성과를 개선하기 위해 수익 및 고객 데이터.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Optimized Delivery",
          KR: "최적화된 배송",
        },
        info: {
          EN: "CNTech uses specialized media tools to optimize content delivery platforms and assist media and publishing organizations in providing search engine and mobile content on a global scale.",
          KR: "CNTech는 전문 미디어 도구를 사용하여 콘텐츠 전달 플랫폼을 최적화하고 미디어 및 글로벌 규모로 검색 엔진 및 모바일 콘텐츠를 제공하는 출판 조직.",
        },
      },
    ],
  },
  foodAndbeverages: {
    header: {
      EN: "Food Safety & Compliance Software",
      KR: "식품 안전 및 규정 준수 소프트웨어",
    },
    info: {
      EN: "Use trigger-based automation to monitor temperature, moisture, and acid conditions to maintain HACCP, HARPC, ISO frameworks, and GFSI quality control when manufacturing, processing, and storing foods.",
      KR: "트리거 기반 자동화를 사용하여 온도, 수분 및 산 상태를 모니터링하여 유지 제조, 가공 및 보관 시 HACCP, HARPC, ISO 프레임워크 및 GFSI 품질 관리 식품.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Food Safety Software",
          KR: "식품 안전 소프트웨어",
        },
        info: {
          EN: "Supply chain management, distribution, warehouse, LIMS, and ERP systems are all configured by ourquality management systems solutions. Cloud-based servers expedite data collection via mobile apps and vendor portals.",
          KR: "공급망 관리, 유통, 창고, LIMS 및 ERP 시스템은 모두 당사에서 구성합니다. 품질 관리 시스템 솔루션. 클라우드 기반 서버는 모바일 앱을 통해 데이터 수집을 촉진합니다. 및 공급업체 포털.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Food Safety Audits & Compliance Software",
          KR: "식품 안전 감사 및 규정 준수 소프트웨어",
        },
        info: {
          EN: "Using record management databases and e-filing, our Food Compliance Software ensures COA, GMP, HACCP, HARPC, Codex Alimentarius, and ISO 22000 standards.",
          KR: "기록 관리 데이터베이스 및 전자 파일링을 사용하여 당사의 식품 규정 준수 소프트웨어는 COA, GMP, HACCP, HARPC, Codex Alimentarius 및 ISO 22000 표준.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Food Traceability Software",
          KR: "식품 이력 추적 소프트웨어",
        },
        info: {
          EN: "We create software systems for food and beverage traceability that are specifically designed for farm- to-fork supply chain visibility. We incorporate GPS tools as well as EDI communication protocols.",
          KR: "우리는 농장을 위해 특별히 설계된 식품 및 음료 추적성을 위한 소프트웨어 시스템을 만듭니다. to-fork 공급망 가시성. 우리는 GPS 도구와 EDI 통신 프로토콜을 통합합니다.",
        },
      },
    ],
  },
  health: {
    header: {
      EN: "Healthcare Software Development Services",
      KR: "의료 소프트웨어 개발 서비스",
    },
    info: {
      EN: "We provide hospitals, doctors, and patients with healthcare software development services, providing a high level of digital healthcare service and health management. To assist health systems and clinics in dealing with this sensitive data, we develop custom healthcare solutions and mobile applications for the healthcare industry.",
      KR: "병원, 의사, 환자에게 헬스케어 소프트웨어 개발 서비스를 제공합니다. 높은 수준의 디지털 의료 서비스 및 건강 관리. 의료 시스템 및 클리닉을 지원하기 위해 이러한 민감한 데이터를 처리하기 위해 맞춤형 의료 솔루션 및 모바일 애플리케이션을 개발합니다. 의료 산업.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Healthcare Software",
          KR: "의료 소프트웨어",
        },
        info: {
          EN: "CNTech provides healthcare software development services to assist you in improving data management processes and providing better healthcare to your patients.",
          KR: "CNTech는 데이터 개선을 지원하는 의료 소프트웨어 개발 서비스를 제공합니다. 관리 프로세스 및 환자에게 더 나은 의료 서비스를 제공합니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Mobile Apps for Healthcare",
          KR: "의료용 모바일 앱",
        },
        info: {
          EN: "Using medical mobile applications, you and your patients can keep a watch on their health and stay updated from anywhere in the world.",
          KR: "의료 모바일 애플리케이션을 사용하여 귀하와 귀하의 환자는 자신의 건강을 감시하고 입원할 수 있습니다. 전 세계 어디에서나 업데이트됩니다.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Need A Custom Solution?",
          KR: "맞춤형 솔루션이 필요하십니까?",
        },
        info: {
          EN: "We offer full-cycle software development and build applications from the ground up to create a solution that addresses your specific needs",
          KR: "우리는 전체 주기 소프트웨어 개발을 제공하고 솔루션을 만들기 위해 처음부터 응용 프로그램을 빌드합니다. 귀하의 특정 요구 사항을 해결하는",
        },
      },
    ],
  },
  educationAndtraining: {
    header: {
      EN: "Custom E-Learning & Solutions Benefit",
      KR: "맞춤형 E-Learning 및 솔루션 혜택",
    },
    info: {
      EN: "EdTech software will greatly improve the performance of your organization, whether you represent a private business, an educational, or a governmental institution. Online methods have gained popularity in recent years due to the following benefits.",
      KR: "EdTech 소프트웨어는 조직의 성과를 크게 향상시킬 것입니다. 민간 기업, 교육 기관 또는 정부 기관. 온라인 방법이 인기를 얻었습니다. 최근 몇 년 동안 다음과 같은 이점으로 인해.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Progress Tracking",
          KR: "진행 추적",
        },
        info: {
          EN: "Your trainees will have quick access to the information they require. They can always track their progress and communicate with other students.",
          KR: "교육생은 필요한 정보에 빠르게 액세스할 수 있습니다. 그들은 항상 자신의 진행하고 다른 학생들과 의사 소통합니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Positive Experience",
          KR: "긍정적인 경험",
        },
        info: {
          EN: "E-Learning apps offer a more comprehensive and effective learning experience than traditional methods of teaching.",
          KR: "E-Learning 앱은 기존 방법보다 더 포괄적이고 효과적인 학습 경험을 제공합니다. 가르침의.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Mobility",
          KR: "유동성",
        },
        info: {
          EN: "Allow your students to study wherever they want. The use of laptops, tablets, or phones creates a comfortable classroom from anywhere in the world.",
          KR: "학생들이 원하는 곳에서 공부할 수 있도록 합니다. 랩톱, 태블릿 또는 전화를 사용하면 세계 어디에서나 편안한 교실.",
        },
      },
    ],
  },
  logistics: {
    header: {
      EN: "We Provide Feature Rich Transport & Logistics Web Development",
      KR: "기능이 풍부한 운송 및 물류 웹 개발을 제공합니다.",
    },
    info: {
      EN: "Take a look at some of the advanced options available from CNTech, a web development company near Delhi, for transportation and logistics software development services and solutions.",
      KR: "가까운 웹 개발 회사인 CNTech에서 제공하는 고급 옵션 중 일부를 살펴보십시오. 운송 및 물류 소프트웨어 개발 서비스 및 솔루션을 위한 델리.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Logistical Packaging",
          KR: "물류 포장",
        },
        info: {
          EN: "The app includes a logistics and transportation packaging feature that allows the owner to track merchandise information and gain a better understanding of product packaging.",
          KR: "앱에는 소유자가 추적할 수 있는 물류 및 운송 포장 기능이 포함되어 있습니다. 상품 정보를 확인하고 제품 포장에 대해 더 잘 이해할 수 있습니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Warehouse Management",
          KR: "창고 관리",
        },
        info: {
          EN: "The warehouse management feature allows the owner to comprehend the movement of merchandise. In addition, our logistics and transportation web development company will provide you with an elaborated product.",
          KR: "창고 관리 기능을 통해 소유자는 상품의 이동을 이해할 수 있습니다. 또한 물류 및 운송 웹 개발 회사는 귀하에게 정교한 제품.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Inventory Management",
          KR: "재고 관리",
        },
        info: {
          EN: "The internal controlled feature assists the owner with product segregation and placement within the warehouse. It can aid in the resolution of the problem associated with storing a variety of items.",
          KR: "내부 제어 기능은 소유자가 제품을 분리하고 내부에 배치하는 데 도움이 됩니다. 창고. 다양한 항목을 저장하는 것과 관련된 문제를 해결하는 데 도움이 될 수 있습니다.",
        },
      },
      {
        id: 4,
        title: {
          EN: "Manage Transportation",
          KR: "교통 관리",
        },
        info: {
          EN: "Everything is now automated to avoid and minimize human error. Our logistics and transportation web development company in Delhi offers a transportation module in its app.",
          KR: "이제 모든 것이 자동화되어 인적 오류를 방지하고 최소화합니다. 물류 및 운송 웹 델리의 개발 회사는 앱에서 운송 모듈을 제공합니다.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Vendor Inventory",
          KR: "공급업체 인벤토리",
        },
        info: {
          EN: "The distributor-managed inventory assists you in streamlining accessibility chain operations and maintaining a healthy owner-vendor relationship.",
          KR: "유통업체에서 관리하는 재고는 접근성 체인 운영을 간소화하고 건강한 소유자-공급자 관계를 유지합니다.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Material Handling",
          KR: "자재 취급",
        },
        info: {
          EN: "It monitors the handling of raw materials, as well as the movement of semi-finished and finished goods into and out of plants, warehouses, and transportation terminals.",
          KR: "반제품 및 완제품의 이동은 물론 원자재의 취급을 모니터링합니다. 공장, 창고 및 운송 터미널 안팎으로.",
        },
      },
    ],
  },
};
const brandHealthCareServices = {
  retailAndecomm: {
    header: {
      EN: "Our eCommerce & Retail Services",
      KR: "우리의 전자 상거래 및 소매 서비스",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Enterprise eCommerce Solutions",
          KR: "엔터프라이즈 전자상거래 솔루션",
        },
        info: {
          EN: "Our eCommerce experts provide end-users with a personalized shopping experience through seamless innovation and omnichannel solutions. Examples include: powerful APIs for complex and custom builds, and a content management system.",
          KR: "당사의 전자 상거래 전문가는 원활한 서비스를 통해 최종 사용자에게 맞춤형 쇼핑 경험을 제공합니다. 혁신 및 옴니채널 솔루션. 예는 다음과 같습니다. 복잡한 사용자 정의 빌드를 위한 강력한 API, 및 콘텐츠 관리 시스템.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Advanced Business & Consumer Insights",
          KR: "고급 비즈니스 및 소비자 통찰력",
        },
        info: {
          EN: "We offer advanced insights and analytics to assist clients in providing personalized services and rising consumer engagement. Dynamic pricing, buyer behavior prediction, demand forecasting, and cart abandonment are some examples.",
          KR: "우리는 고객이 개인화된 서비스를 제공하고 성장하는 데 도움이 되는 고급 통찰력과 분석을 제공합니다. 소비자 참여. 동적 가격 책정, 구매자 행동 예측, 수요 예측 및 장바구니 포기가 몇 가지 예입니다.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Omnichannel Solutions",
          KR: "옴니채널 솔루션",
        },
        info: {
          EN: "Our omnichannel eCommerce and retail software solutions provide customers with an unparalleled shopping experience while increasing clients' ROI. For example, social commerce and marketing, as well as m-commerce apps.",
          KR: "당사의 옴니채널 전자상거래 및 소매 소프트웨어 솔루션은 고객에게 비교할 수 없는 고객의 ROI를 높이는 동시에 쇼핑 경험을 제공합니다. 예를 들어, 소셜 커머스 및 마케팅뿐만 아니라 m-커머스 앱으로.",
        },
      },
      {
        id: 4,
        title: {
          EN: "Platform Extensions",
          KR: "플랫폼 확장",
        },
        info: {
          EN: "CNTech-commerce's developers extend existing eCommerce platforms to support new technologies and capabilities. Search Engine Optimization, Contextual Search, integration with Third-Party Apps and SDKs such as Google Wallet, Apple Touch, and others, and Digital Wallets are examples.",
          KR: "CNTech-commerce의 개발자는 기존 전자상거래 플랫폼을 확장하여 새로운 기술과 능력. 검색 엔진 최적화, 문맥 검색, 타사 앱 및 SDK와의 통합 Google Wallet, Apple Touch 등의 디지털 지갑이 그 예입니다",
        },
      },
      {
        id: 5,
        title: {
          EN: "Customer Loyalty & Rewards",
          KR: "고객 충성도 및 보상",
        },
        info: {
          EN: "We develop Customer Loyalty and Rewards features to foster strong customer relationships and add value to our clients' businesses. Customer Advocacy, Peer to Peer and Social Deals, User Interface and Personalized Messages, 360 Degree Customer Engagement, Real-Time and Personalized Coupons are just a few examples.",
          KR: "우리는 강력한 고객 관계를 육성하고 추가하기 위해 고객 충성도 및 보상 기능을 개발합니다. 고객의 비즈니스에 가치를 제공합니다. 고객 옹호, P2P 및 소셜 거래, 사용자 인터페이스 및 개인화된 메시지, 360도 고객 참여, 실시간 및 개인화된 쿠폰은 몇 가지 예에 불과합니다.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Engineering Efficiency",
          KR: "엔지니어링 효율성",
        },
        info: {
          EN: "CNTech-commerce is skilled at integrating advanced engineering technology with existing systems in order to keep up with technological innovation and ensure a faster time to market. API Enablement and Management, Mobile Security, Test Automation, and DevOps are a few examples.",
          KR: "CNTech-commerce는 고급 엔지니어링 기술을 기존 시스템과 통합하는 데 능숙합니다. 기술 혁신을 따라잡고 시장 출시 시간을 단축합니다. API 활성화 및 관리, 모바일 보안, 테스트 자동화 및 DevOps가 몇 가지 예입니다.",
        },
      },
      {
        id: 7,
        title: {
          EN: "B2B eCommerce Solutions",
          KR: "B2B 전자상거래 솔루션",
        },
        info: {
          EN: "CNTech-commerce's B2B ECommerce solutions aid in increasing operational efficiency and shortening the sales cycle. B2B Multichannel Commerce Infrastructure, Orders Managed and Processed Centrally, Unique Customer Catalogs, Integration with ERP, CRM, SAP, and so on.",
          KR: "CNTech-commerce의 B2B 전자 상거래 솔루션은 운영 효율성을 높이고 시간을 단축하는 데 도움이 됩니다. 판매 주기. B2B 다채널 상거래 인프라, 중앙에서 주문 관리 및 처리, 고유한 고객 카탈로그, ERP, CRM, SAP 등과의 통합.",
        },
      },
      {
        id: 8,
        title: {
          EN: "Scalable B2C eCommerce Solutions",
          KR: "확장 가능한 B2C 전자 상거래 솔루션",
        },
        info: {
          EN: "We build state-of-the-art CMS and ECommerce platforms for B2C eCommerce businesses to help them improve their online sales. eCommerce Consulting, Online Shopping Cart Software Development, Integrated, On-page Analytics, Smart Search, and Navigation Functionality are some examples.",
          KR: "우리는 B2C 전자 상거래 비즈니스를 돕기 위해 최첨단 CMS 및 전자 상거래 플랫폼을 구축합니다. 그들의 온라인 판매를 개량하십시오. 전자상거래 컨설팅, 온라인 쇼핑 카트 소프트웨어 개발, 통합, 온페이지 분석, 스마트 검색 및 탐색 기능이 몇 가지 예입니다.",
        },
      },
    ],
  },
  sportsAndentertainment: {
    header: {
      EN: "Sports Software Development Services",
      KR: "스포츠 소프트웨어 개발 서비스",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Fitness Club Management Software",
          KR: "피트니스 클럽 관리 소프트웨어",
        },
        info: {
          EN: "Because of our extensive experience in this field, we can effectively assist startups in planning and deploying their SaaS application strategy.",
          KR: "이 분야에서 풍부한 경험을 바탕으로 스타트업의 기획 및 운영을 효과적으로 지원할 수 있습니다. SaaS 애플리케이션 전략을 배포합니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Membership Management Software",
          KR: "회원 관리 소프트웨어",
        },
        info: {
          EN: "We create membership management software modules such as enrollment applications, digital signature capture, membership database and payment tracking software, photo ID capture, web portals, and integrated website / mobile app services.",
          KR: "우리는 등록 응용 프로그램, 디지털 서명 캡처, 회원 데이터베이스 및 지불 추적 소프트웨어, 사진 ID 캡처, 웹 포털, 및 통합 웹사이트/모바일 앱 서비스.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Golf Software Development",
          KR: "골프 소프트웨어 개발",
        },
        info: {
          EN: "With specialized applications for private clubs, resorts, and public courses, our team creates customized golf management software to simplify operations, enhance player experience, and maximize the value of your golf business.",
          KR: "프라이빗 클럽, 리조트, 퍼블릭 코스를 위한 특화된 어플리케이션으로 우리 팀은 맞춤형 운영을 단순화하고 플레이어 경험을 향상하며 가치를 극대화하는 골프 관리 소프트웨어 당신의 골프 비즈니스.",
        },
      },
      {
        id: 4,
        title: {
          EN: "Gym Mobile App Development",
          KR: "체육관 모바일 앱 개발",
        },
        info: {
          EN: "Our developers create custom branded mobile applications to increase client engagement through fitness trackers, online coaching, diet and nutrition planners, class booking capabilities, social media integrations, and more.",
          KR: "당사 개발자는 맞춤형 브랜드 모바일 애플리케이션을 만들어 다음을 통해 고객 참여를 높입니다. 피트니스 트래커, 온라인 코칭, 다이어트 및 영양 플래너, 수업 예약 기능, 소셜 미디어 통합 등이 있습니다.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Fitness Equipment Software Technology",
          KR: "피트니스 장비 소프트웨어 기술",
        },
        info: {
          EN: "We create embedded applications, cloud connectivity, and software for wearable fitness technology. We create fitness equipment software that includes custom screens/interfaces, motion tracking/analysis, synchronization with mobile apps, and data collection.",
          KR: "웨어러블 피트니스 기술을 위한 임베디드 애플리케이션, 클라우드 연결 및 소프트웨어를 만듭니다. 우리는 맞춤형 화면/인터페이스, 모션을 포함하는 피트니스 장비 소프트웨어를 만듭니다. 추적/분석, 모바일 앱과의 동기화, 데이터 수집.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Golf POS Software Solutions",
          KR: "골프 POS 소프트웨어 솔루션",
        },
        info: {
          EN: "We create customized touch screen POS systems and payment gateway integrations for pro shops, 19th holes, restaurants, concession stands, and other businesses. We develop customized apps for eCommerce, electronic billing, payments, and collection systems.",
          KR: "프로샵을 위한 맞춤형 터치스크린 POS 시스템 및 결제 게이트웨이 통합, 19일 구멍, 레스토랑, 매점 및 기타 사업체. 를 위한 맞춤형 앱을 개발합니다. 전자 상거래, 전자 청구, 지불 및 수집 시스템.",
        },
      },
      {
        id: 7,
        title: {
          EN: "Club Event Management Tools",
          KR: "클럽 행사 관리 도구",
        },
        info: {
          EN: "We offer event and tournament management software for leagues, tournaments, associations, and private play, as well as custom modules for player access portals, perfect pairing optimization, handicaps, live scores/leader boards module outputs, multiple game formats, weather monitoring, and more.",
          KR: "우리는 리그, 토너먼트, 협회 및 개인 플레이는 물론 플레이어 액세스 포털을 위한 맞춤형 모듈, 완벽한 페어링 최적화, 핸디캡, 라이브 스코어/리더 보드 모듈 출력, 다양한 게임 형식, 날씨 모니터링 및 더.",
        },
      },
      {
        id: 8,
        title: {
          EN: "Motion Tracking Systems",
          KR: "모션 추적 시스템",
        },
        info: {
          EN: "We create motion tracking software systems that analyze and track skeletal, muscular, joint, hand, eye, posture, gait/stride, balance, 3D ranges of motion, 3D anatomical analysis, Six degrees of Freedom (6DOF), and other physical characteristics.",
          KR: "우리는 골격, 근육, 관절, 손, 눈, 자세, 보행/보폭, 균형, 3D 동작 범위, 3D 해부학적 분석, 6자유도 (6DOF) 및 기타 물리적 특성.",
        },
      },
    ],
  },
  foodAndbeverages: {
    header: {
      EN: "Our Food & Beverage Services",
      KR: "음식 및 음료 서비스",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Food Inventory Management System",
          KR: "식품 재고 관리 시스템",
        },
        info: {
          EN: "Upstream and downstream traceability is provided by our EDI integration. To automate replenishments, payments, and distribution, we interface F&B inventory management systems with dynamic, rule-based purchasing engines.",
          KR: "업스트림 및 다운스트림 추적 기능은 EDI 통합을 통해 제공됩니다. 보충을 자동화하려면, 지불 및 유통, F&B 재고 관리 시스템을 동적 규칙 기반 구매 엔진.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Mobile Inventory Apps",
          KR: "모바일 인벤토리 앱",
        },
        info: {
          EN: "We create native and cross-platform mobile apps (iOS, Android, and Windows) for the remote management of Food and Beverage inventory operations in the F&B supply chain sectors. For enterprise-wide inventory tracking, we integrate data aggregation tools.",
          KR: "원격용 네이티브 및 크로스 플랫폼 모바일 앱(iOS, Android 및 Windows)을 만듭니다. F&B 공급망 부문의 식품 및 음료 재고 운영 관리. 을 위한 전사적 재고 추적을 위해 데이터 집계 도구를 통합합니다.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Food & Beverage Quality Solutions",
          KR: "식음료 품질 솔루션",
        },
        info: {
          EN: "We engineer and network embedded software in Automatic Identification and Data Capture (AIDC) sensors to provide real-time geographic and status information to inventory databases.",
          KR: "AIDC(자동 식별 및 데이터 캡처)에서 임베디드 소프트웨어를 엔지니어링 및 네트워크화합니다. 인벤토리 데이터베이스에 실시간 지리 및 상태 정보를 제공하는 센서.",
        },
      },
      {
        id: 4,
        title: {
          EN: "Food Production Software",
          KR: "식품 생산 소프트웨어",
        },
        info: {
          EN: "With your F&B manufacturing systems for on-premises, cloud (SaaS), mobile, and hybrid platforms, you can create your own customized collaborative recipe and packaging development, workflow optimization, and more.",
          KR: "온프레미스, 클라우드(SaaS), 모바일 및 하이브리드 플랫폼을 위한 F&B 제조 시스템을 사용하면 나만의 맞춤형 협업 레시피 및 패키징 개발, 워크플로를 만들 수 있습니다. 최적화 등이 있습니다.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Food Manufacturing ERP Systems",
          KR: "식품 제조 ERP 시스템",
        },
        info: {
          EN: "We offer full-service Food and Beverage ERP software solutions that integrate MES, distribution, accounting, marketing, and CRM systems to streamline production workflows.",
          KR: "우리는 MES, 유통, 회계, 마케팅 및 CRM 시스템을 사용하여 생산 워크플로를 간소화합니다.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Quality Control in Manufacturing",
          KR: "제조 품질 관리",
        },
        info: {
          EN: "Among other features, our custom quality control systems include ingredient traceability, multi-location environmental controls, regulatory compliance, and COA report generation.",
          KR: "다른 기능 중에서 당사의 맞춤형 품질 관리 시스템에는 성분 추적성, 다중 위치가 포함됩니다. 환경 통제, 규정 준수 및 COA 보고서 생성.",
        },
      },
    ],
  },
  health: {
    header: {
      EN: "Our Application Development Services",
      KR: "애플리케이션 개발 서비스",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Remote Healthcare",
          KR: "원격 의료",
        },
        info: {
          EN: "We will nurture and polish your data before converting it into a full-fledged digital product using our cutting-edge and robust services.",
          KR: "우리는 귀하의 데이터를 당사를 사용하여 본격적인 디지털 제품으로 변환하기 전에 육성하고 연마합니다. 최첨단의 강력한 서비스.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Connected Devices (Wearables)",
          KR: "연결된 기기(웨어러블)",
        },
        info: {
          EN: "The Internet of Things (IoT) has enabled you to connect your mobile device, wearables, and other health monitoring devices. It has enabled you to check your blood pressure, blood sugar levels, and even perform blood tests from any location.",
          KR: "사물 인터넷(IoT)을 통해 모바일 장치, 웨어러블 및 기타 건강을 연결할 수 있습니다. 모니터링 장치. 혈압, 혈당 수치, 심지어는 모든 위치에서 혈액 검사를 수행합니다.",
        },
      },
      {
        id: 3,
        title: {
          EN: "E-Prescription",
          KR: "전자 처방전",
        },
        info: {
          EN: "We specialize in developing mobile prescribing apps that securely store patient data such as prescriptions, allergies, and biometrics, thereby reducing medical errors",
          KR: "우리는 다음과 같은 환자 데이터를 안전하게 저장하는 모바일 처방 앱 개발을 전문으로 합니다. 처방, 알레르기 및 생체 인식을 통해 의료 오류를 줄입니다.",
        },
      },
      {
        id: 4,
        title: {
          EN: "EMR/EHR",
          KR: "EMR/EHR",
        },
        info: {
          EN: "Electronic health records improve diagnosis and treatment by increasing the accuracy of patient health records. We offer robust healthcare application services that reduce operational costs while improving treatment outcomes.",
          KR: "전자 건강 기록은 환자 건강의 정확성을 높여 진단 및 치료를 개선합니다. 기록. 우리는 운영 비용을 줄이는 동시에 개선하는 강력한 의료 애플리케이션 서비스를 제공합니다. 치료 결과.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Fitness Apps",
          KR: "피트니스 앱",
        },
        info: {
          EN: "Mobile fitness apps help you track your weight, blood sugar levels, sleep patterns, and other daily activities. Using AI, IoT, and emerging technologies, our apps easily integrate wearable technology and hardware.",
          KR: "모바일 피트니스 앱은 체중, 혈당 수치, 수면 패턴 및 기타 매일을 추적하는 데 도움이 됩니다. 활동. AI, IoT 및 새로운 기술을 사용하여 당사 앱은 웨어러블 기술과 하드웨어.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Hospital Management Solutions",
          KR: "병원 관리 솔루션",
        },
        info: {
          EN: "Healthcare apps have simplified the management of MIS reports, patient-physician interactions, patient registration, resource scheduling, and so on. We make certain that our apps are designed to save hospitals and clinics money and time while maintaining patient record compliance and security.",
          KR: "의료 앱은 MIS 보고서, 환자-의사 상호 작용, 환자 관리를 단순화했습니다. 등록, 리소스 일정 등. 우리는 앱이 저장하도록 설계되었음을 확인합니다. 환자 기록 준수 및 보안을 유지하면서 병원과 진료소에 돈과 시간을 투자합니다.",
        },
      },
    ],
  },
  educationAndtraining: {
    header: {
      EN: "Scalable Learning Management Systems",
      KR: "확장 가능한 학습 관리 시스템",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "LMS Integrations",
          KR: "LMS 통합",
        },
        info: {
          EN: "Custom SIS integrations with LMS, student management systems, administration systems, financial services, and other mission-critical systems.",
          KR: "LMS, 학생 관리 시스템, 관리 시스템, 재무와 맞춤형 SIS 통합 서비스 및 기타 미션 크리티컬 시스템.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Consistent Mobile Experiences",
          KR: "일관된 모바일 경험",
        },
        info: {
          EN: "We build web-based LMS platforms with native mobile apps using the device's hardware to provide unified user experiences across iOS and Android operating systems.",
          KR: "장치의 하드웨어를 사용하여 기본 모바일 앱으로 웹 기반 LMS 플랫폼을 구축하여 iOS 및 Android 운영 체제 전반에 걸쳐 통합된 사용자 경험.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Custom Communication Interfaces",
          KR: "맞춤형 통신 인터페이스",
        },
        info: {
          EN: "We program and interface blended learning applications with features such as social learning, messaging, multimedia, web, and video conferencing, streaming, recording, VoIP, discussion boards, and more.",
          KR: "우리는 소셜 학습, 메시징, 멀티미디어, 웹 및 화상 회의, 스트리밍, 녹음, VoIP, 토론 게시판 및 더.",
        },
      },
      {
        id: 4,
        title: {
          EN: "LMS Plugin Extensions",
          KR: "LMS 플러그인 확장",
        },
        info: {
          EN: "We create and extend custom activity modules, themes, course formats, enrollment and authentication, filters and document repositories, and much more for your Learning Management System.",
          KR: "우리는 사용자 정의 활동 모듈, 테마, 코스 형식, 등록 및 인증을 만들고 확장합니다. 필터 및 문서 저장소, 그리고 학습 관리 시스템에 대한 훨씬 더.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Captivating Instructional Design",
          KR: "매혹적인 교육 디자인",
        },
        info: {
          EN: "Using proven educational frameworks, our E-Learning experts transform basic educational content into captivating learning experiences.",
          KR: "검증된 교육 프레임워크를 사용하여 E-Learning 전문가가 기본 교육 콘텐츠를 매혹적인 학습 경험.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Interactive Education",
          KR: "양방향 교육",
        },
        info: {
          EN: "With custom animations and simulations, we create educational interactions that provide engaging learning experiences.",
          KR: "맞춤형 애니메이션 및 시뮬레이션을 통해 매력적인 교육적 상호 작용을 생성합니다. 학습 경험.",
        },
      },
    ],
  },
  logistics: {
    header: {
      EN: "We Provide Feature Rich Transport & Logistics Web Development",
      KR: "기능이 풍부한 운송 및 물류 웹 개발을 제공합니다.",
    },
    info: {
      EN: "Take a look at some of the advanced options available from CNTech, a web development company near Delhi, for transportation and logistics software development services and solutions.",
      KR: "가까운 웹 개발 회사인 CNTech에서 제공하는 고급 옵션 중 일부를 살펴보십시오. 운송 및 물류 소프트웨어 개발 서비스 및 솔루션을 위한 델리.",
    },
    lets_talk: {
      EN: "Let's talk",
      KR: "얘기하자",
    },
    services: [
      {
        id: 1,
        title: {
          EN: "Logistical Packaging",
          KR: "물류 포장",
        },
        info: {
          EN: "The app includes a logistics and transportation packaging feature that allows the owner to track merchandise information and gain a better understanding of product packaging.",
          KR: "앱에는 소유자가 추적할 수 있는 물류 및 운송 포장 기능이 포함되어 있습니다. 상품 정보를 확인하고 제품 포장에 대해 더 잘 이해할 수 있습니다.",
        },
      },
      {
        id: 2,
        title: {
          EN: "Warehouse Management",
          KR: "창고 관리",
        },
        info: {
          EN: "The warehouse management feature allows the owner to comprehend the movement of merchandise. In addition, our logistics and transportation web development company will provide you with an elaborated product.",
          KR: "창고 관리 기능을 통해 소유자는 상품의 이동을 이해할 수 있습니다. 또한 물류 및 운송 웹 개발 회사는 귀하에게 정교한 제품.",
        },
      },
      {
        id: 3,
        title: {
          EN: "Inventory Management",
          KR: "재고 관리",
        },
        info: {
          EN: "The internal controlled feature assists the owner with product segregation and placement within the warehouse. It can aid in the resolution of the problem associated with storing a variety of items.",
          KR: "내부 제어 기능은 소유자가 제품을 분리하고 내부에 배치하는 데 도움이 됩니다. 창고. 다양한 항목을 저장하는 것과 관련된 문제를 해결하는 데 도움이 될 수 있습니다.",
        },
      },
      {
        id: 4,
        title: {
          EN: "Manage Transportation",
          KR: "교통 관리",
        },
        info: {
          EN: "Everything is now automated to avoid and minimize human error. Our logistics and transportation web development company in Delhi offers a transportation module in its app.",
          KR: "이제 모든 것이 자동화되어 인적 오류를 방지하고 최소화합니다. 물류 및 운송 웹 델리의 개발 회사는 앱에서 운송 모듈을 제공합니다.",
        },
      },
      {
        id: 5,
        title: {
          EN: "Vendor Inventory",
          KR: "공급업체 인벤토리",
        },
        info: {
          EN: "The distributor-managed inventory assists you in streamlining accessibility chain operations and maintaining a healthy owner-vendor relationship.",
          KR: "유통업체에서 관리하는 재고는 접근성 체인 운영을 간소화하고 건강한 소유자-공급자 관계를 유지합니다.",
        },
      },
      {
        id: 6,
        title: {
          EN: "Material Handling",
          KR: "자재 취급",
        },
        info: {
          EN: "It monitors the handling of raw materials, as well as the movement of semi-finished and finished goods into and out of plants, warehouses, and transportation terminals.",
          KR: "반제품 및 완제품의 이동은 물론 원자재의 취급을 모니터링합니다. 공장, 창고 및 운송 터미널 안팎으로.",
        },
      },
    ],
  },
};
const brandTechnologies = {
  header: {
    EN: "Dominate & Disrupt Powerful Technology",
    KR: "강력한 기술을 지배하고 방해하라",
  },
  info: {
    EN: "We will incorporate upcoming technology that is powerful, robust, scalable, and results-oriented for your company.",
    KR: "우리는 귀사를 위해 강력하고 강력하며 확장 가능하고 결과 지향적인 기술을 통합할 것입니다.",
  },
  lets_talk: {
    EN: "Let's talk",
    KR: "얘기하자",
  },
  technologies: [
    {
      id: 1,
      header: {
        EN: "Mobile App Engineering",
        KR: "모바일 앱 엔지니어링",
      },
      info: {
        EN: "CNTech is a worldwide leader in the development of advanced mobile solutions and frameworks for both the Android and iOS platforms. We specialize in both native and hybrid app development, in addition to powerful human-centric application development.",
        KR: "CNTech는 Android 및 iOS 플랫폼 모두를 위한 고급 모바일 솔루션 및 프레임워크 개발의 세계적인 리더입니다. 우리는 강력한 인간 중심 애플리케이션 개발 외에도 네이티브 및 하이브리드 앱 개발을 전문으로 합니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/ios.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/android.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/reactnative.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/flutter.png"),
        },
        {
          id: 5,
          path: require("../assets/images/technologies/xamarin.png"),
        },
      ],
    },
    {
      id: 2,
      header: {
        EN: "Web Development Technologies",
        KR: "웹 개발 기술",
      },
      info: {
        EN: "Dominate the web and reach millions of new users with powerful web apps built using flexible and cross-platform technologies like Angular, React JS, Python, and others.",
        KR: "Angular, React JS, Python 등과 같은 유연한 교차 플랫폼 기술을 사용하여 구축된 강력한 웹 앱으로 웹을 지배하고 수백만 명의 신규 사용자에게 다가가십시오.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/angular.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/aspnet.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/bootstrap.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/expressjs.png"),
        },
        {
          id: 5,
          path: require("../assets/images/technologies/html-css.png"),
        },
        {
          id: 6,
          path: require("../assets/images/technologies/less.png"),
        },
        {
          id: 7,
          path: require("../assets/images/technologies/meteor.png"),
        },
        {
          id: 8,
          path: require("../assets/images/technologies/nodejs.png"),
        },
        {
          id: 9,
          path: require("../assets/images/technologies/pythonjs.png"),
        },
        {
          id: 10,
          path: require("../assets/images/technologies/reactjs.png"),
        },
        {
          id: 11,
          path: require("../assets/images/technologies/sass.png"),
        },
        {
          id: 12,
          path: require("../assets/images/technologies/vuejs.png"),
        },
      ],
    },
    {
      id: 3,
      header: {
        EN: "Backend Technologies",
        KR: "백엔드 기술",
      },
      info: {
        EN: "With powerful, scalable, and flexible backend technologies, establish a solid foundation for your mobile app or digital portal and ensure an effortless user experience for users across the globe. We excel in the deployment of server-side languages such as PHP, Grails, Python, Java, and .NET, as well as relational and NoSQL databases for faster data processing.",
        KR: "강력하고 확장 가능하며 유연한 백엔드 기술을 통해 모바일 앱 또는 디지털 포털을 위한 견고한 기반을 구축하고 전 세계 사용자에게 손쉬운 사용자 경험을 보장하십시오. 우리는 PHP, Grails, Python, Java 및 .NET과 같은 서버 측 언어와 더 빠른 데이터 처리를 위한 관계형 및 NoSQL 데이터베이스 배포에 탁월합니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/aspnet.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/dynamodb.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/firebase.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/golang.png"),
        },
        {
          id: 5,
          path: require("../assets/images/technologies/gails.png"),
        },
        {
          id: 6,
          path: require("../assets/images/technologies/sqlserver.png"),
        },
        {
          id: 7,
          path: require("../assets/images/technologies/mongodb.png"),
        },
        {
          id: 8,
          path: require("../assets/images/technologies/mysql.png"),
        },
        {
          id: 9,
          path: require("../assets/images/technologies/netcore.png"),
        },
        {
          id: 10,
          path: require("../assets/images/technologies/nodejs.png"),
        },
        {
          id: 11,
          path: require("../assets/images/technologies/php.png"),
        },
        {
          id: 12,
          path: require("../assets/images/technologies/postgresql.png"),
        },
        {
          id: 13,
          path: require("../assets/images/technologies/pythonjs.png"),
        },
      ],
    },
    {
      id: 4,
      header: {
        EN: "Cloud Technologies",
        KR: "클라우드 기술",
      },
      info: {
        EN: "Dominate and conquer the trillion-dollar Cloud Computing market with our powerful Cloud Technologies and Solutions, designed specifically for your company. We are Microsoft Azure and Amazon Web Services authorized partners.",
        KR: "귀사를 위해 특별히 설계된 강력한 클라우드 기술 및 솔루션으로 수조 달러 규모의 클라우드 컴퓨팅 시장을 지배하고 정복하십시오. 우리는 Microsoft Azure 및 Amazon Web Services 공인 파트너입니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/aws.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/googlecloud.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/azure.png"),
        },
      ],
    },
    {
      id: 5,
      header: {
        EN: "DevOps Tools and Technologies",
        KR: "DevOps 도구 및 기술",
      },
      info: {
        EN: "We are specialists in providing our clients with trustable, collaborative, scalable, and flexible development environments that are globally accessible via remote hosting services. Our development environment supports GIT for ongoing personalization, and our agile tools ensure project completion on time.",
        KR: "우리는 원격 호스팅 서비스를 통해 전 세계적으로 액세스할 수 있는 신뢰할 수 있고 협력적이며 확장 가능하고 유연한 개발 환경을 고객에게 제공하는 전문가입니다. 우리의 개발 환경은 지속적인 개인화를 위해 GIT를 지원하고, 우리의 애자일 도구는 프로젝트를 제시간에 완료할 수 있도록 합니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/azuredevops.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/docker.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/kubernetes.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/jenkins.png"),
        },
        {
          id: 5,
          path: require("../assets/images/technologies/jira.png"),
        },
        {
          id: 6,
          path: require("../assets/images/technologies/powershell.png"),
        },
      ],
    },
    {
      id: 6,
      header: {
        EN: "Enterprise Technologies",
        KR: "엔터프라이즈 기술",
      },
      info: {
        EN: "By deploying and optimizing platforms such as Salesforce, Microsoft Power Apps, Apache Hadoop, Private Cloud Orchestration, and others, we provide cutting-edge, powerful, and scalable enterprise solutions.",
        KR: "Salesforce, Microsoft Power Apps, Apache Hadoop, Private Cloud Orchestration 등과 같은 플랫폼을 배포하고 최적화하여 강력하고 확장 가능한 최첨단 엔터프라이즈 솔루션을 제공합니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/saleforce.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/powerapps.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/hadoop.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/moodle.png"),
        },
        {
          id: 5,
          path: require("../assets/images/technologies/cloudlock.png"),
        },
      ],
    },
    {
      id: 7,
      header: {
        EN: "Open-Source Development Frameworks",
        KR: "오픈 소스 개발 프레임워크",
      },
      info: {
        EN: "By deploying cutting-edge Open-Source Development Frameworks, we are experts in developing powerful, cost-effective, and platform-independent solutions that enable our clients to scale and rise exponentially.",
        KR: "최첨단 오픈 소스 개발 프레임워크를 배포함으로써 우리는 고객이 기하급수적으로 확장하고 성장할 수 있도록 하는 강력하고 비용 효율적이며 플랫폼 독립적인 솔루션을 개발하는 전문가입니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/codeigniter.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/django.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/laravel.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/moodle.png"),
        },
        {
          id: 5,
          path: require("../assets/images/technologies/slim.png"),
        },
        {
          id: 6,
          path: require("../assets/images/technologies/symfony.png"),
        },
      ],
    },
    {
      id: 8,
      header: {
        EN: "Internet Of Things and Machine Learning",
        KR: "Internet of things & machine learning",
      },
      info: {
        EN: "We are disrupting the status quo by deploying internet of things-based smart home solutions,smartwatches, and other wearables through next-generation technologies such as Alexa, Google Home,and others.",
        KR: "",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/homekit.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/googlehome.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/amazonalexa.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/ifttt.png"),
        },
      ],
    },
    {
      id: 9,
      header: {
        EN: "Smart TV Apps",
        KR: "스마트 TV 앱",
      },
      info: {
        EN: "Create user-friendly, scalable, connected, and feature-rich Smart TV apps that can be accessed via Amazon Fire TV, Chromecast, RokuTV, Samsung Smart TV, and other platforms.",
        KR: "Amazon Fire TV, Chromecast, RokuTV, Samsung Smart TV 및 기타 플랫폼을 통해 액세스할 수 있는 사용자 친화적이고 확장 가능하며 연결된 기능이 풍부한 스마트 TV 앱을 만드십시오.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/amazontv.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/chromecast.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/rokutv.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/samsungtv.png"),
        },
      ],
    },
    {
      id: 10,
      header: {
        EN: "Software Integrations",
        KR: "소프트웨어 통합",
      },
      info: {
        EN: "CNTech is a one-stop solution for seamlessly integrating third-party frameworks such as Stripe, Paypal,Square, Agora, MixPanel, SalesForce, and others into your existing IT platform.",
        KR: "CNTech는 Stripe, Paypal, Square, Agora, MixPanel, SalesForce 등과 같은 타사 프레임워크를 기존 IT 플랫폼에 원활하게 통합하기 위한 원스톱 솔루션입니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/agoraio.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/mixpanel.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/paypal.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/stripe.png"),
        },
        {
          id: 5,
          path: require("../assets/images/technologies/boxdot.png"),
        },
        {
          id: 6,
          path: require("../assets/images/technologies/clover.png"),
        },
        {
          id: 7,
          path: require("../assets/images/technologies/twilio.png"),
        },
        {
          id: 8,
          path: require("../assets/images/technologies/pubnub.png"),
        },
        {
          id: 9,
          path: require("../assets/images/technologies/netcore.png"),
        },
        {
          id: 10,
          path: require("../assets/images/technologies/zendesk.png"),
        },
        {
          id: 11,
          path: require("../assets/images/technologies/zoho.png"),
        },
        {
          id: 12,
          path: require("../assets/images/technologies/saleforce.png"),
        },
        {
          id: 13,
          path: require("../assets/images/technologies/docusign.png"),
        },
        {
          id: 14,
          path: require("../assets/images/technologies/quickbooks.png"),
        },
        {
          id: 15,
          path: require("../assets/images/technologies/firebase.png"),
        },
        {
          id: 16,
          path: require("../assets/images/technologies/analytics.png"),
        },
        {
          id: 17,
          path: require("../assets/images/technologies/braze.png"),
        },
      ],
    },
    {
      id: 11,
      header: {
        EN: "Ecommerce Platforms",
        KR: "전자상거래 플랫폼",
      },
      info: {
        EN: "Our next-generation e-commerce solutions are used by millions of users worldwide because we provide a wide range of integrated and customized services such as order management, omnichannel integration, marketing push, and more.",
        KR: "당사의 차세대 전자 상거래 솔루션은 주문 관리, 옴니채널 통합, 마케팅 푸시 등과 같은 광범위한 통합 및 맞춤형 서비스를 제공하기 때문에 전 세계 수백만 사용자가 사용하고 있습니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/magento.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/nopcommerce.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/saphybris.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/woocommerce.png"),
        },
      ],
    },
    {
      id: 12,
      header: {
        EN: "Content Management Solutions",
        KR: "콘텐츠 관리 솔루션",
      },
      info: {
        EN: "Our Content Management Solutions (CMS) are robust, scalable, reliable, and populated with breathtaking features and capabilities to guarantee smooth content production and delivery to millions of users.",
        KR: "당사의 콘텐츠 관리 솔루션(CMS)은 견고하고 확장 가능하며 안정적이며 수백만 명의 사용자에게 원활한 콘텐츠 제작 및 제공을 보장하는 놀라운 기능과 기능으로 채워져 있습니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/drupal.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/percussion.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/wordpress.png"),
        },
      ],
    },
    {
      id: 13,
      header: {
        EN: "Testing and QA",
        KR: "테스트 및 QA",
      },
      info: {
        EN: "Our unmatched and proven Testing and QA solutions enable hundreds of clients worldwide to deliver fully integrated, tested, and user-friendly solutions with no bugs and anomalies to their users.",
        KR: "당사의 타의 추종을 불허하고 입증된 테스트 및 QA 솔루션을 통해 전 세계 수백 명의 클라이언트가 사용자에게 버그와 이상 현상 없이 완전히 통합되고 테스트되었으며 사용자 친화적인 솔루션을 제공할 수 있습니다.",
      },
      tech_icons: [
        {
          id: 1,
          path: require("../assets/images/technologies/jmeter.png"),
        },
        {
          id: 2,
          path: require("../assets/images/technologies/postman.png"),
        },
        {
          id: 3,
          path: require("../assets/images/technologies/rest-assured.png"),
        },
        {
          id: 4,
          path: require("../assets/images/technologies/selenium.png"),
        },
      ],
    },
  ],
};
module.exports = {
  banner_info,
  footer_info,
  footer_explore_links,
  contact_us,
  our_solutions,
  omnichannel,
  clients,
  servicesHeader,
  productOfferings,
  brandDigitalProducts,
  digitalProductSuccess,
  scheduleExpertSession,
  brandPower,
  healthCareApplicationHeader,
  aboutHealthCareApp,
  healthCareServices,
  brandHealthCareServices,
  brandTechnologies,
};
