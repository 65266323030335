import React from "react";
import BrandPowerStatistics from "./BrandPowerStatistics";
import { cluesNetworkStatisticsInfo as statistics } from "../../../configuration/common.config";
import Block from "../../UI/Block";
import Text from "../../UI/Text";
import UnOrderedList from "../../UI/UnOrderedList";
import {brandPower} from "../../../configuration/lang.config"
const BrandPower = ({selectedLanguage}) => {
  return (
    <Block blockCustomClass = "powering_sec">
      <Block blockCustomClass = "wrapper">
        <h3>{brandPower.header[selectedLanguage]}
        <i>{brandPower.header_info[selectedLanguage]}</i>
        <Text
          text = {brandPower.text[selectedLanguage]}
        />
        </h3>
        <Block blockCustomClass = "info_sec">
          <Block blockCustomClass = "info">
            <UnOrderedList>
            {statistics.map((stats) => {
              return <BrandPowerStatistics key={stats.id} stats={stats} selectedLanguage={selectedLanguage} />;
            })}
            </UnOrderedList>
          </Block>
        </Block>
      </Block>
    </Block>
  );
};

export default BrandPower;
