import React, { Fragment ,useEffect} from "react";
import Header from "../Layout/Header/Header";
import { contact_us } from "../../configuration/lang.config";
import Address from "../Section/Address";
import ContactUs from "../Section/ContactUs";
import Footer from "../Layout/FooterNew/Footer";
import MainContainer from "../UI/MainContainer";
import Block from "../UI/Block";
import InnerPageBanner from "../Layout/Banner/InnerPageBanner";

const Contact = ({ selectedLanguage, onLanguageChangeSelector }) => 
{
  useEffect(() => {
    // 👇️ scroll to top on page load
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  });

  return (
    <Fragment> 
       <Header
          selectedLanguage={selectedLanguage}
          onLanguageChangeSelector={onLanguageChangeSelector}
          headerCustomClass="inner"
        />
      <MainContainer MainContainerCustomClass = "container contact_pg">
        <section className="alt">
        <InnerPageBanner header_text = {contact_us.head_text[selectedLanguage]}/>
        <Block blockCustomClass = "wrapper">
          <Address selectedLanguage={selectedLanguage} />
          <ContactUs selectedLanguage={selectedLanguage}/>
        </Block>
        </section>
      </MainContainer>
      <Footer selectedLanguage={selectedLanguage} />
    </Fragment>
  );
};

export default Contact;
