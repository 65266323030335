import { BrowserRouter as Router, Routes, Route  } from "react-router-dom";
import Home from "./components/Views/Home";
import Contact from "./components/Views/Contact";
import Services from "./components/Views/Services";
import Industries from "./components/Views/Industries";
import Technologies from "./components/Views/Technologies";
import Clients from "./components/Views/Clients";



export default function CluesRoutes({ currentLanguage, onLanguageChange }) {
  
  return (
    <Router>
      <Routes>
        <Route
          path="/"
          element={
            <Home
              selectedLanguage={currentLanguage}
              onLanguageChangeSelector={onLanguageChange}
            />
          }
          exact
        />
          
         <Route path="/services/:serviceName" element={
            <Services
              selectedLanguage={currentLanguage}
              onLanguageChangeSelector={onLanguageChange} 
            />
            } 
        />
        <Route path="/industries/:industriesName" element=
          {<Industries 
            selectedLanguage={currentLanguage}
            onLanguageChangeSelector={onLanguageChange}
            />
          } 
        />
        
        <Route path="/technologies" element={
            <Technologies
              selectedLanguage={currentLanguage}
              onLanguageChangeSelector={onLanguageChange} 
            />
            } 
        />
        <Route
          path="/contact"
          element={
            <Contact
              selectedLanguage={currentLanguage}
              onLanguageChangeSelector={onLanguageChange}
            />
          }
        />
        <Route
          path="/clients"
          element={
            <Clients
              selectedLanguage={currentLanguage}
              onLanguageChangeSelector={onLanguageChange}
            />
          }
        />
      </Routes>
    </Router>
  );
}
