import React from 'react';
import PropTypes from "prop-types";
const MainContainer = (props) => {
    return (
        <div className = {props.MainContainerCustomClass}>
            {props.children}
        </div>
    )
}
MainContainer.propTypes = {
    MainContainerCustomClass: PropTypes.string,
  };
  
MainContainer.defaultProps = {
    MainContainerCustomClass : "container"
}
export default MainContainer;
