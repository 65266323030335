import React from 'react';
import Text from '../../UI/Text';
import Block from '../../UI/Block';
import { Link } from "react-router-dom";
import List from '../../UI/List';
import UnOrderedList from '../../UI/UnOrderedList';
import { brandDigitalProducts } from "../../../configuration/lang.config";

const BrandDigitalProducts = ({selectedLanguage,type}) => {
    return (
        <section>
            <Block blockCustomClass="wrapper">
                <Block blockCustomClass="row">
                    <Block blockCustomClass="heading_info">
                        <h2>
                            <Text text={brandDigitalProducts[type].title[selectedLanguage]} />
                        </h2>
                        <p>
                            <Text text={brandDigitalProducts[type].description[selectedLanguage]} />
                        </p>

                        <Block blockCustomClass="bttn_group">
                            <Link to={brandDigitalProducts[type].link_to} className="btn">
                                {brandDigitalProducts[type].link_title[selectedLanguage]}
                            </Link>
                        </Block>
                    </Block>
                    <Block blockCustomClass="content">
                        <UnOrderedList listCustomClass="x2">
                            {brandDigitalProducts[type].content.map((content) => {
                                return (
                                    <List key={content.id}>
                                        <p>
                                           {content.paragraph_content[selectedLanguage]}
                                        </p>
                                        <Text text={content.span_content[selectedLanguage]} />
                                    </List>
                                )
                            })}
                        </UnOrderedList>
                    </Block>
                </Block>
            </Block>
        </section>
    );
}

export default BrandDigitalProducts;
