import React from "react";
import { Link } from "react-router-dom";

const FooterNavigationItems = ({ navObj, selectedLanguage }) => {
  return (
    <>
      {navObj.map((nav) => {
        if (nav.isFolder) {
          return (
            <ul>
              <h4>{nav.name[selectedLanguage]}</h4>
              <div className="lnk">
                {nav.items.map((items) => {
                  return (
                    <Link to={items.itemRoute}>
                      {items.itemName[selectedLanguage]}
                    </Link>
                  );
                })}
              </div>
            </ul>
          );
        } else {
          return "";
        }
      })}
    </>
  );
};

export default FooterNavigationItems;
