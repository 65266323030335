import React from "react";
import SectionContainer from "../SectionContainer";
import Block from "../../UI/Block";
import { healthCareServices } from "../../../configuration/lang.config";
import { Link } from "react-router-dom";
import UnorderedList from "../../UI/UnOrderedList";
import List from "../../UI/List";
import Text from "../../UI/Text";

const HealthCareServices = ({selectedLanguage ,type}) => {
    let info,lets_talk;
    if(healthCareServices[type].info){
        info = <p>{healthCareServices[type].info[selectedLanguage]}</p>;
    }
    if(healthCareServices[type].lets_talk){
        lets_talk = <Block blockCustomClass="bttn_group"><Link to = "/contact" className="btn">{healthCareServices[type].lets_talk[selectedLanguage]}</Link></Block>;
    }
    return (
        <SectionContainer sectionCustomClass="alt">
            <Block blockCustomClass="wrapper">
                <Block blockCustomClass="row">
                    <Block blockCustomClass="heading_info">
                        <h2>
                            {healthCareServices[type].header[selectedLanguage]}
                        </h2>
                        {info}
                        {lets_talk}
                    </Block>
                </Block>  
                <Block blockCustomClass = "row">
                    <Block blockCustomClass = "content full_width">
                        <UnorderedList listCustomClass = "border_blk">
                            {healthCareServices[type].services.map((services) => {
                                return (
                                    <List key = {services.id}>
                                        <p>
                                            {services.title[selectedLanguage]}
                                        </p>
                                        <Text 
                                            text={services.info[selectedLanguage]}
                                        />
                                    </List>
                                );
                            })}
                        </UnorderedList>
                    </Block>
                </Block>  
            </Block>
        </SectionContainer>
    );
}

export default HealthCareServices;