import React from "react";
import styles from "./Clients.module.css";
import {clients} from "../../../configuration/lang.config";
import Image from "../../UI/Image";

const ClientsDescriptionListing = ({selectedLanguage,onLanguageChangeSelector}) => {
    return(
        <>
            {clients.client_desc.map((clientInfo,key) => {
               return (
                <section className = {clientInfo["section_class"]} 
                key = {key}>
                    <span className = {styles["client_logo"]}>
                        <Image 
                            imgLocation = {clientInfo["client_logo"]}
                            imgCustomClass = {styles["client_image"]}
                        />
                    </span>
                    <h2>{clientInfo["client_name"][selectedLanguage]}</h2>
                    <h4>{clientInfo["client_title"][selectedLanguage]}</h4>
                    <p>{clientInfo["client_descr"][selectedLanguage]}</p>
                </section>
               )
            })}
        </>
    )
}
export default ClientsDescriptionListing;