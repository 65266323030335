import React , {useEffect} from "react";
import Header from "../Layout/Header/Header";
import MainContainer from "../UI/MainContainer";
import {HealthCareApplicationHeader , AboutHealthCareApp , HealthCareServices , 
BrandHealthCareServices , ScheduleExpertSession} from "../Section/Industries/index";
import Footer from "../Layout/FooterNew/Footer";
//import "../../assets/css/inner_pages.css";
import { useParams } from 'react-router-dom';

const Industries = ({selectedLanguage , onLanguageChangeSelector}) => {

    const { industriesName } = useParams();
    useEffect(() => {
        // 👇️ scroll to top on page load
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
      });
    return (
        <>
            <Header
                selectedLanguage={selectedLanguage}
                onLanguageChangeSelector={onLanguageChangeSelector}
                headerCustomClass="inner"
            />
            <MainContainer>
                <HealthCareApplicationHeader 
                    selectedLanguage = {selectedLanguage}
                    type={industriesName} 
                />
                <AboutHealthCareApp 
                    selectedLanguage = {selectedLanguage}
                    type={industriesName}
                />
                <HealthCareServices
                    selectedLanguage={selectedLanguage}
                    type={industriesName}
                />
                <BrandHealthCareServices 
                    selectedLanguage={selectedLanguage}
                    type={industriesName}
                />
                <ScheduleExpertSession 
                    selectedLanguage={selectedLanguage}
                />
            </MainContainer>
            <Footer selectedLanguage={selectedLanguage}/>
        </>
    );
}

export default Industries;